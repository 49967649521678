import React, { useState, useEffect, useContext} from 'react';
import Spinner from "../Spinner/Spinner";
import LinkedinAuthService from '../../Services/LinkedinAuth';
import styles from './Onboarding.module.css';
import loginStyles from '../Login/LoginPage.module.css';
import formStyles from '../Login/FormInput.module.css';
import { Context } from '../../Store/Store';

const LinkedinRedirect = ()=> {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const [success, setSuccess] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const context = useContext(Context);
    const [pauseImage, setPauseImage] = useState(false);
  const handleMouseEnter = () => {
    setPauseImage(true);
  }
  const handleMouseLeave = () => {
    setPauseImage(false);
    setCurrentIndex(currentIndex + 1);
  }

  const changeImage = (e) => {
    let image = e.currentTarget.id;
    if (image === "lead_monitoring"){
      setCurrentIndex(1);
    }
    else {
      setCurrentIndex(0);
    }
  };
  
  const hashtag_trends = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/1_hashtag.svg' alt='FILT Pod'></img></div>
          <div style={{color:"white", textAlign:"center", height:"500px"}}>
            <div style={{height:"90px", marginBottom:"40px"}}>
              <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                <div>Seize LinkedIn hashtags with rapid follower growth for more content exposure by monitoring their behavioral trends.</div>
              </div>
            </div>
            <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
              <img src='/images/hashtag_trends_description_small.jpg' alt='Hashtag Trends'></img>
            </div>
          </div>
          <div >
            <div style={{width:"max-content", margin:"auto"}} className='row'>
              <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
              <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
              
            </div>
          </div>
        </div>
    </div>
)

const lead_monitoring = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/2_engage.svg' alt='FILT Pod'></img></div>
            <div style={{color:"white", textAlign:"center", height:"500px"}}>
              <div style={{height:"90px", marginBottom:"40px"}}>
                <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                Use AI to write insightful comments, saving hours of your time by engaging and increasing touchpoints with multiple LinkedIn leads for faster conversions.
                </div>
              </div>
              <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
                <img src='/images/lead_monitoring.jpg' alt='Lead Monitoring'></img>
              </div>
            </div>
            <div >
              <div style={{width:"max-content", margin:"auto"}} className='row'>
                <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
                <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
                
              </div>
            </div>
        </div>
    </div>
)

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pauseImage) {
        return
      }
      if (currentIndex >= 1) {
        setCurrentIndex(0)
      }
      else {
        setCurrentIndex(currentIndex + 1)
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, pauseImage]);

  useEffect(()=>{
    if (code && state) {
        const existingCode = localStorage.getItem('linkedin_code');
        if ( existingCode === code) {
            return
        }
        localStorage.setItem('linkedin_code', code);
        const existingState = localStorage.getItem('linkedin_state', state);
        if (state !== existingState) {
            setIsLoading(false);
            setSuccess(false);
        }
        //console.log(code, state, existingState);
        setIsLoading(true);
        LinkedinAuthService(code, state).then((res)=>{
            setIsLoading(false);
            if(res.status === 200) {
                setSuccess(true);
                res.json().then((json)=>{
                    const u = JSON.parse(localStorage.getItem("user"));
                    u["linkedin_email"] = json.linkedin_email;
                    u["linkedin_first_name"] = json.linkedin_first_name;
                    u["linkedin_last_name"] = json.linkedin_last_name;
                    u["linkedin_access_token_expiry_date"] = json.linkedin_access_token_expiry_date;
                    localStorage.setItem("user", JSON.stringify(u));
                    context[1]({
                      type: 'SET_USER',
                      payload: {
                        u
                      }
                    });
                })
            }
        })
    }
  }, [code, state, context])

   
  return (
    <div className={`${loginStyles.main} flex-nowrap `}>
      <div className={`${styles.row}`}>
        {currentIndex === 1 ? lead_monitoring : hashtag_trends}
        <div className={`${loginStyles.rightbackground} col-6 `}>
          {loading ? <Spinner className="spinner-container" /> : 
          success ? (
            <div className='form-row d-flex justify-content-center'>
              <div className='col-md-8 mt-3' >
                <div style={{marginTop:"40px", fontSize:"24px", lineHeight:"36px", textAlign:"center"}}>Authorization successful.</div>
                <div className={`${formStyles['rect-button']}`} style={{display:"flex", alignItems:"center", marginTop:"23px", cursor:"pointer"}} onClick={()=> {window.open("https://www.linkedin.com");}}>
                  <div style={{margin:"0 5px"}}><img src='/images/linkedin_logo.svg' alt='gmail' style={{width:"20px"}}></img></div>
                  <div style={{fontWeight:"bold"}}>Go to LinkedIn</div>
                  <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
                </div>
                <div className='d-flex justify-content-center' style={{marginTop:"200px"}}>
                  <div><span><img src='/images/back-arrow.svg' alt='back to login'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
                </div>
              </div>
            </div>
          ) : ( 
              <div className='col-md-8 mt-3' >
                <div style={{marginTop:"40px", fontSize:"24px", lineHeight:"36px", textAlign:"center"}}>Authorization failed.</div>
                <div className={`${formStyles['rect-button']}`} style={{display:"flex", alignItems:"center", marginTop:"23px", cursor:"pointer"}} onClick={()=> {window.open("https://www.linkedin.com");}}>
                  <div style={{margin:"0 5px"}}><img src='/images/linkedin_logo.svg' alt='gmail' style={{width:"20px"}}></img></div>
                  <div style={{fontWeight:"bold"}}>Go to LinkedIn</div>
                  <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
                </div>
                <div className='d-flex justify-content-center' style={{marginTop:"200px"}}>
                  <div><span><img src='/images/back-arrow.svg' alt='back to login'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
                </div>
              </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default LinkedinRedirect;