import React from 'react';
import styles from './Onboarding.module.css';
import Topbar from '../EngageAI/Topbar'


const SalesforceRedirect = () => {
    return (
        <div>
            <div>
                <Topbar/>
                <div className={`${styles['f-20']} ${styles['center-align']} mb-3`}>
                <iframe width="900" height="505" src="https://www.youtube.com/embed/3zfVg61LJWk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className={``}  >
                    <div className={`${styles['f-20']} ${styles['center-align']} mb-3`}>
                        Just a few more steps and you can start moving prospects stuck in your pipeline.
                    </div>
                    <div className={`${styles['w-680']} ${styles['f-16']}`} style={{margin:"auto"}}>
                        <ol>
                            <li><a href='https://apps.filtpod.com/Onboarding/CreateAccount?source=engageai' target='__blank' style={{color:"#3947A8"}}>Create an Engage AI account</a>.</li>
                            <li>Download the <a href='https://chrome.google.com/webstore/detail/nelhhkchoapcbpcgpmmiahfkcdhgecaf?authuser=0&hl=en-GB' target='__blank' style={{color:"#3947A8"}}>Engage AI Chrome Extension</a> to activate LinkedIn post-tracking.</li>
                            <li>Start nurturing prospects by adding their LinkedIn profiles to the Engage AI app directly in Salesforce.</li>
                        </ol>
                    </div>
                    
                </div>
            </div>
        </div>
    ); 
}
export default SalesforceRedirect;