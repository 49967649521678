import React from "react";
import FormInputChange from "./FormInputChange";
import { Box } from "@mui/material";

const ChangePassword = () => {
  return (
    <div className="container ">
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <Box className="headerText" mb="1.5rem">
            <Box display="flex" alignItems="center" gap="8px">
              <h1>Change Password</h1>
            </Box>
        </Box>
        <div style={{ flex: "1" }}>
          {/* {loading ? <Spinner className="spinner-container" /> : ""} */}
          <div>
            <FormInputChange />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
