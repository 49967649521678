import React, { useState, useContext, useEffect } from 'react';
import styles from './AccountPage.module.css';
import { Context } from '../../Store/Store';
import UpdateAccountService, {DeleteAccountService} from '../../Services/UpdateAccountService';
//import { DisconnectLinkedinService } from '../../Services/LinkedinAuth';
import { setUserDataLocalStorage } from '../../Scripts/getSetLocalStorage';
import { useNavigate } from 'react-router-dom';
import { TransformAPIDataToUserData } from '../../Services/UserDataService';
import Modal from 'react-bootstrap/Modal';

const FormInputAccount = () => {
  //const u = JSON.parse(localStorage.getItem("user"));
  const context = useContext(Context);
  const [state, dispatch] = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    linkedinPage: '',
    openaiApiKey: '',
    linkedinEmail: '',
    linkedinFirstName: '',
    linkedinLastName:''
  });
  const [responseValues, setResponseValues] = useState({ response: '', type: '' });
  const history = useNavigate();

  useEffect(() => {
    if (state.user.data !== undefined) {
      console.log(state.user.data)
      setFormValues({
        firstName: state.user.data.firstName,
        lastName: state.user.data.lastName,
        email: state.user.data.email,
        linkedinPage: state.user.data.linkedinPage,
        linkedinEmail: state.user.data.linkedin_email,
        linkedinFirstName: state.user.data.linkedin_first_name,
        linkedinLastName: state.user.data.linkedin_last_name,
        openaiApiKey: state.user.data.open_ai_api_key
      });
    } else {
      console.log('No user data');
    }
  }, [state]);

  const handleChange = (event) => {
    event.persist();
    setFormValues((values) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleDeleteAccount = () => {
    setShowModal(true);
  }

  const confirmAccountDeletion = () => {
    DeleteAccountService().then((res)=> {
      if (res.status === 200) {
        localStorage.clear();
        // clear state
        dispatch({
          type: 'RESET_STATE'
        });
        history('/Login');
      }
    })
  }

  const modalBody = (
    <div style={{padding:"24px", textAlign:"center"}}>
    <div style={{cursor:"pointer", textAlign:"right"}} onClick={()=>{setShowModal(false)}}>X</div>
        <div style={{fontWeight:"700", fontSize:"28px"}}>Are you sure?</div>
        <div><p>Once deleted, you'd need to create a new account to access the app again.</p></div>
        <div style={{color:"#2F3EAC", display:"flex", gap:"4px", justifyContent:"center", cursor:"pointer", marginTop:"20px"}}>
            <div onClick={confirmAccountDeletion} style={{textDecoration:"underline"}}>Yes, delete my account</div>
        </div>
    </div>
)

  const handleSubmit = (event) => {
    event.preventDefault();
    // When UpdateAccountService submits the new data, it will receive a Promise
    // This will take the data from the Promise and update 1) the localstorage for key user and 2) update the context store for user and 3) update the localstoreage token
    UpdateAccountService({
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      linkedinPage: formValues.linkedinPage,
      openaiApiKey: formValues.openaiApiKey
    })
      .then((res) => {
        res
          .json()
          .then((json) => {
            // Update localstorage for user and token, and context store
            try {
              const data = TransformAPIDataToUserData(json);

              setUserDataLocalStorage(data, json.data.token);

              // useContext dispatch to udate the user in context storage
              context[1]({
                type: 'SET_USER',
                payload: {
                  data
                }
              });
              console.log('Success');
              console.log(json); //mv delete
              setResponseValues({ response: 'You have successfully updated your account.', type: 'success' });
            } catch (error) {
              console.log('ERROR bad request');
              console.error(error);
              setResponseValues({ response: error });
            }
          })
          .catch((error) => {
            console.log('Something messed up with the .json() ');
            console.error(error);
            setResponseValues({ response: 'Unable to parse server response. ' + error, type: 'error' });
            // setIsLoading(false);
            return error;
          });
      })
      .catch((error) => {
        console.log('Something messed up ');
        console.error(error);
        setResponseValues({ response: 'Unable to contact server. ' + error, type: 'error' });
        // setIsLoading(false);
        return error;
      });
  };

  const redirect = (link) => {
    history(link);
  };

  /**
  const openLinkedinAuth = () => {
    const state = formValues.email;
    localStorage.setItem("linkedin_state", state);
    var redirect_uri = "http://localhost:3000/LinkedinAuthRedirect"
    if (window.location.hostname.includes("filtpod-frontend-test")) {
      redirect_uri = "https://filtpod-frontend-test.web.app/LinkedinAuthRedirect";
    }
    else if (window.location.hostname.includes("apps.engage-ai")){
      redirect_uri = "https://apps.engage-ai.co/LinkedinAuthRedirect";
    }
    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86vxx2jtebvxju&redirect_uri=${redirect_uri}&state=${state}&scope=r_liteprofile%20r_emailaddress`)
  };

  const disconnect = () => {
    const data = state.user.data;
    data.linkedin_email = '';
    data.linkedin_first_name = '';
    data.linkedin_last_name = '';
    data.linkedin_access_token_expiry_date = '';
    context[1]({
      type: 'SET_USER',
      payload: {
        data
      }
    });
    DisconnectLinkedinService(data.email).then((res)=>{
      console.log(res)
    });
  }*/

  return (
    <div className={` mt-5 col-md-7 ml-2`}>
      <form onSubmit={handleSubmit} id='login-form' data-testid='login-form'>
        <div className={` form-group`}>
          <div className='form-row  justify-content-between'>
            <div className='col-md-6 '>
              <label htmlFor='firstName'>First Name</label>
              <input
                className={`${styles['form-input']} form-control`}
                placeholder='First Name'
                type='text'
                id='firstName'
                name='firstName'
                onChange={handleChange}
                value={formValues.firstName}
                autoComplete='off'
                required
                data-testid='firstNameInput'
              />
            </div>
            <div className='col-md-6 '>
              <label htmlFor='lastName'>Last Name</label>
              <input
                className={`${styles['form-input']} form-control`}
                placeholder='Last Name'
                type='text'
                id='lastName'
                name='lastName'
                onChange={handleChange}
                value={formValues.lastName}
                autoComplete='off'
                required
                data-testid='lastNameInput'
              />
            </div>
          </div>
        </div>

        <div className='mt-4'>
          <label htmlFor='email'>Email</label>
          <input
            className={`${styles['form-input']} form-control`}
            placeholder='Email'
            type='email'
            id='email'
            name='email'
            onChange={handleChange}
            value={formValues.email}
            autoComplete='off'
            required
            data-testid='emailInput'
          />
        </div>

        <div className='mt-4'>
          {/**new Date(state?.user?.data?.linkedin_access_token_expiry_date) > new Date() ? 
            <>
              <div style={{marginBottom:"0.5rem"}}>LinkedIn</div>
              <div style={{fontWeight:"400", marginBottom:"0.5rem"}}>Connected as <b>{state?.user?.data?.linkedin_first_name + ' ' + state?.user?.data?.linkedin_last_name}</b></div>
              <div style={{display:"flex", alignItems:"center", gap:"20px"}}>
                <div style={{display:"flex", alignItems:"center",  cursor:"pointer"}} onClick={openLinkedinAuth}>
                    <div style={{margin:"0 5px"}}><img src='/images/linkedin_logo.svg' alt='' style={{width:"20px"}}></img></div>
                    <div style={{fontWeight:"bold", width:"max-content"}}>Reconnect</div>
                    <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
                </div>
                <div style={{padding:'5px', color:"red", cursor:"pointer", border:"1px dotted gray", borderRadius:"4px"}} onClick={disconnect}>
                    Disconnect
                </div>
              </div>
            </> :
            <>
              <div style={{marginBottom:"0.5rem"}}>LinkedIn</div>
              <div style={{display:"flex", alignItems:"center",  cursor:"pointer"}} onClick={openLinkedinAuth}>
                  <div style={{margin:"0 5px"}}><img src='/images/linkedin_logo.svg' alt='' style={{width:"20px"}}></img></div>
                  <div style={{fontWeight:"bold", width:"max-content"}}>Connect LinkedIn Account</div>
                  <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
             </div>
            </>
        */}
        </div>

        <div className='mt-4'>
          <label htmlFor='openaiApiKey'>OpenAI API Key</label>
          <input
            className={`${styles['form-input']} form-control`}
            id='openaiApiKey'
            name='openaiApiKey'
            onChange={handleChange}
            value={formValues.openaiApiKey}
            autoComplete='off'
            
            data-testid='openaiApiKeyInput'
          />
        </div>

        <div className={` form-group`}>
          <div className='form-row  justify-content-between mt-4'>
            <div >
              <button className={`${styles['submit-button']} `} data-testid='login-submit' style={{fontWeight:"400"}}>
                Update Account
              </button>
            </div>
            <div onClick={() => redirect('/ChangePassword')}>
              <div className={`${styles['submit-button']} `} style={{margin:"auto", textAlign:"center", fontWeight:"400"}}>Change Password</div>
            </div>
          </div>
        </div>
      </form>
      <p className={responseValues.type === 'success' ? 'text-success d-flex ' : 'text-danger-custom d-flex'}>{responseValues.response}</p>
      {state.user?.data?.group !== 'Member' && state.user?.data?.group !== 'Growth Plan' && state.user?.data?.group !== 'Prominence Global' ? <div onClick={handleDeleteAccount} className={`${styles['submit-button']} `} style={{textAlign:"center", fontWeight:"400", width:"max-content", background:"red", cursor:"pointer"}}>Delete Account</div> : ''}
    
      <Modal
            show={showModal}
            size='lg'
            onHide={() => setShowModal(false)}
            dialogClassName={`modal-dialog-centered`}
            aria-labelledby='boost-modal'
            >
            <Modal.Body>{modalBody}</Modal.Body>
        </Modal>
    </div>
  );
};

export default FormInputAccount;
