import React, { useEffect, useState, useContext } from "react";
import Spinner from "../Spinner/Spinner";
import styles from "../Homepage/Homepage.module.css";
import { Context } from "../../Store/Store";
import NotificationService from "../../Services/NotificationService";
import { Link, useLocation } from "react-router-dom";

const NotificationPage = () => {
  const [loading, setLoading] = useState(true);
  const context = useContext(Context);
  const [announcements, setAnnouncements] = useState();
  const [hideList, setHideList] = useState(false);
  let location = useLocation();

  useEffect(() => {
    NotificationService().then((res) => {
      res.json().then((json) => {
        setAnnouncements(json.data);
        setLoading(false);
        localStorage.setItem("FP_notifications", JSON.stringify(json.data));
      });
    });
    return () => {};
  }, [context]);

  useEffect(() => {
    if (location.pathname === "/Notification") {
      setHideList(false);
    } else {
      setHideList(true);
    }
    return () => {};
  }, [location]);

  return (
    <div>
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div style={{ flex: "1" }}>
          {loading ? <Spinner /> : ""}
          <div className={`${styles["background"]}`}>
            <div className={`${styles["main"]} mt-5 `}>
              <div className="row  justify-content-between ">
                <div>
                  <p className={`${styles["title"]} h4 mb-5`}>Notifications</p>
                </div>
              </div>
            </div>
            <>
              {!hideList && announcements
                ? announcements.map((announcement) => {
                    let id = Object.keys(announcement)[0];
                    return (
                      <div
                        key={id}
                        className={`${styles["announcement-container"]} `}
                        style={
                          !announcement[id]["read"] ? { backgroundColor: "#E5F0FF" } : { backgroundColor: "#e1e6ed" }
                        }
                      >
                        <div className="row  justify-content-between mb-2">
                          <div className="font-weight-bold">{announcement[id]["title"]}</div>
                          <div className={`${styles["font-grey"]}`}>{announcement[id]["date"]}</div>
                        </div>
                        <p>{announcement[id]["summary"]}</p>
                        {
                          <Link
                            to={{
                              pathname: `/Notification/${id}`,
                            }}
                          >
                            Learn more
                          </Link>
                        }
                      </div>
                    );
                  })
                : ""}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
