import React, { useState, useRef } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Spinner from '../Spinner/Spinner';
import RunRandomizerService from '../../Services/RunRandomizer';
import SimulationResetService from '../../Services/SimulationReset';
import GetPodRun from '../../Services/GetPodRunService';
import GetCancelledMembers from '../../Services/GetCancelledMembers';
import Topbar from '../Topbar/Topbar';

const AdminPage = () => {
  const [loading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formValues, setFormValues] = useState({
    inputEmail: '',
    password: '',
    submittedDate: '',
    numRuns: '',
    isSimulation: '',
  });
  const [resetFormValues, setResetFormValues] = useState({
    resetinputEmail: '',
    resetpassword: '',
    resetDate:'',
  });
  const [podRunDate, setPodRunDate] = useState();
  const [stripeEndDate, setStripeEndDate] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [responseValues, setResponseValues] = useState({
    response: { message: '' }
  });
  const [resetResponseValues, setResetResponseValues] = useState({
    response: { message: '' }
  });
  const adminButton = useRef();
  const resetButton = useRef();

  const handleChange = (event) => {
    event.persist();
    if (event.target.name === 'resetDate' || event.target.name === 'resetinputEmail' || event.target.name === 'resetpassword'){
      setResetFormValues((values) => ({
        ...values,
        [event.target.name]: event.target.value
      }));}
    else if (event.target.name === 'podRunDate') {
      setPodRunDate(event.target.value);
    }
    else if (event.target.name === 'stripeEndDate') {
      setStripeEndDate(event.target.value);
    }
    else{
      setFormValues((values) => ({
        ...values,
        [event.target.name]: event.target.value
      }));}
  };

  // const mockRandomizerService = (x, y, z, a, b) => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve({ status: 200, statusText: 'we are being mocked' });
  //     }, 300);
  //   });
  // };

  const handleSubmit = (event) => {

    adminButton.current.setAttribute('disabled', 'disabled');
    event.preventDefault();

    //console.log(formValues);
    if ((!formValues.numRuns && formValues.isSimulation) || (formValues.numRuns && !formValues.isSimulation)) {
      setResponseValues({
        message: 'For a simulation a Number of Runs is required. For a manual run Number of Runs should be blank'
      });
      return;
    }
    setResponseValues({
      message: ''
    });
    console.log('randomizer');
    RunRandomizerService(formValues.inputEmail, formValues.password, formValues.isSimulation, formValues.submittedDate, formValues.numRuns).then((res) => {
      console.log(res);
      if (res.status !== 200) {
        setResponseValues({
          message: res.statusText + '. Possible Issue: Check login credentials.'
        });
        return;
      }

      setFormValues({
        inputEmail: '',
        password: '',
        submittedDate: '',
        numRuns: '',
        isSimulation: ''
      });

      // Download the file
      res
        .blob()
        .then((blob) => {
          adminButton.current.removeAttribute('disabled', 'disabled');
          const date = formValues.submittedDate ? formValues.submittedDate.replace(/-/g, '') : createDate();
          //console.log(blob);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'RandomizerRun_TIC1_' + date + '.csv';
          a.click();

          setSuccess(true);
          if (formValues.isSimulation) {
            setResponseValues({
              message: 'Simulation run successful!'
            });
          } else {
            setResponseValues({
              message: 'Randomizer run successful!'
            });
          }
        })
        .catch((error) => {
          adminButton.current.removeAttribute('disabled', 'disabled');
        });
    });
  };

  const createDate = () => {
    let date = new Date();
    const d = date.getUTCDate();
    const m = date.getUTCMonth() + 1;
    const y = date.getUTCFullYear();
    return '' + y + m + d;
  };


  const handleReset = (event) => {
    event.preventDefault();
    resetButton.current.setAttribute('disabled', 'disabled');
    SimulationResetService(resetFormValues.resetinputEmail,resetFormValues.resetpassword,resetFormValues.resetDate).then((res) =>{

       // See deleted and updated items
       res
       .blob()
       .then((blob) => {
         resetButton.current.removeAttribute('disabled', 'disabled');
         const date = resetFormValues.resetDate ? resetFormValues.resetDate.replace(/-/g, '') : createDate();
      
         let url = window.URL.createObjectURL(blob);
         let a = document.createElement('a');
         a.href = url;
         a.download = 'Reset' + date + '.csv';
         a.click();

         setSuccess(true);
         setResetResponseValues({
          message: 'Reset Done!'
        });
       })
       .catch((error) => {
         resetButton.current.removeAttribute('disabled', 'disabled');
         setResetResponseValues({
          message: 'Error: Check credentials!'});
       });
    }).catch((error) => {
      adminButton.current.removeAttribute('disabled', 'disabled');
      setResetResponseValues({
        message: 'Error: Check credentials!'});
    });
  };

  const handleGetPodRun = (e) => {
    e.preventDefault();
    GetPodRun(podRunDate).then((res) => {
      console.log(res);
      if (res.status !== 200){
        setErrorMessage("Check credentials!");
      }
      else {
        res.blob().then((blob) => {
          const date = podRunDate ? podRunDate.replace(/-/g, '') : createDate();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'PodRun_' + date + '.csv';
          a.click(); 
        })
      }
    })
  }

  const handleGetCancelledMembers = (e) => {
    e.preventDefault();
    GetCancelledMembers(stripeEndDate).then((res) => {
      console.log(res);
      if (res.status !== 200){
        setErrorMessage("Check credentials!");
      }
      else {
        res.blob().then((blob) => {
          const date = stripeEndDate ? stripeEndDate.replace(/-/g, '') : createDate();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Cancelled_Members_' + date + '.csv';
          a.click(); 
        })
      }
    })
  }

  return (
    <div className='container flex-nowrap'>
      <div className='row'>
        <Sidebar />
        <div className='no-gutters col-105 mt-2'>
          {loading ? <Spinner className='spinner-container' /> : ''}
          <Topbar />
          <div className='ml-3'>
            <div>
              <form onSubmit={handleSubmit}>
                <div className='form-row'>
                  <div className='form-group col-md-8'>
                    <label htmlFor='inputEmail'>Email</label>
                    <input
                      className='form-control'
                      placeholder='janedoe@gmail.com'
                      type='email'
                      id='inputEmail'
                      name='inputEmail'
                      onChange={handleChange}
                      value={formValues.inputEmail}
                      required
                      autoComplete='new-password'
                    />
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='lastname'>Password</label>
                    <input
                      className='form-control'
                      placeholder=''
                      type='password'
                      id='password'
                      name='password'
                      onChange={handleChange}
                      autoComplete='new-password'
                      required
                      value={formValues.password}
                    />
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='submittedDate'>Submitted Date</label>
                    <input className='form-control' placeholder='' type='date' id='submittedDate' name='submittedDate' onChange={handleChange} autoComplete='off' value={formValues.submittedDate} />
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='numRuns'>Number of Runs</label>
                    <input className='form-control' placeholder='' type='number' min='0' id='numRuns' name='numRuns' onChange={handleChange} autoComplete='off' value={formValues.numRuns} />
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='isSimulation'>Is Simulation</label>
                    <select className='form-control' placeholder='' type='password' id='isSimulation' name='isSimulation' onChange={handleChange} autoComplete='off' value={formValues.isSimulation}>
                      <option value=''></option>
                      <option value='Y'>Y</option>
                    </select>
                  </div>
                </div>
                <div>
                  <p style={success ? { color: 'green' } : { color: '#A91212' }}>{responseValues.message}</p>
                </div>
                <button ref={adminButton} className='btn-sm btn btn-primary mt-3'>
                  Run Randomizer
                </button>
              </form>
            </div>

            <div style={{marginTop: '5em'}}>
              <form onSubmit={handleReset}>
                <div className='form-row'>
                  <div className='form-group col-md-8'>
                      <p>Do a reset after a simulation.</p>
                      <p>Deletes and resets data from Simulation Assignment and Simulation Submission Table starting from the specified date.</p>
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='resetinputEmail'>Email</label>
                    <input
                      className='form-control'
                      placeholder='janedoe@gmail.com'
                      type='email'
                      id='resetinputEmail'
                      name='resetinputEmail'
                      onChange={handleChange}
                      value={resetFormValues.resetinputEmail}
                      required
                      
                    />
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='lastname'>Password</label>
                    <input
                      className='form-control'
                      placeholder=''
                      type='password'
                      id='resetpassword'
                      name='resetpassword'
                      onChange={handleChange}
                      required
                      value={resetFormValues.resetpassword}
                    />
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='resetDate'>Date To Reset From</label>
                    <input className='form-control' placeholder='' type='date' id='resetDate' name='resetDate' onChange={handleChange} autoComplete='off' value={resetFormValues.resetDate} required />
                  </div>
                </div>
                <div>
                  <p style={success ? { color: 'green' } : { color: '#A91212' }}>{resetResponseValues.message}</p>
                </div>
                <button ref={resetButton} className='btn-sm btn btn-primary mt-3'>
                  Reset
                </button>
              </form>
            </div>

            <div style={{marginTop: '5em'}}>
              <form onSubmit={handleGetPodRun}>
                <div className='form-row'>
                  <div className='form-group col-md-8'>
                      <p>Download CSV file containing details of a pod run on the specified date.</p>
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='podRunDate'>Pod Run Date</label>
                    <input className='form-control' type='date' id='podRunDate' name='podRunDate' onChange={handleChange} autoComplete='off' value={podRunDate} required />
                  </div>
                </div>
                <div>
                  {errorMessage ? <div style={{color: 'red'}}>{errorMessage}</div> : ''}
                </div>
                <button className='btn-sm btn btn-primary mt-3'>
                  Download
                </button>
              </form>
            </div>

            <div style={{marginTop: '5em'}}>
              <form onSubmit={handleGetCancelledMembers}>
                <div className='form-row'>
                  <div className='form-group col-md-8'>
                      <p>Download CSV file containing details of paying members whose subscription ended on and before the specified date.</p>
                  </div>
                  <div className='form-group col-md-8'>
                    <label htmlFor='stripeEndDate'>Stripe Subscription End Date (UTC)</label>
                    <input className='form-control' type='date' id='stripeEndDate' name='stripeEndDate' onChange={handleChange} autoComplete='off' value={stripeEndDate} required />
                  </div>
                </div>
                <div>
                  {errorMessage ? <div style={{color: 'red'}}>{errorMessage}</div> : ''}
                </div>
                <button className='btn-sm btn btn-primary mt-3'>
                  Download
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminPage;
