import React, { useEffect, useState, useContext } from "react";
import styles from "../Homepage/Homepage.module.css";
import { Context } from "../../Store/Store";
import { TransformUserData } from "../../Services/UserDataService";

const logoStyle = {
  maxWidth: "100%",
  maxHeight: "100vh",
  margin: "auto",
};

const IndividualNotification = () => {
  const [notification, setNotification] = useState();
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    if (state.user["data"] === undefined) {
      const t = localStorage.getItem("user");
      const u = JSON.parse(t);
      if (u) {
        dispatch({
          type: "SET_USER",
          payload: {
            data: TransformUserData(u),
          },
        });
      }
    }

    //retrieve notification content, this is set in NotificationPage.jsx
    const notifications = localStorage.getItem("FP_notifications");
    const notification_objects = JSON.parse(notifications);
    //console.log(notification_objects)
    if (notification_objects) {
      const notificationId = window.location.href.split("/").pop();
      notification_objects.map((object, index) => {
        if (Object.keys(object)[0] === notificationId) {
          setNotification(notification_objects[index][notificationId]);
        }
        return null;
      });
    }
  }, [dispatch, state.user]);

  useEffect(() => {
    console.log(notification);
  }, [notification]);

  return (
    <div>
      <div className="row flex-nowrap no-gutters">
        <div className="no-gutters col-105 mt-2">
          <div className={`${styles["background"]}`}>
            <div className={`${styles["main"]} mt-5 `}>
              <div className={`${styles["w-950"]} m-auto`}>
                <h6>Hi {state.user.data ? state.user.data.firstName : ""},</h6>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <h4>{notification && notification.header ? notification.header : ""}</h4>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  {notification && notification.intro ? notification.intro : ""}
                </div>
                <div style={{ height: "100%", display: "grid", marginBottom: "20px" }}>
                  {notification && notification.image_url ? (
                    <img src={notification.image_url} style={logoStyle} alt="filtpod-logo"></img>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{ marginBottom: "20px" }}
                  dangerouslySetInnerHTML={{ __html: notification && notification.body ? notification.body : "" }}
                ></div>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  {notification && notification.cta ? (
                    <button className={`${styles["cta-button"]}`}>
                      <a style={{ color: "inherit" }} href={notification.cta_link} target="__blank">
                        {notification.cta}
                      </a>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div>{notification && notification.closing ? notification.closing : ""}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualNotification;
