import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { FaPlus } from "react-icons/fa";

const styles = {
  Paper: {
    borderRadius: "7px",
    border: "1px solid #D1D9E2",
    backgroundColor: "#F5F5FB80",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    height: "197px",
    width: "100%",
    gap: ".8rem",
    transition: "all .4s ease-in",
    cursor: "pointer",
    "&:hover": {
      background: "#00000008",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "600",
      color: "#9EA5AC",
      margin: "0",
    },
  },
  AddBtn: {
    width: "40px",
    height: "40px",
    padding: "8px",
    backgroundColor: "#9EA5AB",
    color: "white",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#9EA5AB",
    },
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
};

function AddTone({ disabled, tooltipText }) {
  return disabled ? (
    <Tooltip title={tooltipText} placement="bottom">
      <Paper
        sx={{
          ...styles.Paper,
          ...(disabled
            ? {
                cursor: "not-allowed",
                "&:hover": {
                  background: "#F5F5FB80",
                },
              }
            : {}),
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <IconButton disabled={disabled} sx={styles.AddBtn} variant="contained">
          <FaPlus />
        </IconButton>
        <Typography>Add tone</Typography>
      </Paper>
    </Tooltip>
  ) : (
    <Paper sx={styles.Paper}>
      <IconButton sx={styles.AddBtn} variant="contained">
        <FaPlus />
      </IconButton>
      <Typography>Add tone</Typography>
    </Paper>
  );
}

export default AddTone;
