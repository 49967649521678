import domain from "./DomainService";

/**
 * Returns a Promise
 *
 * @param {string} inputEmail admin email address
 * @param {string} inputPassword admin password
 * @param {string} isSimulation is this a simulation run. Answers are Y or blank
 * @param {string} submittedDate date as a string in the form yyyyMMdd
 * @param {string} numberOfRuns The user's input url
 * @return {Promise}
 */
export default function RunRandomizerService(inputEmail, inputPassword, isSimulation, submittedDate, numberOfRuns) {
  const pod_id = "TIC1";
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "text/csv",
    },
  };

  if (isSimulation) {
    const url =
      "randomizer?username=" +
      inputEmail +
      "&password=" +
      encodeURIComponent(inputPassword) +
      "&pod_id=" +
      pod_id +
      "&submitted_date=" +
      submittedDate +
      "&number_of_runs=" +
      numberOfRuns +
      "&is_simulation=" +
      isSimulation;
    return fetch(domain() + url, options);
  } else {
    const url = "randomizer?username=" + inputEmail + "&password=" + inputPassword + "&pod_id=" + pod_id;
    return fetch(domain() + url, options);
  }

}
