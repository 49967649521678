import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import Styles from "./Header.module.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationCountService } from "../../Services/NotificationService";
import { TransformUserData } from "../../Services/UserDataService";
import { Context } from "../../Store/Store";
import { constants } from "../../Utils/constants";

function Header() {
  const [state, dispatch] = useContext(Context);
  const [count, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const redirect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    // Check localstorage to see if user data exists
    // Use case is when a user refreshes and we lose the context store
    // Rehydrate user data from localstorage
    // Have to use payload: {data: {}} data inside is to match the shape of the original object
    // without this the conext store isn't readable

    if (state.user["data"] === undefined) {
      const t = localStorage.getItem("user");
      const u = JSON.parse(t);

      if (u) {
        dispatch({
          type: "SET_USER",
          payload: {
            data: TransformUserData(u),
          },
        });
      }
    }
    return () => {};
  }, [dispatch, state.user]);

  // Profil dropdown
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // MenuItems click handlers
  const account = (e) => {
    handleCloseMenu();
    navigate("/Account");
  };

  const billing = (e) => {
    handleCloseMenu();
    navigate("/Billing");
  };

  const logOut = (e) => {
    handleCloseMenu();
    localStorage.clear();
    // clear state
    dispatch({
      type: "RESET_STATE",
    });
    navigate("/Login");
  };

  // Notifications
  useEffect(() => {
    NotificationCountService("get").then((res) => {
      res.json().then((json) => {
        setUnreadCount(json.data?.unread);
      });
    });
  }, []);

  const handleNotifClick = () => {
    setUnreadCount(0);
    NotificationCountService("set").then((res) => {
      if (res.status === 200) {
        setUnreadCount(0);
        redirect("/Notification");
      }
    });
  };

  return (
    <header style={{ margin: "1rem 2.5rem 1rem 2.5rem" }}>
      <nav className={Styles.nav}>
        <Box display="flex" alignItems="center" gap="2rem" style={{ marginLeft: "auto" }}>
          <IconButton onClick={handleNotifClick} className={Styles.notifBtn}>
            <img src="/images/notification.svg" alt="notifications icon" />
            {count > 0 && <sup className={Styles.badge}>{count}</sup>}
          </IconButton>
          <div>
            <div style={{ cursor: "pointer" }} onClick={handleClickMenu}>
              <Avatar className={Styles.profil} />
            </div>
            <Menu
              id="profil-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={account}>Account</MenuItem>
              <MenuItem onClick={billing}>Billing</MenuItem>
              <MenuItem>
                <a
                  className={Styles["profilMenu-link"]}
                  href="https://engage-ai.co/affiliate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Refer and Earn
                </a>
              </MenuItem>
              {state.user?.data?.group === constants.User.MEMBER && (
                <MenuItem>
                  <a
                    className={Styles["profilMenu-link"]}
                    href="https://tally.so/r/wgdYdN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </MenuItem>
              )}
              <hr></hr>
              <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu>
          </div>
        </Box>
      </nav>
    </header>
  );
}

export default Header;
