import React, { useEffect, useState, useContext } from "react";
// import Spinner from "../Spinner/Spinner";
// import FormInputAccount from '../Account/FormInputAccount';
import styles from "./Institute.module.css";
import { Context } from "../../Store/Store";
import Lesson from "./Lesson";
import InstituteService from "../../Services/InstituteService";
import { Box } from "@mui/material";

const InstitutePage = () => {
  const context = useContext(Context);
  const [firstName, setFirstName] = useState("");
  const [lessons, setLessons] = useState();

  useEffect(() => {
    if (context[0].user["data"] !== undefined) {
      setFirstName(context[0].user["data"].firstName);
    }
    InstituteService().then((res) => {
      res.json().then((json) => {
        setLessons(json.data);
      });
    });
    return () => {};
  }, [context]);

  return (
    <div>
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <Box className="headerText" mb="1.5rem">
            <Box display="flex" alignItems="center" gap="8px">
              <h1>Institute</h1>
            </Box>
        </Box>
        <div style={{ flex: "1" }}>
          <div className={`${styles["background"]} mt-2`}>
            <div className={`${styles["box"]} `}>
              <div className={`${styles["box"]} mt-2`}>
                <div style={{ marginBottom: "15px" }}>Hi {firstName}, we’re glad to have you here.</div>
                <div>
                  As a member of Engage AI, you have exclusive access to The Institute, which allows you to master
                  Engage AI and LinkedIn, get your name out there, and ultimately generate revenue for your business.
                </div>
              </div>
              <div className={`mb-2 mt-4`}></div>
              {console.log(lessons)}
              {lessons ? lessons.map((lesson) => <Lesson lesson={lesson} key={lesson.id} />) : ""}
            </div>
          </div>
        </div>
        {/* <div className="diplay-container col-4 mt-2">{content}</div> */}
      </div>
    </div>
  );
};

export default InstitutePage;
