/**
 * Choose the correct backend url by looking at the user's hostname
 *
 * @return {string} backend url
 */
export default function domain() {
  if (window.location.hostname === "apps.filtpod.com" || window.location.hostname === "apps.engage-ai.co") {
    return "https://prod.engageai-server.com/";
  } else if (
    window.location.hostname === "filtpod-frontend-test.web.app" ||
    window.location.hostname === "filtpod-frontend-test.firebaseapp.com"
  ) {
    return "https://test.engageai-server.com/";
  } else {
    //
    return "http://127.0.0.1:8000/";
  }
}
