import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EngagementTable from "./EngagementTable";
import Spinner from "../Spinner/Spinner";
import GetEngagement from "../../Services/GetEngagements";
import MarkCompleteService from "../../Services/MarkComplete";
import { checkServiceResponse } from "../../Services/AuthService";
import styles from "./EngagementTable.module.css";
import SaveDeviceService from "../../Services/SaveDeviceService";
import app from "../../firebase";
import { getMessaging, getToken } from "firebase/messaging";

const messaging = getMessaging(app);

const exclamation = (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.50009 3.00008C3.36748 3.00008 3.2403 3.05276 3.14653 3.14653C3.05276 3.2403 3.00008 3.36748 3.00008 3.50009V28.5007C3.00008 28.7767 3.22408 29.0007 3.50009 29.0007H8.50021C8.89805 29.0007 9.27959 29.1588 9.5609 29.4401C9.84221 29.7214 10.0003 30.1029 10.0003 30.5008V36.8809L16.8544 30.0268C17.5105 29.3703 18.4004 29.0012 19.3285 29.0007H38.501C38.6336 29.0007 38.7608 28.9481 38.8545 28.8543C38.9483 28.7605 39.001 28.6333 39.001 28.5007V3.50009C39.001 3.36748 38.9483 3.2403 38.8545 3.14653C38.7608 3.05276 38.6336 3.00008 38.501 3.00008H3.50009ZM0 3.50009C0 1.56804 1.56804 0 3.50009 0H38.501C40.433 0 42.0011 1.56804 42.0011 3.50009V28.5007C42.0011 29.429 41.6323 30.3193 40.9759 30.9757C40.3195 31.6321 39.4293 32.0008 38.501 32.0008H19.3285C19.2628 32.0007 19.1977 32.0135 19.137 32.0386C19.0762 32.0636 19.021 32.1004 18.9745 32.1468L11.9743 39.147C11.5668 39.5543 11.0476 39.8317 10.4825 39.9441C9.91734 40.0564 9.33157 39.9987 8.79922 39.7782C8.26687 39.5578 7.81183 39.1844 7.49161 38.7054C7.17139 38.2264 7.00038 37.6631 7.00018 37.0869V32.0008H3.50009C2.57181 32.0008 1.68155 31.6321 1.02515 30.9757C0.368758 30.3193 0 29.429 0 28.5007V3.50009ZM21.0005 7.00018C21.3984 7.00018 21.7799 7.15822 22.0612 7.43953C22.3425 7.72084 22.5006 8.10238 22.5006 8.50022V16.5004C22.5006 16.8983 22.3425 17.2798 22.0612 17.5611C21.7799 17.8424 21.3984 18.0005 21.0005 18.0005C20.6027 18.0005 20.2212 17.8424 19.9398 17.5611C19.6585 17.2798 19.5005 16.8983 19.5005 16.5004V8.50022C19.5005 8.10238 19.6585 7.72084 19.9398 7.43953C20.2212 7.15822 20.6027 7.00018 21.0005 7.00018ZM21.0005 25.0006C21.531 25.0006 22.0397 24.7899 22.4148 24.4148C22.7899 24.0398 23.0006 23.531 23.0006 23.0006C23.0006 22.4701 22.7899 21.9614 22.4148 21.5863C22.0397 21.2113 21.531 21.0005 21.0005 21.0005C20.4701 21.0005 19.9614 21.2113 19.5863 21.5863C19.2112 21.9614 19.0005 22.4701 19.0005 23.0006C19.0005 23.531 19.2112 24.0398 19.5863 24.4148C19.9614 24.7899 20.4701 25.0006 21.0005 25.0006Z"
      fill="#5667A5"
    />
  </svg>
);

const EngagementPage = () => {
  const [loading, setIsLoading] = useState(false);
  const [podDate, setPodDate] = useState();
  const [engagements, setEngagementValues] = useState([]);
  const [tz, setTz] = useState();

  let history = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    GetEngagement()
      .then((res) => {
        res.json().then((json) => {
          let checkServiceRes = checkServiceResponse(json, res);

          if (res.status !== 200 && checkServiceRes["pathname"]) {
            history.push(checkServiceRes.pathname);
          } else {
            let data = json.data;
            if (data.length > 0) {
              setTz(data[0].pod_timezone);
              setPodDate(data[0].date_assigned);
              if (data.length > 2) {
                // Set data state
                let currentIndex = data.length;

                // Randomize the data to display to the user
                while (currentIndex !== 0) {
                  // Pick a remaining element...
                  let randomIndex = Math.floor(Math.random() * currentIndex);
                  currentIndex -= 1;

                  // And swap it with the current element.
                  let temporaryValue = data[currentIndex];
                  data[currentIndex] = data[randomIndex];
                  data[randomIndex] = temporaryValue;
                }
              }

              setEngagementValues(data);
            }
            setIsLoading(false);
            //});
          }
        });
      })

      .catch((error) => {
        setIsLoading(false);
        //alert(error);
      });
  }, [history]);

  const handleOpen = async (item) => {
    const id = item.id;
    let text = item.suggested_response;
    // copy suggested response to clipboard
    await navigator.clipboard.writeText(text);

    MarkCompleteService(id).then((markCompleteRes) => {
      if (markCompleteRes.status === 200) {
        // Update the state of this button
        let currentVals = [...engagements];

        for (let i = 0; i < engagements.length; i++) {
          if (currentVals[i]["id"] === id) {
            console.log("Found assignment, turning to true");
            currentVals[i]["is_complete"] = true;
            setEngagementValues(currentVals);
            break;
          }
        }
      }
    });

    // open link
    let newWin = window.open(item.link, "_blank", "noopener,noreferrer");
    if (newWin) {
      newWin.opener = null;
    }
  };

  const formatDate = () => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: tz,
    };
    const date = new Date(podDate).toLocaleDateString("en-GB", options);

    return date;
  };

  // display allow notificatio dialog box
  const displayNotification = () => {
    Notification.requestPermission()
      .then(() => {
        return getToken(messaging, {
          vapidKey: "BDy8ZpjLtHfj_63yJpUzDqPTew0XalbONBmFGGmZ0GFS17mQldOAFjJ6WBpDTCYImIQRZFpaD13I6dBBVzEdSMY",
        });
      })
      .then((token) => {
        console.log("firebase token: ", token);
        SaveDeviceService(token, "engagement-reminder").then((res) => {
          if (res.status === 200) {
            alert("You've allowed notifications.");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const content = (
    <div className="px-3 py-3">
      <p>
        Leave insightful comments and "Like" your assigned posts{" "}
        <strong>within 120 minutes of the scheduled time.</strong> Remember, the comments you leave will be seen by your
        potential clients, you don't want to ruin your own reputation.
      </p>
      <p>Comments such as "Great post" or "Thanks for sharing" are not acceptable comments.</p>
      <p className="red-font">Why is this important?</p>
      <ul>
        <li>
          Remember, before using the services you provide, your potential clients will want to find out everything about
          you. This includes your comments on others' posts on LinkedIn
        </li>
        <li>These substandard comments will only ruin your personal brand</li>
        <li>These substandard comments will expose your pod activities using a pod</li>
      </ul>
      <hr></hr>
      <p>Always connect with the pod members as you comment on their posts.</p>
      <p>
        This is important to <strong>keep your LinkedIn account safe</strong> because always engaging but never
        connecting will flag your account to LinkedIn.
      </p>
      <p>
        <strong>Please use the following message when connecting with pod members</strong>
      </p>
      <p>Hi %first name%</p>
      <p>
        We share mutual connections and a passion for helping others. Based on these common factors I would like to be a
        part of your LI Network.
      </p>
      <span>Regards</span>
      <br></br>
      <span>%your name%</span>
    </div>
  );

  return (
    <div>
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div style={{ flex: "1" }}>
          {loading ? <Spinner className="spinner-container" /> : ""}
          <div className={`${styles["background"]}`}>
            <div className={`${styles["main"]} mt-5 `}>
              <div className="row  justify-content-between ">
                <div>
                  <p className={`${styles["title"]} h4`}>Engagement</p>
                  <div className={`${styles["notification"]}`} onClick={displayNotification}>
                    <p>
                      <span role="img" aria-label="finger-pointing-right">
                        &#128073;
                      </span>{" "}
                      <b>Get A Reminder:</b> Ensure timely engagements to keep your 100 Reputation Score.{" "}
                      <span role="img" aria-label="finger-pointing-left">
                        &#128072;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">{podDate ? "Pod Date: " + formatDate() : ""}</div>
            </div>
            <div className="">
              <div className="">
                <EngagementTable engagements={engagements} handleOpen={handleOpen} loading={loading} />
              </div>
              <div className={`${styles["message-box"]}`}>
                <div className="mb-4">{exclamation}</div>
                {content}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="diplay-container col-4 mt-2">{content}</div> */}
      </div>
    </div>
  );
};

export default EngagementPage;
