import domain from "./DomainService";

/**
 * Saves members' complaints about other members to db.
 *
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function ReportMemberService(member_id,link,remark) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body : JSON.stringify({member_id: member_id, link: link, remark:remark})
  };
  
  return fetch(domain() + "report-member", options);
}

/**
 * Gets members' complaints and display them on admin page for verification
 * @returns 
 */
export function GetMembersReportService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  
  return fetch(domain() + "report-member", options);
}