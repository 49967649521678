import React, {useState} from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar';
import Bottombar from './Bottombar';
import { Link } from 'react-router-dom';   

const Welcome = () => {
    const [showModal, setShowModal] = useState(false);
    
    return (
        <div >
        <div className={`${styles['body']} `} onClick={() => {if (showModal) {setShowModal(false)}}}>
            <Topbar/>
            <div className={`${styles['top-arrow']}`} style={{position:"fixed", right:"1vw", top:"1vw", borderRadius:"10px", background:"#3947A8", padding:"24px", textAlign:"center", color:"white"}}>
                <img style={{width:"400px"}} src='/images/pin.gif' alt=''></img>
                <div style={{margin:"12px 0 24px 0"}}>
                    Click the <img style={{width:"14px"}} src='/images/extension_icon.svg' alt=''></img> icon in your browser and pin <b>Hashtag Analytics</b> <img style={{width:"12px"}} src='/images/pin_white.svg' alt=''></img>
                </div>
                <div style={{padding:"12px", background:"white", borderRadius:"8px", width:"max-content", margin:"auto"}}>
                    <Link to='/Onboarding/howitworks' style={{color:"#3947A8", textDecoration:"none"}}>Done <img src='/images/double_check_mark.svg' alt=''></img></Link>
                </div>
            </div>
            <div className={`${styles['main']} ${styles['center-align']}`}>
                <div style={{fontWeight:"700", fontSize:"34px", lineHeight:"51px", color:"#3947A8", marginBottom:"16px"}}>Welcome!</div>
                <div style={{fontWeight:"400", fontSize:"18px", lineHeight:"27px", marginBottom:"15px", color:"#3947A8"}}>Thanks for installing Hashtag Analytics.</div>
                <div style={{background:"#F2F6FA", padding:"24px 40px", borderRadius:"10px", width:"405px", margin:"auto"}}>
                    <img src='/images/pin.svg' alt=''></img>
                    <div style={{fontWeight:"700", fontSize:"20px", margin:"5px 0"}}>Pin this extension!</div>
                    <div style={{fontWeight:"400", fontSize:"14px", margin:"5px 0"}}>Speed up your hashtag search by keeping it in sight.</div>
                </div>
            </div>
            <div style={{width:"max-content", padding:"12px 16px", color:"white", background:"#3947A8", borderRadius:"8px", margin:"auto"}} >
                    <Link to='/Onboarding/howitworks' style={{color:"white", textDecoration:"none"}}>Learn more <img src='/images/right_arrow_white.svg' alt=''></img></Link>
            </div>
            <div style={{position:"fixed", bottom:"0%", width:"100%"}}><Bottombar/></div>
        </div>
        </div>
    );
}
export default Welcome;
