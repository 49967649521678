import { Box } from "@mui/material";
import React, { useContext } from "react";
import { Context } from "../../Store/Store";

const Boost = () => {
  const [state] = useContext(Context);
  const userName = state.user?.data?.firstName;

  return (
    <div>
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <Box className="headerText" mb="1.5rem">
            <Box display="flex" alignItems="center" gap="8px">
              <h1>{`Welcome ${userName}!`}</h1>
            </Box>
        </Box>
        <div style={{ flex: "1" }}>
          <div style={{ marginTop: "3rem" }}>
            <div>
              Give your LinkedIn Post a strong boost of visibility for better audience reach and maximise your
              opportunities.
            </div>
            <div>
              Join this closely-guarded community before applications close and turbocharge your LinkedIn profile.
            </div>
            <div
              style={{
                marginTop: "10px",
                padding: "12px",
                background: "#F2F6FA",
                border: "none",
                borderRadius: "6px",
                width: "max-content",
              }}
            >
              <a
                href="https://filtpod.com/get-invite/"
                target="_blank"
                style={{ color: "#2F3EAC" }}
                rel="noopener noreferrer"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boost;
