import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Spinner from '../Spinner/Spinner';
import Topbar from '../Topbar/Topbar';
import styles from './MembersReportForm.module.css';
import GetHotApplicantsService, {UpdateHotApplicantsService} from '../../Services/HotApplicantsService';

const minusCircle = (
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="red">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
    </svg>
)

const HotApplicants = () => {
    const [loading, setIsLoading] = useState(true);
    const [hotApplicants, setHotApplicants] = useState();
    const [hotApplicantsId, setHotApplicantsId] = useState();
    const [trialDates, setTrialDates] = useState();
    const [statuses, setStatuses] = useState();
    const [results, setResults] = useState();
    const [trialPosts, setTrialPosts] = useState();
    const [message, setMessage] = useState();

    useEffect(() => {
        GetHotApplicantsService().then((res)=>{
            res.json().then((json) =>{
                setIsLoading(false);
                setHotApplicants(json);
                let tempArray1 = [];
                let tempArray2 = [];
                let tempArray3 = [];
                let tempArray4 = [];
                let tempArray5 = [];
                Object.keys(json).map((email)=>{
                    tempArray1.push(json[email].trial_run_date);
                    tempArray2.push(json[email].status);
                    tempArray3.push(json[email].result);
                    tempArray4.push(json[email].trial_post);
                    tempArray5.push(email);
                    return null
                })
                setTrialDates(tempArray1);
                setStatuses(tempArray2);
                setResults(tempArray3);
                setTrialPosts(tempArray4);
                setHotApplicantsId(tempArray5);
            })
        })
    }, [])

    const handleChange = idx => (event) => {
        //console.log(idx, event.target.id);
        if (event.target.id.includes("result")) {
            results[idx] = event.target.value;
            setResults(results);
            //console.log(results)
        }
        else if (event.target.id.includes("status")) {
            statuses[idx] = event.target.value;
            setStatuses(statuses);
            //console.log(statuses)
        }
        else if (event.target.id.includes("trial_post")) {
            trialPosts[idx] = event.target.value;
            setTrialPosts(trialPosts);
            //console.log(trialPosts)
        }
        else if (event.target.id.includes("trial_run_date")) {
            trialDates[idx] = event.target.value;
            setTrialDates(trialDates);
            //console.log(trialDates);
        }
        
    };

    const handleClick = () => {
        let data = {};
        for (let i = 0; i < hotApplicantsId.length; i++) {
            data[hotApplicantsId[i]] = {status: statuses[i], trial_post: trialPosts[i], trial_run_date: trialDates[i], result: results[i]}
        }
        setIsLoading(true);
        UpdateHotApplicantsService(data).then((res)=> {
            setIsLoading(false);
            if (res.status === 200) {
               setMessage("Success!");
            }
            else {
                setMessage("error updating applicants' status.")
            }
        }).catch(() => {
            setMessage("error updating applicants' status")
        })
    }

    const removeApplicant = (e, param) => {
        console.log(e);
        setIsLoading(true);
        let data = {};
        for (let i = 0; i < hotApplicantsId.length; i++) {
            if (hotApplicantsId[i] === param){
                data[hotApplicantsId[i]] = {status: statuses[i], trial_post: trialPosts[i], trial_run_date: trialDates[i], result: results[i], is_visible : false}
            }
        }
        UpdateHotApplicantsService(data).then((res)=> {
            setIsLoading(false);
            if (res.status === 200) {
                window.location.reload();
               setMessage("Success!");
            }
            else {
                setMessage("error updating applicants' status.")
            }
        }).catch(() => {
            setMessage("error updating applicants' status")
        })
    }

    return (
        <div className='container '>
            <div className='row flex-nowrap no-gutters'>
                <Sidebar />
                <div className='no-gutters col-105 mt-2'>
                    {loading ? <Spinner /> : ''}
                    <div className={`${styles['row']}`}>
                        <Topbar />
                    </div>
                    <table className={`${styles['table-background']} table`}>
                        <thead>
                            <tr >
                                <th></th>
                                <th >
                                    Date Applied
                                </th>
                                <th >                 
                                    Name
                                </th>
                                <th >
                                    Email
                                </th>
                                <th >
                                    Country
                                </th>
                                <th >
                                    LinkedIn Profile
                                </th>
                                <th >
                                    Recent Posts
                                </th>
                                <th>
                                    Note
                                </th>
                                <th >
                                    Application Status
                                </th>
                                <th >
                                    Trial Post
                                </th>
                                <th >
                                    Trial Run Date
                                </th>
                                <th >
                                    Result
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {hotApplicants ? Object.keys(hotApplicants).map((email, idx)=>{
                                var row = [];
                                row.push(<td><button onClick={(e) => removeApplicant(e, email)} style={{background:"white", border:"none", width:"36px"}}>{minusCircle}</button></td>);
                                row.push(<td>{hotApplicants[email].date_applied}</td>);
                                row.push(<td>{hotApplicants[email].first_name}</td>);
                                row.push(<td>{email}</td>);
                                row.push(<td>{hotApplicants[email].country}</td>);
                                row.push(<td><a href={hotApplicants[email].linkedin_profile} target='__blank'>See profile</a></td>)
                                row.push(<td>{hotApplicants[email].recent_posts.map((post, index) => {return <><a href={post} target="__blank">post {index}</a><br></br></>})}</td>);
                                row.push(<td>{hotApplicants[email].note}</td>);
                                row.push(<td><select id={"status" + idx} defaultValue="default" onChange={handleChange(idx)}
                                                >
                                            <option value={"default"} disabled>
                                                {hotApplicants[email].status}
                                            </option>
                                            <option value="Pending">Pending</option>
                                            <option value="Qualified">Qualified</option>
                                            <option value="High potential">High potential</option>
                                            <option value="Mid potential">Mid potential</option>
                                            <option value="Unsuitable">Unsuitable</option>

                                        </select></td>);
                                row.push(<td><input id={"trial_post"+idx} defaultValue={hotApplicants[email].trial_post} onChange={handleChange(idx)}></input></td>);
                                row.push(<td><input id={"trial_run_date"+idx} defaultValue={hotApplicants[email].trial_run_date} onChange={handleChange(idx)}></input></td>);
                                row.push(<td>
                                            <select id={"result" + idx} defaultValue={hotApplicants[email].result} onChange={handleChange(idx)}
                                                    >
                                                <option value="Pending">Pending</option>
                                                <option value="Signed up">Signed up</option>
                                                <option value="Declined">Declined</option>
                                            </select> 
                                            </td>)
                                return <tr>{row}</tr>
                            }) : ''}
                        </tbody>
                    </table>
                    <button onClick={handleClick}>Save</button>
                    <div>{message}</div>
                </div>
            </div>
        </div>
    )
}
export default HotApplicants