import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FormInput.module.css';

const FormInput = ({ loginHandler, responseValues }) => {
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });

  const handleChange = (event) => {
    event.persist();
    setFormValues((values) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    loginHandler(event, {
      email: formValues.email,
      password: formValues.password
    });
  };

  let history = useNavigate();

  const redirect = useCallback(
    (path) => {
      history(path);
    },
    [history]
  );

  const openForgotPassword = () => {
    redirect('/ForgotPassword');
  };

  const openWebsite = () => {
    window.open("https://engage-ai.co");
  };

  // Call endpoint which calls the Stripe API to get a temporary URL to the user's Stripe portal

  return (
    <form onSubmit={handleSubmit} id='login-form' data-testid='login-form'>
      <div className='form-row d-flex justify-content-center'>
        <div className='col-md-8 mt-5 row' style={{alignItems:"center", cursor:"pointer"}} onClick={openWebsite}>
          <img
              src='/images/engage_ai_logo.svg'
              alt='engageai'
              style={{height:"50px", marginRight:"10px"}}
            />
          <div className='h2'>Engage AI</div>
          
        </div>

        <div className='form-group col-md-8 mt-5'>
          <label htmlFor='email'>Email</label>
          <input
            className={`${styles['form-input3']} form-control  mt-2`}
            placeholder='Email'
            type='email'
            id='email'
            name='email'
            onChange={handleChange}
            value={formValues.email}
            autoComplete='off'
            required
            data-testid='emailInput'
          />
        </div>
        {/* </div>
      <div className="form-row d-flex justify-content-center"> */}
        <div className='form-group col-md-8 mt-3'>
          <label htmlFor='lastname'>Password</label>
          <input
            className={`${styles['form-input3']} form-control mt-2`}
            placeholder='Password'
            type='password'
            id='password'
            name='password'
            autoComplete='on'
            onChange={handleChange}
            value={formValues.password}
            data-testid='passInput'
          />
          <div className='float-right not-a-link' onClick={openForgotPassword}>
            Forgot password?</div>
        </div>
        {/* </div> */}
        {/* <div className="form-row d-flex justify-content-center mt-3"> */}
        <div className='form-group col-md-8 mt-3'>
          <button className={`${styles['submit-button']}`} data-testid='login-submit'>
            Login
          </button>
          <p className='d-flex justify-content-center' style={{ color: '#A91212' }}>
            {responseValues.response}
          </p>
          <div className='d-flex justify-content-center'>
                <div>Don't have an account? <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/Onboarding/CreateAccount'>Sign up</a></span></div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormInput;
