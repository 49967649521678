import React, { useState } from "react";
import styles from "./ChangePassword.module.css";
import Spinner from "../Spinner/Spinner";
import CreateResponseMessage from "../../Services/CreateResponseMessage";

import ChangePasswordService from "../../Services/ChangePassword";

const FormInputChange = () => {
  const [loading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formValues, setFormValues] = useState({
    oldpassword: "",
    password1: "",
    password2: "",
  });
  const [responseValues, setResponseValues] = useState({
    response: { message: "" },
  });

  const handleChange = (event) => {
    event.persist();
    setFormValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
    //setErrors(validate(values));
  };

  const handleSubmit = (event) => {
    // Check if new passwords match
    event.preventDefault();
    if (formValues.password1.length < 9) {
      setResponseValues({
        message: "New Password Must Be At Least 9 Characters Long",
      });
      return;
    }
    if (formValues.password1 !== formValues.password2) {
      setResponseValues({ message: "New Passwords Do Not Match" });
      return;
    }
    if (formValues.oldpassword === formValues.password1) {
      setResponseValues({
        message: "New Password Must Be Different From Your Old Password",
      });
      return;
    }

    ChangePasswordService(formValues).then((res) => {
      setIsLoading(true);
      if (res.status === 200) {
        res.json().then((resetJSON) => {
          setResponseValues({
            message: resetJSON.message,
          });
          setFormValues({ oldpassword: "", password1: "", password2: "" });
          setSuccess(true);
          setIsLoading(false);
        });
      } else if (res.status === 400)
        res.json().then((resetJSON) => {
          setResponseValues({
            message: resetJSON.message,
          });
          setSuccess(false);
          setIsLoading(false);
        });
    });
  };

  return (
    <div className="diplay-container col-8 mt-5">
      {loading ? <Spinner className="spinner-container" /> : ""}
      <div className={` mt-5 col-md-7 ml-2`}>
        <form onSubmit={handleSubmit} id="login-form" data-testid="login-form">
          {/* <div className={` form-group`}>
            <div className="form-row  justify-content-between">
              <div className="col-md-6 ">
                <label htmlFor="firstName">First Name</label>
                <input
                  className={`${styles["form-input"]} form-control`}
                  placeholder="First Name"
                  type="text"
                  id="firstName"
                  name="firstName"
                  onChange={handleChange}
                  value={formValues.firstName}
                  autoComplete="off"
                  required
                  data-testid="firstNameInput"
                />
              </div>
              <div className="col-md-6 ">
                <label htmlFor="lastName">Last Name</label>
                <input
                  className={`${styles["form-input"]} form-control`}
                  placeholder="Last Name"
                  type="text"
                  id="lastName"
                  name="lastName"
                  onChange={handleChange}
                  value={formValues.lastName}
                  autoComplete="off"
                  required
                  data-testid="lastNameInput"
                />
              </div>
            </div>
          </div> */}
                  <div className="form-group mt-4">
                    <label htmlFor="email">Old Password</label>
                    <input
                      autoComplete="new-password"
                      className={`${styles["form-input"]} form-control`}
                      placeholder="Old Password"
                      type="text"
                      id="oldpassword"
                      name="oldpassword"
                      onChange={handleChange}
                      value={formValues.oldpassword}
                      required
                    />
                  </div>
                  <div className="form-group mt-4 mb-4">
                    <label htmlFor="lastname">New Password</label>
                    <input
                      className={`${styles["form-input"]} form-control`}
                      placeholder="New Password"
                      type="password"
                      id="password1"
                      name="password1"
                      onChange={handleChange}
                      autoComplete="new-password"
                      value={formValues.password1}
                    />
                  </div>
                  <div className="form-group mt-4 mb-4">
                    <label htmlFor="lastname">Re-Enter New Password</label>
                    <input
                      className={`${styles["form-input"]} form-control`}
                      placeholder="Re-Enter New Password"
                      type="password"
                      id="password2"
                      name="password2"
                      onChange={handleChange}
                      autoComplete="new-password"
                      value={formValues.password2}
                    />
                  </div>
                <div>
                  <p style={success ? { color: "green" } : { color: "#A91212" }}>{responseValues.message}</p>
                </div>
                <div className={`${styles["button-spacer"]} `}>
                  <div className="row no-gutters">
                    <div className=" col-md-6">
                      <button onClick={handleSubmit} className={`${styles["submit-button"]} `} data-testid="login-submit">
                        Update Password
                      </button>
                    </div>
                  </div>
                </div>
          <div>
            {responseValues.response ? (
            <div data-testid="login-message" style={{ color: "#A91212" }}>
              {CreateResponseMessage(responseValues)}
            </div>
          ) : (
            <div></div>
          )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormInputChange;
