import React, { useState, useEffect } from "react";
import AccountabilityTable from "./AccountabilityTable";
import Spinner from "../Spinner/Spinner";
import GetAccountabilityService from "../../Services/GetAccountability";
import { useNavigate } from "react-router-dom";
import { checkServiceResponse } from "../../Services/AuthService";
import styles from "./Accountability.module.css";
const AccountabilityPage = () => {
  const [loading, setIsLoading] = useState(false);
  const [accValues, setAccValues] = useState([]);
  const [podDate, setPodDate] = useState();
  const [tz, setTz] = useState();
  const [display, setDisplay] = useState(true);

  let history = useNavigate();

  useEffect(() => {
    try {
      setIsLoading(true);
      GetAccountabilityService()
        .then((res) => {
          res.json().then((json) => {
            let checkServiceRes = checkServiceResponse(json, res);
            if (res.status !== 200 && checkServiceRes["pathname"]) {
              history.push(checkServiceRes.pathname);
            } else {
              var data = json.data;
              try {
                if (Object.keys(data).length > 0) {
                  if (Object.keys(data)[0] === "None") {
                    setDisplay(false);
                  } else {
                    for (let i = 0; i < Object.keys(data).length; i++) {
                      if (Object.values(data)[i].length > 0) {
                        setTz(Object.values(data)[i]["pod_timezone"]);
                        setPodDate(Date.now());
                        break;
                      }
                    }
                    setAccValues(data);
                  }
                }
                setIsLoading(false);
              } catch (err) {
                setAccValues([]);
                setIsLoading(false);
              }
            }
          });
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      //alert(error);
    }
  }, [history]);

  const formatDate = () => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: tz,
    };
    const date = new Date(podDate).toLocaleDateString("en-GB", options);
    console.log(date);
    return date;
  };

  return (
    <div>
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div style={{ flex: "1" }}>
          {loading ? <Spinner className="spinner-container" /> : ""}
          <div className={`${styles["background"]}`}>
            <div className={`${styles["main"]} mt-5 `}>
              <div className="row  justify-content-between ">
                <div>
                  <p className={`${styles["title"]} h4 mb-5`}>Accountability</p>
                </div>
              </div>
              <div className="d-flex">{podDate ? "Pod Date: " + formatDate() : ""}</div>
            </div>

            <AccountabilityTable accValues={accValues} loading={loading} display={display} />
          </div>
        </div>
        {/* <div className="diplay-container col-4 mt-2">{content}</div> */}
      </div>
    </div>
  );
};

export default AccountabilityPage;
