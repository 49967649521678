import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { MdInsertDriveFile } from "react-icons/md";

import { template } from "./CSVTemplate";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    paddingTop: "32px",
    textAlign: "center",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },
};

function InvalidCSV() {
  return (
    <Box sx={styles.Modal} display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "56px", width: "56px", background: "var(--color-red)", borderRadius: "8px" }}
      >
        <MdInsertDriveFile style={{ height: "40px", width: "40px", color: "white" }} />
      </Box>
      <h2>Invalid CSV file</h2>
      <span>
        The CSV file you imported is not valid. Please ensure it matches the format and column schema of the provided{" "}
        <CSVLink {...template}>
          <strong style={{ color: "var(--color-brand)", textDecoration: "underline" }}>CSV template.</strong>
        </CSVLink>
      </span>
    </Box>
  );
}

export default InvalidCSV;
