import { Box } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { useState } from "react";
import ImportErrors from "./ImportErrors";
import ImportWarnings from "./ImportWarnings";
import { formateNumber } from "../../../../../Utils/reusableFunctions";

const styles = {
  title: { fontSize: "24px", fontWeight: "700", color: "#1F292E", margin: "0" },
  results: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  resultBox: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    padding: "8px 12px",
    "& span": {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "22px",
    },
  },
  icon: {
    height: "20px",
    width: "20px",
  },
};

const success_color = "rgba(100, 190, 55, 1)";
const warning_color = "rgba(234, 171, 62, 1)";
const error_color = "rgba(214, 31, 31, 1)";

function ImportResultsContent({ results = {} }) {
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  return (
    <>
      <Box display="flex" flexDirection="column" gap="24px">
        <h2 style={styles.title}>Import finished</h2>
        <Box sx={styles.results}>
          {results.imported.length > 0 && (
            <Box
              sx={{
                ...styles.resultBox,
                border: `1px solid ${success_color}`,
                color: "#407a23",
                background: "rgba(239, 248, 235, 1)",
              }}
            >
              <Box display="flex" alignItems="center" gap="8px">
                <FaCheck style={{ ...styles.icon, color: success_color }} />
                <span>{`${formateNumber(results.imported.length)} ${
                  results.imported.length === 1 ? "prospect" : "prospects"
                } successfully saved.`}</span>
              </Box>
            </Box>
          )}
          {results.already_exists.length > 0 && (
            <Box
              sx={{
                ...styles.resultBox,
                border: `1px solid ${warning_color}`,
                color: "rgba(120, 88, 32, 1)",
                background: "rgba(253, 247, 236, 1)",
              }}
            >
              <Box display="flex" alignItems="center" flexGrow={1} gap="8px">
                <RiErrorWarningLine style={{ ...styles.icon, color: warning_color }} />
                <span>{`${formateNumber(results.already_exists.length)} ${
                  results.already_exists.length === 1 ? "prospect" : "prospects"
                } already exists in your account.`}</span>
              </Box>
              <span
                onClick={() => setOpenWarningDialog(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                View
              </span>
            </Box>
          )}
          {results.error.length > 0 && (
            <Box
              sx={{
                ...styles.resultBox,
                border: `1px solid ${error_color}`,
                color: "rgba(137, 20, 20, 1)",
                background: "rgba(251, 233, 233, 1)",
              }}
            >
              <Box display="flex" alignItems="center" flexGrow={1} gap="8px">
                <RiErrorWarningLine style={{ ...styles.icon, color: error_color }} />
                <span>{`${formateNumber(results.error.length)} ${
                  results.error.length === 1 ? "prospect" : "prospects"
                } failed to import.`}</span>
              </Box>
              <span
                onClick={() => setOpenErrorsDialog(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                View
              </span>
            </Box>
          )}
        </Box>
      </Box>

      {openErrorsDialog && (
        <ImportErrors open={openErrorsDialog} onClose={() => setOpenErrorsDialog(false)} errors={results.error} />
      )}

      {openWarningDialog && (
        <ImportWarnings
          open={openWarningDialog}
          onClose={() => setOpenWarningDialog(false)}
          warnings={results.already_exists}
        />
      )}
    </>
  );
}

export default ImportResultsContent;
