import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

const styles = {
  container: {
    height: "100%",
    width: "100%",
    border: "2px dashed rgba(224, 226, 228, 1)",
    background: "rgba(251, 251, 251, 1)",
    borderRadius: "8px",
    padding: "32px 48px",
  },
  dropZoneDiv: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    background: "white",
  },
  lblmsgUpload: {
    paddingTop: "15px",
    paddingLeft: "15px",
  },
  cloudUploadIcon: {
    fontSize: "500",
  },
  limitImgSize: {
    maxWidth: 150,
    maxHeight: 100,
    borderRadius: 10,
  },
  miniatureImage: {
    width: "100%",
    borderRadius: 10,
    objectFit: "cover",
  },
  text: {
    fontSize: "14px",
    fontWeight: "400",
    color: "rgba(65, 80, 88, 1)",
    "& span": {
      fontWeight: "500",
    },
  },
  selectedFile: {
    fontSize: "12px",
    fontWeight: "600",
    color: "rgba(65, 80, 88, 1)",
  },
};

export default function DropZone(props) {
  const [myFiles, setMyFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFiles) => {
      props.onChange(acceptedFiles);
      setMyFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    noClick: props.readOnly,
    multiple: false,
  });

  const files = useMemo(
    function () {
      return myFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    },
    [myFiles]
  );

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section
      style={{
        ...styles.container,
        background: files.length === 0 ? "rgba(251, 251, 251, 1)" : "rgba(235, 236, 247, 1)",
      }}
    >
      <div {...getRootProps({ className: "dropzone" })} style={styles.dropZoneDiv}>
        <input {...getInputProps({ width: "inherit", height: "inherit" })} />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="16px"
          textAlign="center"
          sx={{
            height: "100%",
            cursor: "pointer",
            background: files.length === 0 ? "rgba(251, 251, 251, 1) !important" : "rgba(235, 236, 247, 1)",
          }}
        >
          <Box>
            <CloudUpload style={{ fontSize: 48, color: "#2F3EAC" }} />
          </Box>
          <Box sx={{ width: "100%" }} display="flex" flexDirection="column" gap="8px">
            <Typography sx={styles.text}>
              <span style={{ textDecoration: "underline" }}>Select a file</span> or drag it here{" "}
            </Typography>
            {files.length > 0 && <Typography sx={styles.selectedFile}>{files[0].name}</Typography>}{" "}
          </Box>
        </Box>
      </div>
    </section>
  );
}
