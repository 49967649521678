import domain from "./DomainService";

/**
 * Fetch user's saved hashtag data for display on LHT page
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function GetSavedHashtagsService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  let pod_id = "TIC1";

  return fetch(domain() + "get-saved-hashtags?pod_id=" + pod_id, options);
}

export function DeleteSavedHashtagsService(hashtag) {
  const token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ hashtag: hashtag}),
  };
  let pod_id = "TIC1";

  return fetch(domain() + "get-saved-hashtags?pod_id=" + pod_id, options);
}

export function SaveHashtagsService(hashtag) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ hashtag: hashtag}),
  };
  let pod_id = "TIC1";

  return fetch(domain() + "get-saved-hashtags?pod_id=" + pod_id, options);
}

export function GetSuggestedHashtagsService(email, keyword) {
  const options = {
    method: "PUT",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8"
    },
    
    body: JSON.stringify({ email: email, keyword:keyword}),
};

  return fetch(domain() + "api/suggested-hashtags", options);
}

export function GetHashtagFollowerCountService(email, keyword) {
  const options = {
    method: "POST",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8"
    },
    
    body: JSON.stringify({ username: email, hashtag:keyword}),
};

  return fetch(domain() + "api/get-hashtag-follower-count", options);
}