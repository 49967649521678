import { Box } from "@mui/material";
import { MdInsertDriveFile } from "react-icons/md";

import { formateNumber } from "../../../../../Utils/reusableFunctions";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    paddingTop: "32px",
    textAlign: "center",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },
};

function MaxLimit({ fileDataLength, allProspects = []}) {
  return (
    <Box sx={styles.Modal} display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "56px", width: "56px", background: "var(--color-red)", borderRadius: "8px" }}
      >
        <MdInsertDriveFile style={{ height: "40px", width: "40px", color: "white" }} />
      </Box>
      <h2>Import limit exceeded</h2>
      <span>
        Your CSV file contains <strong>{formateNumber(fileDataLength)} prospects,</strong> but you only have space for{" "}
        <strong>{formateNumber(10000 - allProspects.length)} more prospects</strong> in your account. Please reduce the number of prospects in your
        file and try again.
      </span>
    </Box>
  );
}

export default MaxLimit;
