import domain from "./DomainService";

/**
 * Gets submissions and assignments of a pod run of a specified date
 * Only for admins
 */

export default function GetPodRun(pod_run_date) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ date : pod_run_date }),
  };

  return fetch(domain() + "get-pod-run", options);
}