import React, { useState, useEffect } from 'react';
import EngagementItem from './EngagementItem';
import styles from './EngagementTable.module.css';

const EngagementTable = ({ engagements, handleOpen, loading }) => {
  // TODO when someone clicks and marks the task complete change that value here or pull from server to refresh state?
  const [loadingMessage, setLoadingMessage] = useState('Loading Table...');
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    if (!loading && !initialLoad) {
      setInitialLoad(true);
    } else if (!loading && initialLoad && engagements.length === 0) {
      setInitialLoad(true);

      setLoadingMessage('The Pod Has Yet To Run');
    }
  }, [loading, initialLoad, engagements]);

  return (
    <table className={`${styles['table-background']} table ml-3 `}>
      <thead className=''>
        <tr className={`${styles['table-header']} `}>
          <th scope='col' className='w-30'>
            Poster Full Name
          </th>
          <th scope='col' className='w-10'>
           Post Date
          </th>
          <th scope='col' className='w-10'>
            Status
          </th>
          <th scope='col' className='w-10'>
            Complete
          </th>
        </tr>
      </thead>
      <tbody>
        {engagements.length !== 0 ? (
          engagements.map((item, idx) => {
            return <EngagementItem key={idx} item={item} idx={idx} handleOpen={handleOpen} />;
          })
        ) : (
          <tr>
            <td className='w-30'>{loadingMessage}</td>
            <td className='w-10'></td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default EngagementTable;
