
/**
 * Send to google sheet via Zapier.
 */
export default function SendFeedbackService(formValues) {
  const options = {
    method: "POST",
    body: JSON.stringify({
        "date": formValues.date,
        "name": formValues.name,
        "email": formValues.email,
        "important feature request" : formValues["important feature request"],
        "nice to have feature": formValues["nice to have feature"],
        "how likely to recommend FILT Pod": formValues["how likely to recommend FILT Pod"],
        "can we contact you": formValues["can we contact you"],
      })
  };
  return fetch("https://hooks.zapier.com/hooks/catch/8876124/b7l7899/", options);
}