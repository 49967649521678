import { Paper, Box, LinearProgress, IconButton } from "@mui/material";
import { MdGroups } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { IoIosCheckmark } from "react-icons/io";
import { MdLowPriority } from "react-icons/md";
import { MdOutlineWifiTetheringError } from "react-icons/md";

import { IoClose } from "react-icons/io5";

import "./styles.css";
import StyledButton from "../../ReusableComponents/Button";
import { useNurtureContext } from "../../../../Context/NurtureContext";

const styles = {
  Paper: {
    backgroundColor: "#fff",
    border: "1px solid #E0E2E4",
    borderRadius: "10px",
    paddingBlock: "16px",
    position: "relative",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.04)",
    padding: "32px",
    minHeight: "340px",
    maxHeight: "340px",
    height: "100%",
  },

  Box: {
    paddingInline: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
  },
  Heading: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "600",
    color: "#000",
    margin: 0,
  },
  Text: {
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#415058",
    margin: 0,
    "& span": {
      fontWeight: "500",
    },
  },
  Progress: { width: "100%", color: "var(--color-brand)", background: "#EBEDF6", height: "3px" },
};

function PourcentageCard({ updateFinished, postsFound = [], onCloseCard, onSort, error, onRetry }) {
  const { pourcentage } = useNurtureContext();

  return (
    <Paper sx={{ ...styles.Paper, paddingBlock: updateFinished && postsFound.length ? "24px" : "32px" }}>
      {error ? (
        <Box display="flex" flexDirection="column" alignItems="center" gap="40px" sx={{ height: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "24px", width: "24px", color: "#415058" }}
            alignSelf="flex-end"
          >
            <IconButton onClick={onCloseCard}>
              <IoClose />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" gap="32px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ borderRadius: "8px", width: "56px", height: "56px", background: "#D61F1F" }}
            >
              <MdOutlineWifiTetheringError style={{ color: "white", height: "48px", width: "48px" }} />
            </Box>
            <Box sx={{ ...styles.Box, paddingInline: "0" }}>
              <h3 style={styles.Heading}>Updates failed</h3>
              <Box display="flex" alignItems="center" gap="8px">
                <p style={styles.Text}>An unknown error occurred while attempting to update posts.</p>
              </Box>
            </Box>
            <StyledButton onClick={onRetry} style={{ marginTop: "auto !important" }} variation="noBackgroundPrimary">
              <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", lineHeight: "14.5px" }}>
                Retry
              </span>
            </StyledButton>
          </Box>
        </Box>
      ) : updateFinished && postsFound.length ? (
        <Box display="flex" flexDirection="column" alignItems="center" gap="24px" sx={{ height: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "24px", width: "24px", color: "#415058" }}
            alignSelf="flex-end"
          >
            <IconButton onClick={onCloseCard}>
              <IoClose />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" gap="32px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ borderRadius: "8px", width: "56px", height: "56px", background: "#64BE37" }}
            >
              <IoIosCheckmark style={{ color: "white", height: "48px", width: "48px" }} />
            </Box>
            <Box sx={styles.Box}>
              <h3 style={styles.Heading}>Updates completed</h3>
              <Box display="flex" alignItems="center" gap="8px">
                <p style={{ ...styles.Text, whiteSpace: "nowrap" }}>
                  <span style={{ fontWeight: "600" }}>{postsFound.length}</span> new posts found
                </p>
              </Box>
            </Box>
            <StyledButton onClick={onSort} style={{ marginTop: "auto !important" }} variation="noBackgroundPrimary">
              <MdLowPriority style={{ color: "var(--color-brand)", height: "18px", width: "18px" }} />
              <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", lineHeight: "14.5px" }}>
                Sort by date
              </span>
            </StyledButton>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ marginBlock: "auto" }} gap="32px">
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="loader">
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="iconBox">
                <MdManageSearch className="searchIcon" />
              </div>
            </div>
          </Box>
          <LinearProgress variant="determinate" sx={styles.Progress} value={pourcentage} />
          <Box sx={styles.Box}>
            <h3 style={styles.Heading}>Collecting recents posts</h3>
            <Box display="flex" alignItems="start" gap="8px">
              <MdGroups style={{ marginTop: "2px" }} />
              <p style={styles.Text}>
                checking your prospects <span>[{pourcentage}%]</span>
              </p>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

export default PourcentageCard;
