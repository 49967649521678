import { useMediaQuery, useTheme } from "@mui/material";

const useCenteredTableData = ({ styles, className }) => {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));

  if (smallDisplay) return null;

  const setCellProps = () => ({
    className: smallDisplay ? "" : `centeredBodyCell ${className}`,
    ...styles,
  });

  const setCellHeaderProps = () => ({
    className: smallDisplay ? "" : `centeredHeaderCell ${className}`,
    ...styles,
  });

  return { setCellProps, setCellHeaderProps };
};

export default useCenteredTableData;
