import React from "react";
import LinkButton from "./LinkButton";
import styles from "./EngagementTable.module.css";

const EngagementItem = (props) => {
  const { item, handleOpen } = props;
  const diff_hours = (Date.now() - Date.parse(item.date_assigned)) / (60 * 60 * 1000);
  const formatDate = (d) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const date = new Date(d).toLocaleDateString('en-GB', options);

    return date;
  };
  return (
    <tr className="" data-testid="engagement-row">
      <td className={`${styles["table-header"]} ${styles["row-name-style"]}`}>{item.name}</td>
      <td>{formatDate(item.date_assigned)}</td>
      <td className="">
        <div
          className={
            item.is_complete === true
              ? `${styles["div-status"]} ${styles["div-complete-color"]} text-center`
              : diff_hours > 24 ? `${styles["div-status"]} ${styles["div-overdue-color"]} text-center`
              : `${styles["div-status"]} ${styles["div-pending-color"]} text-center`
          }
        >
          <span className="">{item.is_complete === true ? "Complete" : diff_hours > 24 ? "Overdue" : "Pending"}</span>
        </div>
      </td>

      <td className="">
        <LinkButton item={item} handleOpen={handleOpen} />
      </td>
    </tr>
  );
};

export default EngagementItem;
