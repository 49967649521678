import React from 'react';
import styles from './Onboarding.module.css';
import { Link } from 'react-router-dom';

const Topbar = () => {

  return (
    <div className={`${styles['frow']} `} style={{padding:"0 120px 10px", boxShadow: "rgb(0 0 0 / 20%) 0px 8px 13px -3px"}}>
        <div>
          <a href='https://engage-ai.co/'>
          <img style={{width:'40px'}} src='/images/engage_ai_logo.svg' alt='engageai'></img>
          <span style={{marginLeft:"10px"}}>Engage AI</span>
          </a>
        </div>
        <div className={`${styles['row']} `}>
           
            <div className={`${styles['button']} `}><Link to='/Onboarding/CreateAccount' style={{color:"white"}}>Join Engage Ai for Free</Link></div>
        </div>
    </div>
  );
};
export default Topbar;
