import React from "react";
//this is for newer version but options don't work  
//import { Chart, registerables} from 'chart.js';
//Chart.register(...registerables);
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({xValues, yValues, keyword}) => {
  const lineChartData = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
        label: "#" + keyword,
        borderColor: "#e3ac7a",
        backgroundColor: "#e3ac7a",
        fill: {
          target: 'origin',
          above: 'rgb(56, 90, 159, 0.3)'
        },
        lineTension: 0.5
      }
    ]
  };
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Hashtag Follower Trend',
      },
    },
    scales: {
      y: {
        ticks: {
           //autoSkip:true,
           beginAtZero: true
         },
         title: {
           display:true,
           text: ''
         }
     },
     x: {
       ticks: {
         /** 
        callback: function(val, index) {
          const date = new Date(this.getLabelForValue(val));
          const format = { year: 'numeric', month: 'long', day: 'numeric' };
          // Show every 10th tick label
          return index === 0 ? '': (index === 1 || index % 10 === 0) ? date.toLocaleDateString('en-US',format) : '';
        },
        color: 'red',*/
        autoSkip :true
       }
     }
   }
  };

  return (
    <Line
    type="line"
      width={160}
      height={60}
      options={options}
    data={lineChartData}
    />
  );
};
export default LineChart;