import { Box } from "@mui/material";

import StyledButton from "../../ReusableComponents/Button";

import { IoIosCheckmark } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    paddingTop: "32px",
    textAlign: "center",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },
};
function SucessModal({ onCloseModal, title, text, onClose }) {
  const handleClose = () => {
    onClose();
    onCloseModal();
  };

  return (
    <Box sx={styles.Modal}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ borderRadius: "8px", width: "56px", height: "56px", background: "#64BE37" }}
      >
        <IoIosCheckmark style={{ color: "white", height: "48px", width: "48px" }} />
      </Box>
      <h2>{title}</h2>
      <span>{text}</span>
      <StyledButton onClick={handleClose} variation="primary">
        <IoClose />
        <span>Close</span>
      </StyledButton>
    </Box>
  );
}

export default SucessModal;
