import domain from "./DomainService";

/**
 * For any external user
 * @return {Promise}
 */
export default function GetHashtagsService() {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return fetch(domain() + "api/trending-hashtags" , options);
}
