import { Box, Paper } from "@mui/material";
import Skeleton from "react-loading-skeleton";

const styles = {
  Paper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #E0E2E4",
    borderRadius: "10px",
    paddingBlock: "16px",
    minHeight: "340px",
    position: "relative",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.04)",
  },
  Box: {
    paddingInline: "20px",
    display: "flex",
    // alignItems: "flex-end",
  },
};

function PostSkeleton() {
  return (
    <Paper sx={styles.Paper}>
      <Box sx={styles.Box} justifyContent="space-between" mb="24px">
        <Box display="flex" gap="8px">
          <Skeleton circle height={32} width={32} />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Skeleton width={75} height={14} />
            <Box display="flex" alignItems="center">
              <Skeleton width={27} height={10} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.Box,
          flexDirection: "column",
          gap: "16px",
          "& #skeletonWrapper": { width: "100%" },
          "& .100 span": { width: "100%" },
          "& .80 span": { width: "80%" },
          "& .85 span": { width: "85%" },
          "& .70 span": { width: "70%" },
        }}
      >
        <div style={{ width: "100%" }}>
          <Skeleton />
        </div>
        <div style={{ width: "80%" }}>
          <Skeleton />
        </div>
        <div style={{ width: "90%" }}>
          <Skeleton />
        </div>
        <div style={{ width: "70%" }}>
          <Skeleton />
        </div>
      </Box>
    </Paper>
  );
}

export default PostSkeleton;
