import React, { useState} from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Spinner from '../Spinner/Spinner';
import Topbar from '../Topbar/Topbar';
import {AdminGenerateResponseService} from '../../Services/GenerateResponsesService';

const Generate = () => {
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('The following is a conversation with an AI. The AI is friendly, insightful, smart and concise.');
    const [numResponses, setNumResponses] = useState(1);
    const [message, setMessage] = useState();
    const [apiKey, setApiKey] = useState('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');
    const [inputURL, setinputURL] = useState();
    const [responses, setResponses] = useState();

    const generateResponse = (e) => {
        e.preventDefault();
        console.log(inputURL)
        setLoading(true);
        if (inputURL){
            fetch('https://nameless-wildwood-34548.herokuapp.com/' + inputURL).then(response => response.text()).then((data) => {
                var search = data.replace(/(\r\n|\n|\r)/gm, "").match('<p class="share-update-card__update-text" dir="ltr">(.*?)</p>')
                if (search == null){
                    search = data.replace(/(\r\n|\n|\r)/gm, "").match('data-test-id="main-feed-activity-card__commentary">(.*?)</p>')
                }
                if (search){
                    const post = search[1];
                    AdminGenerateResponseService(post, prompt, apiKey, numResponses).then((response) => {
                        console.log(response);
                        setResponses(response);
                        setLoading(false);
                    }).catch(()=>setLoading(false))
                }
                else {
                    setMessage("Cannot scrape post content.");
                    setLoading(false);
                }
            }).catch(()=>setLoading(false))
        }
        else {
            setMessage('Please enter a valid LinkedIn post URL.');
            setLoading(false);
        }
    }


const handleChange = (e) => {
    const id = e.target.id;
    //console.log(id, e.target.value)
    setMessage();
    if (id === 'prompt'){
        setPrompt(e.target.value);
    }
    else if (id === 'num_responses') {
        setNumResponses(e.target.value)
    }
    else if (id === 'api_key') {
        setApiKey(e.target.value);
    }
    else if (id === 'li_post_url') {
        const url = e.target.value.trim();
        if (url !== '' && /^((https?):\/\/)?([w|W]{3}\.)*[a-zA-Z0-9\-.]{3,}\.[a-zA-Z]{2,}.*/.test(url) && url.includes("linkedin")) {
            setinputURL(url);
        } else {
            //console.log("invalid link");
            setMessage('Invalid link');
        }
    }
}
    

    return (
        <div className='container '>
            <div className='row' style={{flexWrap:"nowrap"}}>
                <Sidebar />
                <div style={{flex:"1 0 80%", width:"100vw"}}>
                    {loading ? <Spinner /> : ''}
                    <Topbar />
                    <div style={{margin:"10px"}}>
                        <form onSubmit={generateResponse}>
                            <div style={{fontSize:"18px", fontWeight:"700", lineHeight:"24px", marginBottom:"10px"}}>OpenAI API Key</div>
                                <div>
                                    <textarea
                                        rows="1" 
                                        id='api_key'
                                        name='api_key'
                                        onChange={handleChange}
                                        style={{width:"100%"}}
                                        placeholder={apiKey}
                                        />
                                </div>
                            <div style={{fontSize:"18px", fontWeight:"700", lineHeight:"24px", marginBottom:"10px"}}>Prompt</div>
                            <div>
                                <textarea
                                    rows="2" 
                                    id='prompt'
                                    name='prompt'
                                    onChange={handleChange}
                                    style={{width:"100%"}}
                                    placeholder={prompt}/>
                            </div>
                            <div style={{fontSize:"18px", fontWeight:"700", lineHeight:"24px", marginBottom:"10px"}}>Number of Responses to Generate</div>
                            <div>
                                <input type="number" min="0" step="1" 
                                    id='num_responses'
                                    name='num_responses'
                                    onChange={handleChange}
                                    style={{width:"100%"}}
                                    required/>
                            </div>
                            <div style={{fontSize:"18px", fontWeight:"700", lineHeight:"24px", marginBottom:"10px"}}>LinkedIn Post URL:</div>
                            <div >
                                <textarea
                                    rows="2" 
                                    id='li_post_url'
                                    name='li_post_url'
                                    onChange={handleChange}
                                    style={{width:"100%"}}
                                    required/>
                                </div>
                                <div>
                                <button className={` mt-2`} >
                                    Generate
                                </button>
                            </div>
                        </form> 
                        <div>{message}</div>
                        <div>{responses ? responses.map((item, idx)=>{
                            return <div style={{marginBottom:"8px"}}>{item}</div>
                        }) : ''}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Generate