import domain from "./DomainService";

/**
 * Central place to create the payload for SET_USER reducer
 * @param {object} data
 * @return {object} key value pair
 */
export function TransformUserData(data) {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    id: data.id,
    podAccess: data.podAccess,
    mainPod: data.mainPod,
    podSubgroup: data.podSubgroup,
    accessiblePods: data.accessiblePods,
    is_first_login: data.is_first_login,
    linkedinPage: data.linkedinPage,
    group: data.group,
    is_paying: data.is_paying,
    is_staff: data.is_staff,
    ui: data.ui,
    max_number_of_regenerated_AI_responses: data.max_number_of_regenerated_AI_responses,
    max_number_of_leads: data.max_number_of_leads,
    days_to_run_lead_nurturing: data.days_to_run_lead_nurturing,
    time_to_scrape_prospects_posts_local: data.time_to_scrape_prospects_posts_local,
    trial_end_date: data.trial_end_date,
    get_notified_when_prospects_post: data.get_notified_when_prospects_post,
    get_notified_day: data.get_notified_day,
    get_notified_hour_local: data.get_notified_hour_local,
    linkedin_email: data.linkedin_email,
    linkedin_first_name: data.linkedin_first_name,
    linkedin_last_name: data.linkedin_last_name,
    linkedin_access_token_expiry_date: data.linkedin_access_token_expiry_date,
    open_ai_api_key: data.open_ai_api_key,
  };
}

export function TransformAPIDataToUserData(data) {
  return {
    id: data.data.user["id"],
    firstName: data.data.user["first_name"],
    lastName: data.data.user["last_name"],
    email: data.data.user["email"],
    podAccess: data.data.user["pod_access"],
    mainPod: data.data.user["main_pod"],
    podSubgroup: data.data.user["pod_subgroup"],
    accessiblePods: data.data.user["accessible_pods"],
    is_first_login: data.data.user["is_first_login"],
    linkedinPage: data.data.user["linkedin"],
    group: data.data.user["group"],
    is_paying: data.data.user["is_paying"],
    is_staff: data.data.user["is_staff"],
    ui: data.data.user["ui"],
    max_number_of_regenerated_AI_responses: data.data.user["max_number_of_regenerated_AI_responses"],
    max_number_of_leads: data.data.user["max_number_of_leads"],
    days_to_run_lead_nurturing: data.data.user["days_to_run_lead_nurturing"],
    time_to_scrape_prospects_posts_local: data.data.user["time_to_scrape_prospects_posts_local"],
    trial_end_date: data.data.user["trial_end_date"],
    get_notified_when_prospects_post: data.data.user["get_notified_when_prospects_post"],
    get_notified_day: data.data.user["get_notified_day"],
    get_notified_hour_local: data.data.user["get_notified_hour_local"],
    linkedin_email: data.data.user["linkedin_email"],
    linkedin_first_name: data.data.user["linkedin_first_name"],
    linkedin_last_name: data.data.user["linkedin_last_name"],
    linkedin_access_token_expiry_date: data.data.user["linkedin_access_token_expiry_date"],
    open_ai_api_key: data.data.user["open_ai_api_key"],
  };
}

export function UpdateUserData(data, timezone) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ data: data, timezone: timezone }),
  };
  return fetch(domain() + "get-user-data", options);
}

export function GetUserData() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + "get-user-data", options);
}
