import React, { useState, useEffect, useContext } from 'react';
import styles from './Topbar.module.css';
import { useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { Context } from '../../Store/Store';
import { constants } from '../../Utils/constants';
import { TransformUserData } from '../../Services/UserDataService';
import Notification from './Notification';


const Topbar = () => {
  const [toggle, setToggle] = useState(false);
  const [state, dispatch] = useContext(Context);

  let history = useNavigate();

  useEffect(() => {
    // Check localstorage to see if user data exists
    // Use case is when a user refreshes and we lose the context store
    // Rehydrate user data from localstorage
    // Have to use payload: {data: {}} data inside is to match the shape of the original object
    // without this the conext store isn't readable

    if (state.user['data'] === undefined) {
      const t = localStorage.getItem('user');
      const u = JSON.parse(t);

      if (u) {
        dispatch({
          type: 'SET_USER',
          payload: {
            data: TransformUserData(u)
          }
        });
      }
    }
    return () => {};
  }, [dispatch, state.user]);

  const toggleDropdown = () => {
    if (toggle === true) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  const billing = () => {
    history('/Billing');
  };

  const account = () => {
    history('/Account');
  };

  const logOut = () => {
    localStorage.clear();
    // clear state
    dispatch({
      type: 'RESET_STATE'
    });
    history('/Login');
  };

  return (
    <div className={`${styles['topbar']}`}>
      <div className='row' style={{justifyContent:'flex-end', paddingLeft:'2em'}}>
      <div className='d-flex justify-content-end' style={{gap:"16px"}}>
        <Notification/>
        <div className='row cursorPointer' onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <div>
            <div className='row mr-3'>
              <div className='dropdown-position-absolute '>
                <div style={{padding:"6px"}}><img src='images/profile.svg' alt=''></img></div>
                <div className={`${!toggle ? 'dropdown-menu' : 'dropdown-position-absolute dropdown-menu d-flex no-gutters dropdown-menu-right'} `}>
                  <div id='topbar-dropdown' className='w-100' aria-labelledby='dropdownMenuButton'>
                    <Nav.Link className='dropdown-item' onClick={account}>
                      Account
                    </Nav.Link>
                    <Nav.Link className='dropdown-item' onClick={billing}>
                      Billing
                    </Nav.Link>
                    <Nav.Link className='dropdown-item' href='https://engage-ai.co/affiliate/' target='_blank' rel='noopener noreferrer'>
                      Refer and Earn
                    </Nav.Link>
                        {state.user?.data?.group === constants.User.MEMBER ? (
                          <div> 
                            <Nav.Link className='dropdown-item' href='https://tally.so/r/wgdYdN' target='_blank' rel='noopener noreferrer'>
                              Contact Us
                            </Nav.Link>
                          </div>
                        ) : (
                          <div></div>
                        )}
                    <hr></hr>
                    <Nav.Link className='dropdown-item' onClick={logOut}>
                      Logout
                    </Nav.Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
export default Topbar;
