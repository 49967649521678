import domain from "./DomainService";

/**
 * 
 * @param {string} inputEmail user's email
 * @param {string} inputPassword user's pass
 * @return {Promise}
 */
export default function RegisterNewUserService(firstName, lastName, inputEmail, password, group) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({first_name:firstName, last_name:lastName, email: inputEmail, password: password, group: group}),
  };

  return fetch(domain() + "register-external", options);
}

export function RegisterNewAWSUserService(firstName, lastName, inputEmail, password, customerID, expirationDate) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({first_name:firstName, last_name:lastName, email: inputEmail, password: password, stripe_customer_id: customerID, stripe_service_end_date: expirationDate}),
  };

  return fetch(domain() + "aws/register", options);
}