import React from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar'; 

const WelcomeEngageAI = () => {  
    return (
        <div >
        <div className={`${styles['body']} `}>
            <Topbar/>
            <div className={`${styles['top-arrow']}`} style={{background:"#3947A8", padding:"24px", textAlign:"center", color:"white"}}>
                <h4>10 free generations on us – no sign-up needed</h4>
                <p>You can sign up for free unlimited generations later – no credit card required </p>
                
                <img style={{width:"750px"}} src='/images/comment.png' alt=''></img>
                <div style={{padding:"12px", background:"white", borderRadius:"8px", width:"max-content", margin:"22px auto"}}>
                    <a href="https://www.linkedin.com" target="__blank" style={{color:"#3947A8", textDecoration:"none", fontWeight:800}}>Go to LinkedIn</a>
                </div>
                <div>2 of 2</div>
            </div>
        </div>
        </div>
    );
}
export default WelcomeEngageAI;