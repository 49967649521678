import React from 'react';
import styles from './Onboarding.module.css';
import { Link } from 'react-router-dom';

const Bottombar = () => {

  return (
    <div className={`${styles['center-align']} `} style={{background:"#3947A8", color:"white", height:"150px", padding:"15px"}}>
        <div style={{fontWeight:"700", fontSize:"24px", lineHeight:"36px", marginBottom:"10px"}}>
            Claim your LinkedIn growth toolkit!
        </div>
        <div style={{fontWeight:"400", fontSize:"14px", lineHeight:"21px", marginBottom:"10px"}}>
            Power your business advertising with all the best growth hacking tools for LinkedIn :
        </div>
        <div style={{marginBottom:"10px"}}>
            <div className={`${styles['white_button']} `}>
                <Link to='/Onboarding/CreateAccount'>Start Now for FREE</Link>
            </div>
        </div>
    </div>
  );
};
export default Bottombar;
