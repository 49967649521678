import { Avatar, Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const styles = {
  cellProps: {
    fontSize: "12px",
    fontWeight: "600",
    textTransform: "capitalize",
    color: "#1E1E1E",
  },
  headline: {
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(65, 80, 88, 1)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

function CustomisedAvatar({ tableMode, name, imageUrl = null, headline }) {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("1500"));

  return (
    <Box
      display="flex"
      justifyContent={tableMode ? "flex-start" : "flex-end"}
      alignItems={tableMode ? "center" : "flex-start"}
      gap={tableMode ? ".7rem" : "8px"}
    >
      <Avatar sx={{ height: "32px", width: "32px" }} {...(imageUrl ? { src: imageUrl } : {})} alt="user" />
      <Box display="flex" flexDirection="column" gap="4px">
        <Typography sx={headline ? {...styles.cellProps, whiteSpace: "nowrap"} : styles.cellProps}>{name}</Typography>
        <Typography sx={{ ...styles.headline, width: smallDisplay ? "100px" : "200px" }}>{headline}</Typography>
      </Box>
    </Box>
  );
}

export default CustomisedAvatar;
