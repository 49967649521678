import domain from "./DomainService";
/**
 * Returns a Promise
 *
 * @return {Promise} data contains the Stripe portal temp url
 */
export default function StripePortalSessionService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + "create-customer-portal-session", options);
}

/**
 * Redirect to Stripe checkout page
 * @returns 
 */
export function CreateCheckoutSessionService(product) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8"
    },
    body: JSON.stringify({ product:product }),
  };
  
  return fetch(domain() + "create-checkout-session", options);
}
