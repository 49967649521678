import React, { createContext, useContext, useEffect, useState } from "react";
import { GetMultiplePostsService, GetSavedListsService, GetSavedProspectsService } from "../Services/GetProspectsPosts";
import toast from "react-hot-toast";

const SOCKET_URL = "wss://takj1ff971.execute-api.ap-southeast-2.amazonaws.com/production";

const NurtureContext = createContext(null);

export const NurtureProvider = ({ children }) => {
  const [ws, setWs] = useState(null);

  // POSTS STATE
  const [orderBy, setOrderBy] = useState("DESC");
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [allPosts, setAllPosts] = useState([]);
  const [refreshPostsKey, setRefreshPostsKey] = useState(0);

  // PROSPECTS STATE
  const [prospects, setProspects] = useState([]);
  const [isLoadingProspects, setIsLoadingProspects] = useState(true);
  const [refreshProspectsKey, setRefreshProspectsKey] = useState(0);

  // LISTS STATE
  const [lists, setLists] = useState([]);
  const [isLoadingLists, setIsLoadingLists] = useState(true);
  const [refreshListsKey, setRefreshListsKey] = useState(0);

  // STATE FOR WEB SOCKET
  const [postsFound, setPostsFound] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateFinished, setUpdateFinished] = useState(false);
  const [openNoPostsFound, setOpenNoPostsFound] = useState(false);
  const [errorUpdaing, setErrorUpdating] = useState(false);
  const [showCookieErr, setShowCookieErr] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [pourcentage, setPourcentage] = useState(0);

  //   SOCKET CONNECTION
  useEffect(() => {
    const websocket = new WebSocket(SOCKET_URL);
    setWs(websocket);
    return () => {
      websocket.close();
    };
  }, []);

  function onMessage(callback) {
    ws.onmessage = function (event) {
      const data = JSON.parse(event.data);
      callback(data);
    };
  }

  const sendMessage = (message) => {
    if (ws) {
      ws.send(message);
      setMessageCount((prevCount) => prevCount + 1);
    }
  };

  //  Reset state
  const getInitialState = () => {
    setIsUpdating(false);
    setUpdateFinished(false);
    setPostsFound([]);
    setErrorUpdating(false);
    setOpenNoPostsFound(false);
    setPourcentage(0);
    setMessageCount(0);
    setShowCookieErr(false);
  };

  // Get saved lists
  useEffect(() => {
    GetSavedListsService()
      .then((res) => {
        res.json().then((json) => {
          setLists(json.data);
        });
      })
      .finally(() => {
        setIsLoadingLists(false);
      });
  }, [refreshListsKey]);

  // Get saved posts
  useEffect(() => {
    const sort = {
      field: "post date",
      order: orderBy,
    };
    GetMultiplePostsService({ sort: sort })
      .then((res) => {
        res.json().then((json) => {
          setAllPosts(json.data);
        });
      })
      .finally(() => {
        setIsLoadingPosts(false);
      });
  }, [orderBy, refreshPostsKey]);

  //   Get saved prospects
  useEffect(() => {
    setIsLoadingProspects(true);
    GetSavedProspectsService()
      .then((res) => {
        res.json().then((json) => {
          setProspects(json.data);
        });
      })
      .finally(() => {
        setIsLoadingProspects(false);
      });
  }, [refreshProspectsKey]);

  //   Calculate pourcentage
  useEffect(() => {
    if (!prospects || prospects.length === 0) return;
    const elementPourcentage = 100 / prospects.length;
    const totalPourcentage = Math.round(elementPourcentage * messageCount);

    setPourcentage(totalPourcentage);
  }, [prospects, messageCount]);

  // send toast notification when scraping is finished and user changes page
  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname !== "/NurtureProspects/posts" && updateFinished) {
      toast.success(
        <span>
          Post updates completed. &nbsp;
          <a style={{ fontWeight: "700", textDecoration: "underline" }} href="/NurtureProspects/posts">
            Show
          </a>
        </span>,
        {
          duration: 10000,
        }
      );
    }
  }, [updateFinished]);

  return (
    <NurtureContext.Provider
      value={{
        sendMessage,
        onMessage,
        isUpdating,
        setIsUpdating,
        updateFinished,
        setUpdateFinished,
        postsFound,
        setPostsFound,
        errorUpdaing,
        setErrorUpdating,
        openNoPostsFound,
        setOpenNoPostsFound,
        pourcentage,
        getInitialState,
        orderBy,
        setOrderBy,
        allPosts,
        setRefreshPostsKey,
        isLoadingPosts,
        showCookieErr,
        setShowCookieErr,
        // PROSPECTS
        prospects,
        isLoadingProspects,
        setIsLoadingProspects,
        setRefreshProspectsKey,

        // LISTS
        lists,
        isLoadingLists,
        setIsLoadingLists,
        setRefreshListsKey,
      }}
    >
      {children}
    </NurtureContext.Provider>
  );
};

export const useNurtureContext = () => useContext(NurtureContext);
