import React from "react";
import styles from "./EngagementTable.module.css";

const checkIcon = (
  <svg width="15" height="18" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.56569 4.79434L2.71426 2.97863L0.714264 5.02148L4.57712 8.80291L11.29 2.15863L9.28141 0.127197L4.56569 4.79434Z"
      fill="#2E59DD"
    />
  </svg>
);

const LinkButton = (props) => {
  let { item, handleOpen } = props;

  return (
    <>
      <div
        className={
          item.is_complete === true
            ? `${styles["checkbox-style"]}  ${styles["checkbox-incomplete"]} d-flex align-content-center flex-wrap`
            : item.is_complete === false ? `${styles["checkbox-style"]}  ${styles["checkbox-complete"]} d-flex align-content-center flex-wrap`
            : ''
        }
        onClick={() => handleOpen(item)}
      >
        {item.is_complete === true ? <span>{checkIcon}</span> : <span>&nbsp;</span>}
      </div>
    </>
  );
};

export default LinkButton;
/**
 *     <button
      className={
        item.is_complete === false
          ? "btn-sm btn button-wide btn-primary"
          : "btn-sm btn button-wide btn-secondary"
      }
      onClick={() => handleOpen(item)}
    >
      {item.is_complete === false ? "Needs A Comment" : "Opened"}
    </button>
 */
