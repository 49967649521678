import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCDuY5Qe3lUD3pI84QEyuMFMvfpyZmdf7w",
    authDomain: "filtpod-frontend.firebaseapp.com",
    databaseURL: "https://filtpod-frontend.firebaseio.com",
    projectId: "filtpod-frontend",
    storageBucket: "filtpod-frontend.appspot.com",
    messagingSenderId: "973143886384",
    appId: "1:973143886384:web:0a1b64fe284d8b31f77bf5"
  };

  const app = initializeApp(firebaseConfig);

  export default app;