import { setUserDataLocalStorage } from "../../../Scripts/getSetLocalStorage";
import { TransformAPIDataToUserData } from "../../../Services/UserDataService";

export const handleSuccessLogin = (json, context) => {
  const d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();

  document.cookie = "fp_email=" + json.data.user.email + ";" + expires + "; path=/";
  document.cookie = "fp_user_type=" + json.data.user.group + ";" + expires + "; path=/";
  document.cookie = "fp_user_trial_end_date=" + json.data.user.trial_end_date + ";" + expires + "; path=/";
  document.cookie = "eai_token=" + json.data.token + ";" + expires + "; path=/";

  const data = TransformAPIDataToUserData(json);
  setUserDataLocalStorage(data, json.data.token);

  context[1]({
    type: "SET_USER",
    payload: {
      data,
    },
  });
};
