import React, { useState} from 'react';
import Spinner from "../Spinner/Spinner";
import {RegisterNewAWSUserService} from '../../Services/RegisterNewUserService';
import styles from './Onboarding.module.css';
import loginStyles from '../Login/LoginPage.module.css';
import formStyles from '../Login/FormInput.module.css';
import PasswordStrengthBar from 'react-password-strength-bar';

const CreateAccountAWS = ()=> {
    const [formValues, setFormValues] = useState({
        first_name:'',
        last_name:'',
        email: '',
        password1: '',
        password2:''
        });
    const [success, setSuccess] = useState(false);
    const [accountExists, setAccountExists] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setErrorMessage();
        event.persist();
        var filteredStr = filterMetacharacters(event.target.value);
        if (event.target.name === "email") {
          filteredStr = filterEmailAlias(filteredStr);
        }
        setFormValues((values) => ({
            ...values,
            [event.target.name]: filteredStr
        }));
        if (event.target.id === 'password1') {
          let pw1 = document.getElementById("password1").value;
          if (pw1.length < 8) {
            setErrorMessage("Password is too short");
            return
          }
        }
        if (event.target.id === 'password1' || event.target.id === 'password2') {
            let pw1 = document.getElementById("password1").value;
            let pw2 = document.getElementById("password2").value;
            if (pw1!==pw2){
                setErrorMessage("Passwords do not match");
                return
            }
        }
    };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const awsCustomerId = queryParams.get('awsCustomerId');
    const expirationDate = queryParams.get('expirationDate');

    RegisterNewAWSUserService(formValues.first_name, formValues.last_name, formValues.email, formValues.password1, awsCustomerId, expirationDate).then((res)=> {
        if (res.status === 200) {
            res.json().then((json) => {
                console.log(json)
                if (json.message === "Account already exists"){
                    setAccountExists(true);
                }
            })
            setSuccess(true);
            const d = new Date();
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            const expires = "expires="+d.toUTCString();
            document.cookie = "fp_email=" + formValues.email + ";" + expires + "; path=/";
            var email=formValues.email;
            window.fpr("referral",{email: email})
        }
        else {
            setErrorMessage('An error occured. Please contact hello@engage-ai.co.');
        }
        setIsLoading(false);

    }).catch(() => {
        setErrorMessage('An error occured. If this persists, please contact hello@engage-ai.co and our team will assist you.');
        setIsLoading(false);
    })
    
};

const openLinkedinAuth = () => {
  const state = formValues.email;
  localStorage.setItem("linkedin_state", state);
  var redirect_uri = "http://localhost:3000/"
  if (window.location.hostname.includes("filtpod-frontend-test")) {
    redirect_uri = "https://filtpod-frontend-test.web.app/LinkedinAuthRedirect";
  }
  else if (window.location.hostname.includes("apps.engage-ai")){
    redirect_uri = "https://apps.engage-ai.co/LinkedinAuthRedirect";
  }
  window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86vxx2jtebvxju&redirect_uri=${redirect_uri}&state=${state}&scope=r_liteprofile%20r_emailaddress`)
};

function filterMetacharacters(inputString) {
  inputString = inputString.replace(/<\/?script[^>]*>/gi, '').replace(/<\/?html[^>]*>/gi, '');
  return inputString;
}

function filterEmailAlias(inputString) {
  inputString = inputString.replace(/\+[^@]+@/, '@');
  return inputString;
}
 
  return (
    <div className={`${loginStyles.main} flex-nowrap `}>
      
        <div className={`${loginStyles.rightbackground} `}>
          {loading ? <Spinner className="spinner-container" /> : ""}
          {success && accountExists ? (
            <div className='form-row d-flex justify-content-center'>
              <div className='col-md-8 mt-3' style={{textAlign:"center"}}>
                <div style={{fontSize:"24px", fontWeight:"600", lineHeight:"36px"}}>You already have an account.</div>
                <div className='mt-3'>We have upgraded your account to Engage AI Pro. Please re-login to see the updated plan.</div>
                <div className='d-flex justify-content-center' style={{marginTop:"200px"}}>
                    <div><span><img src='/images/back-arrow.svg' alt='back to login'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
                </div>
              </div>
            </div>
          ) : success && !accountExists ? <div className='form-row d-flex justify-content-center'>
          <div className='col-md-8 mt-3' >
            <div style={{fontSize:"24px", fontWeight:"600", lineHeight:"36px"}}>Step 1 : Check your email <img src='/images/email.svg' alt='email.svg' style={{marginBottom:"10px"}}></img></div>
            <div className='mt-3'>We sent a verification link to {formValues.email}. Please be sure to check your spam folder too.</div>
            <div className={`${formStyles['rect-button']}`} style={{display:"flex", alignItems:"center", marginTop:"23px", cursor:"pointer"}} onClick={()=> {window.open("https://mail.google.com/mail/u/0/#search/from%3A(%40engage-ai.co)+in%3Aanywhere");}}>
              <div style={{margin:"0 5px"}}><img src='/images/gmail.jpg' alt='gmail' style={{width:"20px"}}></img></div>
              <div style={{fontWeight:"bold"}}>Open in Gmail</div>
              <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
            </div>
            <div style={{marginTop:"40px", fontSize:"24px", fontWeight:"600", lineHeight:"36px"}}>Step 2 : Connect your LinkedIn account</div>
            <div className='mt-3'></div>
            <div className={`${formStyles['rect-button']}`} style={{display:"flex", alignItems:"center", marginTop:"23px", cursor:"pointer"}} onClick={openLinkedinAuth}>
              <div style={{margin:"0 5px"}}><img src='/images/linkedin_logo.svg' alt='gmail' style={{width:"20px"}}></img></div>
              <div style={{fontWeight:"bold"}}>Login to LinkedIn</div>
              <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
            </div>
            <div style={{marginTop:"23px", fontSize:"12px"}}>*We do not post anything on LinkedIn without your explicit consent.</div>
            <div className='d-flex justify-content-center' style={{marginTop:"200px"}}>
              <div><span><img src='/images/back-arrow.svg' alt='back to login'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
            </div>
          </div>
        </div> : (
            <>
              <form onSubmit={handleSubmit}>
                <div>
                  <div className='form-row d-flex justify-content-center'>
                    
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['frow']}`}>
                            <div className={`${styles['fcolumn']}`} style={{width:"50%"}}>
                                <label htmlFor='first_name'>First Name</label>
                                <input
                                    className={`${formStyles['form-input3']} mt-2`}
                                    type='text'
                                    id='first_name'
                                    name='first_name'
                                    onChange={handleChange}
                                    value={formValues.first_name}
                                    required
                                />
                            </div>
                            <div className={`${styles['fcolumn']}`} style={{width:"50%"}}>
                            <label htmlFor='last_name'>Last Name</label>
                                <input
                                    className={`${formStyles['form-input3']} mt-2`}
                                    type='text'
                                    id='last_name'
                                    name='last_name'
                                    onChange={handleChange}
                                    value={formValues.last_name}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className=' col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='email'>Email</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='user@gmail.com'
                                type='email'
                                id='email'
                                name='email'
                                onChange={handleChange}
                                value={formValues.email}
                                required
                            />
                        </div>
                    </div>
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='password1'>Password</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='Password (at least 8 characters)'
                                type='password'
                                id='password1'
                                name='password1'
                                onChange={handleChange}
                                autoComplete='new-password'
                                value={formValues.password1}
                                required
                            />
                        </div>
                        <PasswordStrengthBar password={formValues.password1} minLength={8}/>
                    </div>
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='password2'>Confirm Password</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='Re-Enter Password'
                                type='password'
                                id='password2'
                                name='password2'
                                onChange={handleChange}
                                autoComplete='new-password'
                                value={formValues.password2}
                                required
                            />
                        </div>
                    </div>
                    <div className='d-flex col-md-12 justify-content-center' style={{color:"#A91212"}}>
                      {errorMessage}
                    </div>
                    <div className='form-group col-md-8 mt-4'>
                      <div style={{fontWeight:"600", fontSize:"12px", marginBottom:"10px", textAlign:"center"}}>I’ve reviewed and agree to the <a href='https://engage-ai.co/terms-and-conditions/' target='_blank' rel="noopener noreferrer">Terms and Conditions</a>.</div>
                      <button className={`${formStyles['submit-button']}`} data-testid='newpassword-submit' disabled={errorMessage ? true : false}>
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className='d-flex justify-content-center'>
                <div>Already have an account? <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
              </div>
            </>
          )}
        </div>
      
    </div>
  );
};
export default CreateAccountAWS;