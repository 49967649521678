export const constants = {
  User: {
    MEMBER: 'Member',
    FILTPOD: 'FILT Pod',
    HOOTSUITE: 'Hootsuite',
    CHROMEEXTENSION: 'Chrome Extension',
    PROMINENCEGLOBAL: 'Prominence Global',
    AFFILIATEPARTNER: 'Affiliate Partner',
    GROWTHPLAN : 'Growth Plan',
    ENGAGEAI: 'Engage AI'
  }
};
