import { styled, Switch } from "@mui/material";

export const StyledSwitch = styled(Switch)(() => ({
  "&": {
    padding: 0,
    height: "24px",
    width: "39px",
  },
  "& .MuiSwitch-track": {
    height: "24px",
    width: "39px",
    borderRadius: "40px",
    border: "1px solid var(--color-brand)",
    background: "white",
    padding: 0,
    transition: "none",
  },
  "& .MuiSwitch-switchBase": {
    height: "16px",
    width: "16px",
    backgroundColor: "var(--color-brand)",
    opacity: 1,
    top: "3px",
    left: "4px",
    transition: "none",
  },
  "& .MuiSwitch-switchBase:hover, & .MuiSwitch-switchBase:active ": {
    backgroundColor: "var(--color-brand)",
    opacity: 1,
  },
  "& .MuiSwitch-switchBase.Mui-disabled ": {
    backgroundColor: "#C8CDD0",
    opacity: 1,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "var(--color-brand)",
    border: "1px solid var(--color-brand)",
    opacity: 1,
    transition: "none",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    backgroundColor: "white",
    opacity: 1,
    top: "3px",
    left: "-2px",
    transition: "none",
  },
  "& .MuiSwitch-switchBase.Mui-checked:hover, & .MuiSwitch-switchBase.Mui-checked:active ": {
    backgroundColor: "white",
    opacity: 1,
  },
}));
