import { FormLabel, TextField, styled, useMediaQuery, useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import StyledButton from "../../NurtureProspects/ReusableComponents/Button";
import { useMemo, useState } from "react";
import { AddSettingsService, UpdateSettingsService } from "../../../Services/SettingsService";
import { Controller, Form, useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";

const ADD_TITLE = "Add a tone";
const UPDATE_TITLE = "Edit a tone";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },

  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  formLabel: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    color: "var(--color-grey-400)",
  },
  chars: {
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "16px",
    marginLeft: "1px",
    color: "#A0A4A6",
    flex: "1",
  },
  errMsg: {
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "16px",
    color: "var(--color-red)",
    textAlign: "right",
    margin: "0",
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  upgradeMsg: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    color: "var(--color-warning)",
    textAlign: "right",
    textDecoration: "underline",
  },
  formErr: {
    fontSize: "14px",
    fontWeight: "500",
    padding: "8px 16px",
    color: "var(--color-red)",
    background: "var(--color-red-100)",
    borderRadius: "8px",
  },
};

const StyledTextField = styled(TextField)`
  padding: 16px;
  border: 1px solid #e0e2e4;
  border-radius: 8px;
  & input {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #1f292e;
    padding: 0;

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: #a0a7ab;
      font-style: normal;
    }
  }
  & textarea {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #1f292e;
    padding: 0;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      cursor: default;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-grey-200);
      cursor: default;
    }

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: var(--color-grey-400);
      font-style: normal;
    }
  }
`;

function AddUpdateTone({ mode, onCloseModal, tone, premiumUser, onAddUpdate, showSucessModal, setShowSucessModal }) {
  const [showErrForm, setShowErrForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const dontShowModal = localStorage.getItem("dontShowSucessModal");

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control: toneController,
    watch,
  } = useForm({
    defaultValues: {
      label: tone?.label || "",
      prompt: tone?.prompt || "",
    },
  });

  const fields = watch();

  const onSucess = () => {
    if (!showSucessModal) {
      if (dontShowModal) {
        toast.success("Tone updated successfully");
      } else {
        setShowSucessModal(true);
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSaving(true);
      if (mode === "add") {
        const res = await AddSettingsService([
          { label: data.label, prompt: data.prompt, character_count: data.prompt.length },
        ]);
        if (res.status === 200) onSucess();
      } else {
        const res = await UpdateSettingsService([
          { id: tone.id, label: data.label, prompt: data.prompt, character_count: data.prompt.length },
        ]);
        if (res.status === 200) onSucess();
      }
      onAddUpdate();
      onCloseModal();
    } catch (err) {
      setShowErrForm(true);
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = useMemo(() => {
    if (fields.label.length > 30) return false;
    if (!premiumUser && fields.prompt.length > 350) return false;
    return true;
  }, [fields.label, fields.prompt, premiumUser]);

  return (
    <Form control={toneController} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={styles.Modal}>
        <Box display="flex" alignItems="center" gap="10px" mb={2}>
          <img src="/images/pageInfo.svg" alt="Settings icon" style={{ height: "24px", width: "24px" }} />
          <h2>{mode === "add" ? ADD_TITLE : UPDATE_TITLE}</h2>
        </Box>
        {showErrForm && <Typography sx={styles.formErr}>An unknown error occurred. Please try again later.</Typography>}{" "}
        <Box display="flex" flexDirection="column" gap="1.4rem">
          <Box sx={styles.formGroup}>
            <FormLabel sx={styles.formLabel}>Label</FormLabel>
            <Controller
              name="label"
              control={toneController}
              rules={{ required: "Label is required." }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  variant="standard"
                  placeholder="Example : Professional"
                  sx={{
                    border:
                      !!errors?.label || fields.label.length > 30 ? "1px solid var(--color-red)" : "1px solid #e0e2e4",
                  }}
                  error={!!errors?.label}
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  Analytical
                </StyledTextField>
              )}
            />

            <Box display="flex" alignItems="center">
              <span
                style={{
                  ...styles.chars,
                  color: fields.label.length > 30 ? "var(--color-red)" : "var(--color-grey-200)",
                }}
              >
                {`${fields.label.length}/30`}
              </span>
              <Typography sx={styles.errMsg}>
                {fields.label.length > 30
                  ? "Label is too long (max 30 characters)"
                  : !!errors?.label
                  ? errors?.label?.message
                  : ""}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.formGroup}>
            <FormLabel sx={styles.formLabel}>Tone</FormLabel>
            <Controller
              name="prompt"
              control={toneController}
              rules={{ required: "Prompt is required." }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  variant="standard"
                  placeholder="Example : Respond professionally with a focus on business benefits and strategic value."
                  multiline
                  rows={bigScreen ? 8 : 6}
                  sx={{
                    border:
                      !!errors?.prompt || (!premiumUser && fields.prompt.length > 350)
                        ? "1px solid var(--color-red)"
                        : "1px solid #e0e2e4",
                    paddingRight: "5px",
                    "& textarea": {
                      paddingRight: "5px",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  Analytical
                </StyledTextField>
              )}
            />

            <Box display="flex" alignItems="flex-start">
              <span
                style={{
                  ...styles.chars,
                  color: !premiumUser && fields.prompt.length > 350 ? "var(--color-red)" : "var(--color-grey-200)",
                }}
              >{`${fields.prompt.length}/${premiumUser ? "Unlimited" : "350"}`}</span>
              {!!errors?.prompt && <Typography sx={styles.errMsg}>{errors?.prompt?.message}</Typography>}
              {!premiumUser && fields.prompt.length > 350 && (
                <Box display="flex" flexDirection="column" alignSelf="end" gap="5px">
                  <Typography sx={{ ...styles.errMsg }}>Reached character limit.</Typography>
                  <Link style={styles.link} to="https://bit.ly/3x1DtMM" target="_blank" rel="noopener noreferrer">
                    <img style={{ height: "14px", width: "14px" }} src="/images/warning.svg" alt="warning icon" />
                    <span style={styles.upgradeMsg}>Upgrade for unlimited characters</span>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <StyledButton onClick={onCloseModal} variation="noBackgroundPrimary">
            Cancel
          </StyledButton>
          <StyledButton
            sx={{ "& span": { color: "white", width: "24px !important", height: "24px !important" } }}
            disabled={!isDirty || !canSave || errors?.label || errors?.prompt || isSaving}
            type="submit"
          >
            {isSaving ? <CircularProgress /> : "Save"}
          </StyledButton>
        </Box>
      </Box>
    </Form>
  );
}

export default AddUpdateTone;
