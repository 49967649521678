// ----------------------------------------------------------------------------
// IMPORTS
// ----------------------------------------------------------------------------
import { Box, Button } from "@mui/material";
import StyledButton from "../../NurtureProspects/ReusableComponents/Button";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";

// ----------------------------------------------------------------------------

const styles = {
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    fontFamily: "Inter",
    textAlign: "center",

    "& svg": {
      fontSize: "80px",
      color: "#64be37",
    },

    "& h3": {
      fontSize: "24px",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.7)",
      margin: "0",
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.7)",
      lineHeight: "24px",
      margin: "0",
    },
    "& button:last-child": {
      display: "flex",
      alignItems: "center",
      alignSelf: "stretch",
      gap: "8px",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "600",
      borderRadius: "8px",
      border: "2px solid rgba(10, 114, 195, 1)",
      color: "rgba(10, 114, 195, 1)",
      background: "#fff",
      padding: "16px",
      textTransform: "none",
      height: "48px",
      transition: "all .3s",

      "&:hover": {
        background: "#f3f8fc",
      },

      "& svg": {
        height: "24px",
        width: "24px",
        color: "rgba(10, 114, 195, 1)",
      },
    },
  },
  mainBtn: {
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "8px",
    fontFamily: "Inter",
    border: "2px solid rgba(10, 114, 195, 1)",
    background: "rgba(10, 114, 195, 1)",
    textTransform: "none",
    lineHeight: "100%",
    transition: "all .3s",
    cursor: "pointer",
    padding: "16px",
    height: "48px",
    "&:hover": { background: "#085b9c", borderColor: "#085b9c" },
  },
};

function FormSubmitted({ onContinue }) {
  return (
    <Box sx={styles.box}>
      <AssignmentTurnedInIcon />
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <h3>Thank you for your feedback!</h3>
        <p>
          We appreciate you taking the time to help us improve. If you have any additional comments or need further
          assitance, feel free to reach out.
        </p>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="16px">
        <StyledButton
          sx={styles.mainBtn}
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            window.open("https://tally.so/r/mDkRyN", "_blank");
          }}
        >
          Contact us
        </StyledButton>
        <Button onClick={onContinue}>
          <BrowserUpdatedIcon />
          <span>Continue using Engage AI</span>
        </Button>
      </Box>
    </Box>
  );
}

export default FormSubmitted;
