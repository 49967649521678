import React from "react";
//this is for newer version but options don't work  
//import { Chart, registerables} from 'chart.js';
//Chart.register(...registerables);
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({xValues, yValues, keyword}) => {
  const lineChartData = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
        label: "#" + keyword,
        borderColor: "#2F3EAC",
        backgroundColor: "white",
        fill: {
          target: 'origin',
          above: 'rgb(56, 90, 159, 0.3)'
        },
        lineTension: 0.5
      }
    ]
  };
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Hashtag Follower Trend',
      },
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        ticks: {
           autoSkip:true
         },
         title: {
           display:true,
           text: 'No. of followers'
         }
     },
     x: {
      grid: {
        display: false
      },
       ticks: {
        /** 
        callback: function(val, index, values) {
          
          const date = new Date(this.getLabelForValue(val));
          //console.log(values);
          const format = { year: 'numeric', month: 'long'};
          if (index > 0 && values[index]["label"] !== values[index-1]["label"]) {
            return date.toLocaleDateString('en-US',format)
          }
          else {
            return ''
          }
        },*/
        autoSkip :true
       }
     }
   }
  };

  return (
    <Line
    type="line"
      width={200}
      height={60}
      options={options}
    data={lineChartData}
    />
  );
};
export default LineChart;