import React from 'react';
import styles from './Onboarding.module.css';
import Topbar from '../EngageAI/Topbar'

const PipedriveRedirect = () => {
    return (
        <div>
            <div>
                <Topbar/>
                <div className={`${styles['f-20']} ${styles['center-align']} mb-3`}>
                <iframe width="700" height="400" src="https://www.youtube.com/embed/RdEWtzXYguk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className={``}  >
                    <div className={`${styles['f-30']} ${styles['center-align']} ${styles['fw-b']} mb-3 `}>
                        You are almost done!
                    </div>
                    <div className={`${styles['f-20']} ${styles['center-align']} mb-3`}>
                        Just a few more steps and you can start moving prospects stuck in your pipeline.
                    </div>
                    <div className={`${styles['w-680']} ${styles['f-16']}`} style={{margin:"auto"}}>
                        <ol>
                            <li>Please check your inbox for a password reset email from us. This email and password will be your Engage AI login credentials.</li>
                            <li>Download the <a href='https://chrome.google.com/webstore/detail/nelhhkchoapcbpcgpmmiahfkcdhgecaf?authuser=0&hl=en-GB' target='__blank' style={{color:"#3947A8"}}>Engage AI Chrome Extension</a> to activate LinkedIn post-tracking.</li>
                            <li>Start nurturing prospects by adding their LinkedIn profiles to the <a href='https://apps.filtpod.com/Login' target='__blank' style={{color:"#3947A8"}}  >Engage AI app</a>.</li>
                        </ol>
                    </div>
                    
                </div>
            </div>
        </div>
    ); 
}
export default PipedriveRedirect;
