import React, { useState, useEffect } from 'react';
import styles from './Accountability.module.css';
import ProfileBox from './AccountabilityProfileBox';
//import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const exclamation = (
  <svg width='42' height='40' viewBox='0 0 42 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.50009 3.00008C3.36748 3.00008 3.2403 3.05276 3.14653 3.14653C3.05276 3.2403 3.00008 3.36748 3.00008 3.50009V28.5007C3.00008 28.7767 3.22408 29.0007 3.50009 29.0007H8.50021C8.89805 29.0007 9.27959 29.1588 9.5609 29.4401C9.84221 29.7214 10.0003 30.1029 10.0003 30.5008V36.8809L16.8544 30.0268C17.5105 29.3703 18.4004 29.0012 19.3285 29.0007H38.501C38.6336 29.0007 38.7608 28.9481 38.8545 28.8543C38.9483 28.7605 39.001 28.6333 39.001 28.5007V3.50009C39.001 3.36748 38.9483 3.2403 38.8545 3.14653C38.7608 3.05276 38.6336 3.00008 38.501 3.00008H3.50009ZM0 3.50009C0 1.56804 1.56804 0 3.50009 0H38.501C40.433 0 42.0011 1.56804 42.0011 3.50009V28.5007C42.0011 29.429 41.6323 30.3193 40.9759 30.9757C40.3195 31.6321 39.4293 32.0008 38.501 32.0008H19.3285C19.2628 32.0007 19.1977 32.0135 19.137 32.0386C19.0762 32.0636 19.021 32.1004 18.9745 32.1468L11.9743 39.147C11.5668 39.5543 11.0476 39.8317 10.4825 39.9441C9.91734 40.0564 9.33157 39.9987 8.79922 39.7782C8.26687 39.5578 7.81183 39.1844 7.49161 38.7054C7.17139 38.2264 7.00038 37.6631 7.00018 37.0869V32.0008H3.50009C2.57181 32.0008 1.68155 31.6321 1.02515 30.9757C0.368758 30.3193 0 29.429 0 28.5007V3.50009ZM21.0005 7.00018C21.3984 7.00018 21.7799 7.15822 22.0612 7.43953C22.3425 7.72084 22.5006 8.10238 22.5006 8.50022V16.5004C22.5006 16.8983 22.3425 17.2798 22.0612 17.5611C21.7799 17.8424 21.3984 18.0005 21.0005 18.0005C20.6027 18.0005 20.2212 17.8424 19.9398 17.5611C19.6585 17.2798 19.5005 16.8983 19.5005 16.5004V8.50022C19.5005 8.10238 19.6585 7.72084 19.9398 7.43953C20.2212 7.15822 20.6027 7.00018 21.0005 7.00018ZM21.0005 25.0006C21.531 25.0006 22.0397 24.7899 22.4148 24.4148C22.7899 24.0398 23.0006 23.531 23.0006 23.0006C23.0006 22.4701 22.7899 21.9614 22.4148 21.5863C22.0397 21.2113 21.531 21.0005 21.0005 21.0005C20.4701 21.0005 19.9614 21.2113 19.5863 21.5863C19.2112 21.9614 19.0005 22.4701 19.0005 23.0006C19.0005 23.531 19.2112 24.0398 19.5863 24.4148C19.9614 24.7899 20.4701 25.0006 21.0005 25.0006Z'
      fill='#5667A5'
    />
  </svg>
);

const AccountabilityTable = ({ accValues, loading, display }) => {
  const [loadingMessage, setLoadingMessage] = useState('Loading Table...');
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    console.log(loading);
    if (!loading && !initialLoad && display) {
      setInitialLoad(true);
    } else if (!loading && !display) {
      setLoadingMessage('');
    }
    else if (!loading && initialLoad && accValues.length === 0 && display) {
      setInitialLoad(true);
      setLoadingMessage('The Pod Has Yet To Run');
    }
  }, [loading, initialLoad, accValues, display]);

  return (
    <div className={`${styles['table-background']}`}>
      
        <div>
          {Object.keys(accValues).length >0 ? (
            
            <table className={`${styles['table-background']} table ml-3 `}>
              <thead className=''>
                <tr className={`${styles['table-header']} `}>
                  <th scope='col' className={'w-25'}>                 
                    Post Submission Date
                  </th>
                  <th scope='col'>
                      
                  </th>
                </tr>
              </thead>
              <tbody>
               
                {Object.keys(accValues).map((key, idx) => {

                    var row = []; //to return outside of curly brackets
                    let AssignedDate = (<td className='w-10'>{key}</td >);  
                    row.push(AssignedDate)

                    if (!accValues[key].length) {
                      let emptyCol = (<td>You did not post a link on this date.</td>)
                      row.push(emptyCol);
                    }
                    else{
                      let assignees = [];
                      for (let i = 0; i < accValues[key].length; i++){  
                        let assigneeProfile = (<ProfileBox key={i} item={accValues[key][i]} idx={i} />)
                        assignees.push(assigneeProfile);            
                    }
                      let assigneeCell = (<td>{assignees}</td>)
                      row.push(assigneeCell);
                  
                  }
                  return(<tr>{row}</tr>)
                })}
               

              </tbody>
            </table>
          ) : (
            <>
              <div className=''>{loadingMessage}</div>
            </>
          )}
        </div>
        <div>
          {!display ? (
            <div className={`${styles['message-box']}`}>
            <div className='mb-4'>{exclamation}</div>
            <div>
              <p>Oops! It seems like you still have posts to comment on in the Engagement tab.</p>
              <div>
                <div>
                  <p>
                    Check back on this page later!
                  </p>
                </div>
                <div>
                  <b>Workflow tip</b> &#128161; <a id='workflow-link' href='https://helpcenter.thecloudtutorialusers.com/main/articles/1622087853850' target='_blank' rel='noopener noreferrer'>
                  Click here 
                  </a> to learn how to adopt a more productive workflow for engagement sessions to keep your Reputation Score high.
                </div>
              </div>
            </div>
          </div>) : (
      
     
      <div className={`${styles['message-box']} row d-inline-flex flex-wrap h-25 ml-4`}>
        <div className='mb-4'>{exclamation}</div>
        <div>
          <p className={`${styles['message-text']}`}>A fair and better way to vet members.</p>
          <div>
            <div>
              <p>
                If you notice there are members that NEVER play by the rules, please let the admin team know by using the link. Any member that has their score fall below the acceptable score will
                have their membership cancelled and will be asked to leave.
              </p>
            </div>
            <div>
              <Link to='/ReportMember'>
                Report a User
              </Link>
            </div>
          </div>
        </div>
      </div>)}
      </div>
    </div>
  );
};

export default AccountabilityTable;
