import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Box } from "@mui/material";

import { RiErrorWarningLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import ReusableTable from "./Table";

const styles = {
  closeBtn: {
    background: "#ecedee",
    border: "none",
    padding: "8px",
    borderRadius: "3px",
    transition: "all 0.2s",
    height: "40px",
    width: "40px",
    minWidth: "40px",

    "&:hover": {
      background: " #ecedeecc",
    },

    "& svg ": {
      width: "24px",
      height: "24px",
      color: "#1f292e",
    },
  },
  title: { fontSize: "24px", fontWeight: "700", color: "#1F292E", margin: "0" },
  text: { fontSize: "16px", fontWeight: "500", lineHeight: "20px", color: "rgba(65, 80, 88, 1)" },
};

function ImportErrors({ open, onClose, errors = [] }) {
  const tableOptions = {
    headers: ["Line N°", "Linkedin URL", "First name", "Last name", "List", "Healine"],
    data: errors,
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" mb="8px">
          <Button sx={styles.closeBtn} onClick={onClose}>
            <IoClose />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="flex" alignItems="center" gap="8px">
            <RiErrorWarningLine style={{ height: "24px", width: "24px", color: "rgba(214, 31, 31, 1)" }} />
            <h2 style={styles.title}>Import Errors ({errors.length})</h2>
          </Box>
          <Typography sx={styles.text}>
            The lines below from your CSV failed to import. Hover over the red cells to see error details.
          </Typography>
          <ReusableTable tableIdx={1} options={tableOptions} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ImportErrors;
