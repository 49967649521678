import { Box, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNurtureContext } from "../../../Context/NurtureContext";

const styles = {
  Paper: {
    height: "100%",
    width: "100%",
    border: "1px solid #D1D9E2",
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: "#F9FBFD",
  },
  TextBox: {
    position: "relative",
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    borderBottom: "2px solid #edeff2",
    paddingBlock: "2rem",
  },
  Btn: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "16px",
    color: "#2F3EAC",
    fontWeight: "600",
    background: "none",
    border: "none",
    margin: "0",
    position: "absolute",
    left: "20px",
  },
  Title: {
    fontSize: "16px",
    color: "#2F3EAC",
    fontWeight: "700",
    marginInline: "auto",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  FormBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    position: "relative",
    paddingBlock: "4rem"
  },
};

function AddUpdate({ ressource, title, children }) {
  const navigate = useNavigate();
  const { setRefreshListsKey, setRefreshProspectsKey } = useNurtureContext();

  const handleClick = () => {
    if (ressource === "lists") {
      setRefreshListsKey((oldKey) => oldKey + 1);
    } else if (ressource === "prospects") {
      setRefreshProspectsKey((oldKey) => oldKey + 1);
    }
    navigate(-1);
  };

  return (
    <Paper sx={styles.Paper}>
      <Box sx={styles.TextBox}>
        <button style={styles.Btn} onClick={handleClick}>
          <img style={{ width: "12px", height: "12px" }} src="/images/leftArrowIcon.svg" alt="back icon" />
          <span>Back</span>
        </button>
        <Typography sx={styles.Title}>{title}</Typography>
      </Box>
      <Box sx={styles.FormBox}>{children}</Box>
    </Paper>
  );
}

export default AddUpdate;
