import { Outlet } from "react-router-dom";
import Sidebar from "./NurtureProspects/Sidebar/Sidebar";
import Header from "./NurtureProspects/Header";
import { SkeletonTheme } from "react-loading-skeleton";
import { NurtureProvider } from "../Context/NurtureContext";

const styles = {
  Layout: {
    display: "grid",
    gridTemplateColumns: "18.75rem 1fr",
    gridTemplateRows: "auto 1fr",
    height: "100vh",
    overflow: "hidden",
  },
  Main: {
    overflow: "auto",
    background: "var(--color-grey-50)",
  },
};

function AppLayout() {
  return (
    <SkeletonTheme baseColor="#E8EBF1" highlightColor="#edeff4">
      <NurtureProvider>
        <div style={styles.Layout}>
          <Header />
          <Sidebar />
          <main style={styles.Main}>
            <div>
              <Outlet />
            </div>
          </main>
        </div>
      </NurtureProvider>
    </SkeletonTheme>
  );
}

export default AppLayout;
