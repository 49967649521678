import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Spinner from '../Spinner/Spinner';
import Topbar from '../Topbar/Topbar';
import { GetMembersReportService } from '../../Services/ReportMemberService';
import UserScoreService from '../../Services/UserScoreService';
import styles from './MembersReportForm.module.css';

const ReportForm = () => {
    const [loading, setIsLoading] = useState(false);
    const [complaints, setComplaints] = useState();
    const [checkedState, setCheckedState] = useState();
    const [admin, isAdmin] =useState(false);

    const handleChange = (event) => {
        const index = parseInt(event.target.value);
        const item = complaints[index];
        const updatedCheckedState = checkedState.map((item, idx) => 
          idx === index ? !item : item
        );
        setCheckedState(updatedCheckedState);
        if (updatedCheckedState[index] === true) {
            UserScoreService(item.about_member, item.form_id).then((res) => {
                if (res.status === 200) {
                    console.log("Reputation score deducted!");
                    setComplaints(complaints.splice(index, 1))
                }
                else {
                    console.log("Cannot update reputation score.")
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    };

    useEffect(() => {
        GetMembersReportService().then((res) => {
            if (res.status === 200) {
                res.json().then((data) =>{
                    setCheckedState(new Array(data.length).fill(false));
                    setComplaints(data);
                    isAdmin(true);
                });
            }
            setIsLoading(false);
        }).catch((e) => {
            console.log(e)
        })
    }, [])
    return (
        <div className='container '>
            <div className='row flex-nowrap no-gutters'>
                <Sidebar />
                <div className='no-gutters col-105 mt-2'>
                    {loading ? <Spinner /> : ''}
                    <div className={`${styles['row']}`}>
                        <Topbar />
                    </div>
                    <>{!admin ? <h4>You must be an admin to see this page</h4> : ''}</>
                    <table className={`${styles['table-background']} table`}>
                        <thead>
                            <tr >
                                <th >                 
                                    Date Submitted
                                </th>
                                <th >
                                    Reported by
                                </th>
                                <th >
                                    Target
                                </th>
                                <th className='w-10'>
                                    Link
                                </th>
                                <th className='w-50'>
                                    Remark
                                </th>
                                <th >
                                    Approved?
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {complaints ? (complaints.map((item, index)=> {
                                var row = [];
                                let date = (<td>{item.date_submitted}</td>);
                                let reported_by = (<td>{item.by_member}</td>);
                                let target = (<td>{item.about_member}</td>); 
                                let link = (<td>{<a href='item.link' target='_blank'>{item.link}</a>}</td>); 
                                let remark = (<td>{item.remark}</td>); 
                                let approved = (<td>{<input type="checkbox" checked={checkedState[index]} value={index} onChange={handleChange}/>}</td>); 
                                row.push(date, reported_by,target,link,remark,approved);
                                return(<tr>{row}</tr>)
                            })) : ''}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ReportForm