import React, { useContext} from "react";
import { Navigate } from "react-router-dom";
import { isTokenValid } from "../Services/AuthService";
import { Context } from "../Store/Store";
//import { deleteLocalStorage } from "../Scripts/getSetLocalStorage";
//import { TransformUserData } from '../Services/UserDataService';

// Check if user is admin
const AdminRoute = ({ children }) => {
  const loggedIn = isTokenValid();
  const context = useContext(Context);
  const state = context[0];
  if (state.user['data'] === undefined) {
    const t = localStorage.getItem('user');
    const u = JSON.parse(t);
    if (!loggedIn || !u || u.is_staff === false) {
      return <Navigate to={'/Login'} replace />
    }
  }
  else if (state.user?.data?.is_staff !== true){
    return <Navigate to={'/Login'} replace />
  }

  return children;

}

export default AdminRoute;