import domain from "./DomainService";

/**
 * Updates user's reputation score after admin verification.
 *
 * Uses JWT token to identify user
 * @return {Promise}
 */
 export default function UserScoreService(member, form_id) {
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "JWT " + token,
      },
      body : JSON.stringify({member: member, form_id: form_id})
    };
    
    return fetch(domain() + "user-score", options);
  }