import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner/Spinner';
import styles from '../Login/LoginPage.module.css';
import FormInputForgot from './FormInputForgot';

const ForgotPassword = () => {
  const [loading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pauseImage, setPauseImage] = useState(false);
  const handleMouseEnter = () => {
    setPauseImage(true);
  }
  const handleMouseLeave = () => {
    setPauseImage(false);
    setCurrentIndex(currentIndex + 1);
  }

  const changeImage = (e) => {
    let image = e.currentTarget.id;
    if (image === "lead_monitoring"){
      setCurrentIndex(1);
    }
    else {
      setCurrentIndex(0);
    }
  };
  
  const hashtag_trends = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/1_hashtag.svg' alt='FILT Pod'></img></div>
          <div style={{color:"white", textAlign:"center", height:"500px"}}>
            <div style={{height:"90px", marginBottom:"40px"}}>
              <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                <div>Seize LinkedIn hashtags with rapid follower growth for more content exposure by monitoring their behavioral trends.</div>
              </div>
            </div>
            <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
              <img src='/images/hashtag_trends_description_small.jpg' alt='Hashtag Trends'></img>
            </div>
          </div>
          <div >
            <div style={{width:"max-content", margin:"auto"}} className='row'>
              <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
              <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
              
            </div>
          </div>
        </div>
    </div>
)

const lead_monitoring = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/2_engage.svg' alt='FILT Pod'></img></div>
            <div style={{color:"white", textAlign:"center", height:"500px"}}>
              <div style={{height:"90px", marginBottom:"40px"}}>
                <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                Use AI to write insightful comments, saving hours of your time by engaging and increasing touchpoints with multiple LinkedIn leads for faster conversions.
                </div>
              </div>
              <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
                <img src='/images/lead_monitoring.jpg' alt='Lead Monitoring'></img>
              </div>
            </div>
            <div >
              <div style={{width:"max-content", margin:"auto"}} className='row'>
                <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
                <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
        
              </div>
            </div>
        </div>
    </div>
)


  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pauseImage) {
        return
      }
      if (currentIndex >= 1) {
        setCurrentIndex(0)
      }
      else {
        setCurrentIndex(currentIndex + 1)
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, pauseImage]);

  return (
    <div className={`${styles.main} flex-nowrap `}>
      <div className='row'>
      {currentIndex === 1 ? lead_monitoring : hashtag_trends}
        {loading ? <Spinner className='spinner-container' /> : ''}
        <div className={`${styles.rightbackground} col-6 `} style={{margin:"auto"}}>
          {success ? (
            <div className='form-row d-flex justify-content-center'>
            <div className='col-md-8 mt-3' style={{textAlign:"center"}}>
              <div style={{fontSize:"24px", fontWeight:"600", lineHeight:"36px", textAlign:"center"}}>Check your email <img src='/images/email.svg' alt='email.svg' style={{marginBottom:"10px"}}></img></div>
              <div className='mt-3'>We sent you a password reset link. Please be sure to check your spam folder too.</div>
              <div className={`${styles['rect-button']}`} style={{display:"flex", alignItems:"center", marginTop:"70px", cursor:"pointer"}} onClick={()=> {window.open("https://mail.google.com/mail/u/0/#search/from%3A(%40filtpod.com)+in%3Aanywhere");}}>
                <div style={{margin:"0 5px"}}><img src='/images/gmail.jpg' alt='gmail' style={{width:"20px"}}></img></div>
                <div style={{fontWeight:"bold"}}>Open in Gmail</div>
                <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
                </div>
              <div className='d-flex justify-content-center' style={{marginTop:"200px"}}>
                <div><span><img src='/images/back-arrow.svg' alt='back to login'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
              </div>
            </div>
          </div>
          ) : (
            <div>
              <FormInputForgot setSuccess={setSuccess} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
