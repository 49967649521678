import domain from "./DomainService";

// Lists
export function GetSavedListsService(from = 0, to = 1000) {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + `lists?range=[${from},${to}]`, options);
}

export function GetListByIdService(id) {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + `lists?range=[0,9]&filter={"id":${id}}`, options);
}

export function AddNewListService(listData) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(listData),
  };
  return fetch(domain() + "lists", options);
}

export function EditListService({ listId, listData }) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(listData),
  };
  return fetch(domain() + `lists/${listId}`, options);
}

export function DeleteListService(listId) {
  const token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + `lists/${listId}`, options);
}

export function DeleteMultipleListsService(listIds) {
  const token = localStorage.getItem("token");
  const url = domain() + `lists?filter={"id":[${listIds}]}`;
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(url, options);
}

// Prospects

export function GetSavedProspectsService(from = 0, to = 10000) {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  const url = domain() + `prospects?range=[${from},${to}]`;
  return fetch(url, options);
}

export function GetProspectByIdService(id) {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + `prospects?range=[0,100]&filter={"id":${id}}`, options);
}

export function AddNewProspectService(prospectData) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(prospectData),
  };
  return fetch(domain() + "prospects", options);
}

export function EditProspectService({ prospectId, prospectData }) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(prospectData),
  };
  return fetch(domain() + `prospects/${prospectId}`, options);
}

export function EditProspectsService(prospectsData) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(prospectsData),
  };
  return fetch(domain() + "prospects", options);
}

export function DeleteProspectService(id) {
  const token = localStorage.getItem("token");
  const url = domain() + `prospects/${id}`;
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(url, options);
}

export function DeleteMultipleProspectsService(prospectsIds) {
  const token = localStorage.getItem("token");
  const filterValue = JSON.stringify({ id: prospectsIds });
  const url = `${domain()}prospects?filter=${filterValue}`;
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(url, options);
}

// Posts
export function GetMultiplePostsService({ from = 0, to = 10000, sort = null }) {
  let url;
  const token = localStorage.getItem("token");
  url = domain() + `posts?range=[${from},${to}]`;
  if (sort && sort.field) {
    const sortValue = encodeURIComponent(JSON.stringify([sort.field, sort.order]));
    url += `&sort=${sortValue}`;
  }
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(url, options);
}

export function UpdatePostToCompletedService(postId) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ id: postId }),
  };
  return fetch(domain() + "posts", options);
}

export function DeletePostService(id) {
  const token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + `posts/${id}`, options);
}

// --------------------------------------------

export default function GetProspectsPostsService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + "get-prospects-posts", options);
}

export function GetProspectService(email, li_profile_url) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ email: email, linkedin_profile_url: li_profile_url }),
  };

  return fetch(domain() + "api/leads", options);
}

export function SaveProspectService(email, li_profile_url, selectedList) {
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },

    body: JSON.stringify({ email: email, linkedin_profile_url: li_profile_url, list: selectedList }),
  };

  return fetch(domain() + "api/leads", options);
}

export function ImportProspectService(data) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ data: data }),
  };

  return fetch(domain() + "import-prospects", options);
}

export function MarkProspectCompleteService(post_url) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ post_url: post_url }),
  };
  let pod_id = "TIC1";
  return fetch(domain() + "mark-prospect-complete?pod_id=" + pod_id, options);
}

export function ScrapeProspectsProfile(selectedList) {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + "api/posts?list=" + selectedList, options);
}

export function CreateNewList(data) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ data: data }),
  };
  return fetch(domain() + "prospects-list", options);
}

export function DeleteList(data) {
  const token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ data: data }),
  };
  return fetch(domain() + "prospects-list", options);
}

export function deletePosts(ids) {
  const token = localStorage.getItem("token");
  const url = domain() + `posts?filter={"id":[${ids}]}`;

  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(url, options);
}
