import MUIDataTable from "mui-datatables";
import { Checkbox, styled } from "@mui/material";

import "./CustomisedTable.css";

const StyledMUIDataTable = styled(MUIDataTable)(() => ({
  "&": {
    border: "none",
    boxShadow: "none",
    backgroundColor: "#fff",
  },
  "& thead, & tbody": {
    backgroundColor: "#fff",
  },
  "& thead th": {
    textTransform: "none",
    borderTop: "none",
  },
  "& thead tr": {
    borderTop: "none",
  },
  "& tr": {
    borderTop: "2px solid #edeff2",
    borderBottom: "none",
    cursor: "default",
  },
  "& tr th, & tr td": {
    backgroundColor: "#fff",
    border: "none",
  },

  "& .MuiToolbar-root[role='toolbar']": {
    paddingInline: "20px",
  },
  "& .MuiToolbar-root[role='toolbar'] > div:last-of-type": {
    width: "100%",
  },
  "& th > div": {
    color: "#7C8B9D",
    lineHeight: "20px",
    fontSize: "12px",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
  "& .MuiPaper-root:has(.deleteAll)": {
    background: "#fff",
  },
  "& .MuiPaper-root:has(.deleteAll) h6": {
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#1e1e1e",
  },
  "& td input, & th input": {
    height: "100%",
    width: "100%",
  },
}));

function CustomisedTable({ table }) {
  function CustomCheckbox(props) {
    return <Checkbox style={{ color: "#7C8B9D" }} {...props} />;
  }

  return (
    <StyledMUIDataTable
      columns={table.columns}
      options={table.options}
      data={table.data}
      components={{
        Checkbox: CustomCheckbox,
      }}
    />
  );
}

export default CustomisedTable;
