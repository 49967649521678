import domain from "./DomainService";

export default function GetSettingsService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + "settings", options);
}

export function UpdateSettingsService(data) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ data: data }),
  };
  return fetch(domain() + "settings", options);
}

export function AddSettingsService(data) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ data: data }),
  };
  return fetch(domain() + "settings", options);
}

export function DeleteSettingService(data) {
  const token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ data }),
  };
  return fetch(domain() + "settings", options);
}
