import React from "react";

const FPInstitute = () => {
  return (
    <div>
      <div className="row flex-nowrap no-gutters">
        <div className="no-gutters col-105 mt-2">
          <div></div>
          <div style={{ marginTop: "5em" }}>
            <div style={{ textAlign: "center", color: "#385A9F" }}>
              <h2>FILT Pod Institute</h2>
            </div>
            <div style={{ padding: "0 10em", fontSize: "17px", marginTop: "2em" }}>
              <p>
                If you could own every strategy and technique to boost your presence on LinkedIn and beyond, would you?
              </p>
              <p>
                The FILT Pod Institute holds every insider tip and tactic that will drive business growth for every
                member of our exclusive circle.
              </p>
              <p>
                We cover everything from what you need to master LinkedIn to every aspect of content marketing that will
                move leads through your sales funnel:
              </p>
              <p>
                <ul style={{ listStyleType: "square" }}>
                  <li>Ace the Basics to Maximise Your Profile Visibility</li>
                  <li>Content Marketing for Smart Business Owners</li>
                  <li>Master the LinkedIn Algorithm</li>
                  <li>Transform Your Engagement into Revenue</li>
                  <li>Reduce Workload and Increase Efficiency</li>
                </ul>
              </p>
              <div style={{ textAlign: "center", marginTop: "2em" }}>
                <div>Discover what you’ll get at every stage of FILT Pod Institute:</div>
                <div>
                  <a href="https://filtpod.com/filtpod-institute/" target="__blank">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FPInstitute;
