import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Notification.module.css';
import { NotificationCountService } from '../../Services/NotificationService';

const Notification = () => {
    const [count, setUnreadCount] = useState(0);
    let history = useNavigate();
    const redirect = (path) => {
        history(path);
      };

    useEffect (() => {
        NotificationCountService('get').then((res) => {
            res.json().then((json) => {
                setUnreadCount(json.data.unread)
            })
        })
    }, [])

    const handleClick = () => {
        setUnreadCount(0);
        NotificationCountService('set').then((res) => {
            if (res.status === 200) {
                setUnreadCount(0);
                redirect('/Notification')
            }
        })
      };

    return (
        <div>
            <div onClick={handleClick} className={`${styles['topbar-button']}`} style={{padding:"6px", cursor:"pointer"}}>
                  <img src='images/bell.svg' alt=''></img>
                  {count > 0 ? <sup className={`${styles['badge']}`}>{count}</sup> : ''}
            </div>
        </div>
    )

}

export default Notification