import React from "react";
import styles from "./Spinner.module.css";

const Spinner = () => {
  return (
    <div className="spinner-wrapper" id="spinner">
      <div className={`${styles["loader"]}`}>Loading...</div>
    </div>
  );
};
export default Spinner;
/**
 *      <div id="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="loading"></div>
      </div> 
 */
