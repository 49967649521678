import React, { useEffect } from 'react';
import styles from '../Onboarding/Onboarding.module.css';
import loginStyles from '../Login/LoginPage.module.css';
import Spinner from '../Spinner/Spinner';
//import { CreateCheckoutSessionService } from '../../Services/StripeService';

const Upgrade = ()=> {
  useEffect(() => {
    window.location.replace("https://buy.stripe.com/fZe4kde98eNr7sYcMZ");
    /** 
    CreateCheckoutSessionService("engageaipro_65_1month_3000").then((res) => {
      if (res.status === 200) {
        res.json().then((json) => {
          console.log(json)
          window.location.replace(json.data.checkout_url);
      })
  })*/
  }, []);
 
  return (
    <div>
        <div className={`${styles.row}`}>
            <div className={`${loginStyles.rightbackground} col-6 `}>
            <Spinner />
            </div>
        </div>
    </div>
  );
};
export default Upgrade;