import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)(({ paddingblock, color, textarea, height, textareabottom }) => ({
  "&": {
    background: "white",
    marginBlock: "0 !important",
    borderRadius: "15px",
    border: "1px solid #DFEAF2",
    paddingLeft: textarea ? "8px" : "1rem",
    paddingRight: textarea ? "5px" : "1rem",
  },
  "&, & > div": {
    height: height || "auto",
    color: color || "#718EBF",
  },
  "& .MuiSelect-select:focus": {
    background: "none",
  },

  "& .MuiFormHelperText-root": {
    position: "absolute",
    bottom: textarea ? textareabottom || "0" : "-1.3rem",

    right: textarea ? "14px" : "unset",
    ...(textarea && {
      fontSize: "12px",
      color: "#7C8B9D99",
      fontWeight: "600",
    }),
  },
  "& input": {
    paddingBlock: paddingblock || "14px",
  },
  "& textarea": {
    paddingTop: "8px",
    paddingRight: "5px",
    paddingBottom: "8px",
    marginBottom: "18px",
    fontSize: "12px",
    color: "#718EBF",
    lineHeight: "1.5",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
      cursor: "default",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#e6e9ec",
      cursor: "default",
    },
  },
  "& input::placeholder": {
    color: "#718EBF",
    opacity: "1",
    fontSize: "15px",
    fontStyle: "normal",
  },
  "& input > div": {
    opacity: "1",
  },
}));
