import React from "react";
import styles from "./Accountability.module.css";

const defaultProfileIcon = (
  <svg width="25" height="25" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M75.7574 69.5812C71.7637 63.1462 62.9718 56.25 44.9999 56.25C27.0281 56.25 18.2418 63.1406 14.2424 69.5812C17.9273 74.2007 22.6063 77.9298 27.9315 80.4911C33.2567 83.0524 39.0908 84.38 44.9999 84.375C50.909 84.38 56.7432 83.0524 62.0684 80.4911C67.3935 77.9298 72.0726 74.2007 75.7574 69.5812Z"
      fill="#23324E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45 50.625C49.4755 50.625 53.7678 48.8471 56.9324 45.6824C60.0971 42.5178 61.875 38.2255 61.875 33.75C61.875 29.2745 60.0971 24.9823 56.9324 21.8176C53.7678 18.6529 49.4755 16.875 45 16.875C40.5245 16.875 36.2323 18.6529 33.0676 21.8176C29.9029 24.9823 28.125 29.2745 28.125 33.75C28.125 38.2255 29.9029 42.5178 33.0676 45.6824C36.2323 48.8471 40.5245 50.625 45 50.625Z"
      fill="#23324E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45 5.625C34.5571 5.625 24.5419 9.77343 17.1577 17.1577C9.77343 24.5419 5.625 34.5571 5.625 45C5.625 55.4429 9.77343 65.4581 17.1577 72.8423C24.5419 80.2266 34.5571 84.375 45 84.375C55.4429 84.375 65.4581 80.2266 72.8423 72.8423C80.2266 65.4581 84.375 55.4429 84.375 45C84.375 34.5571 80.2266 24.5419 72.8423 17.1577C65.4581 9.77343 55.4429 5.625 45 5.625ZM0 45C0 33.0653 4.74106 21.6193 13.1802 13.1802C21.6193 4.74106 33.0653 0 45 0C56.9347 0 68.3807 4.74106 76.8198 13.1802C85.2589 21.6193 90 33.0653 90 45C90 56.9347 85.2589 68.3807 76.8198 76.8198C68.3807 85.2589 56.9347 90 45 90C33.0653 90 21.6193 85.2589 13.1802 76.8198C4.74106 68.3807 0 56.9347 0 45Z"
      fill="#23324E"
    />
  </svg>
);


const ProfileBox = (props) => {
  let { idx, item } = props;
  return (
    
    <div key={idx} className={`${styles["card-user-box"]} `}>
      <div className={`${styles["card-content-35"]} d-flex  justify-content-center card-content-35`}>
        {defaultProfileIcon}
      </div>
      <div className={`${styles["card-content-35"]} d-flex text-center mt-3 mb-1`}>
        <span className={`${styles["card-name-bold"]} align-self-end`}>{item['assignee']}</span>
      </div>
      <div className="d-flex justify-content-center text-center">
        <div className={item['is_complete'] === false ? `${styles["div-complete-color"]}` : `${styles["div-pending-color"]}`}>
          <div className={`${styles["div-status"]}`}>{item['is_complete'] === false ? "Pending" : "Opened"}</div>
        </div>
      </div>
    </div>
);
};


export default ProfileBox;
