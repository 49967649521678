import { useNavigate, useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { MdGroups } from "react-icons/md";
import StyledButton from "../../ReusableComponents/Button";

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 24px",
    gap: "24px",
    "& *": {
      margin: " 0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "29px",
      color: "#1F292E",
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "400",
      color: "#415058",
      lineHeight: "24px",
      "& span": {
        fontSize: "14px",
        fontWeight: "700",
        color: "#1F292E",
      },
    },
  },
  modalImg: {
    marginTop: "32px",
  },
};

function NoPostsFoundDialog({ onCloseModal }) {
  const navigate = useNavigate();
  const { setActiveTab } = useOutletContext();

  const goToProspects = () => {
    navigate("/NurtureProspects/prospects");
    setActiveTab("prospects");
    onCloseModal();
  };

  return (
    <Box sx={styles.modal}>
      <img style={styles.modalImg} src="/images/noPostsImg.png" alt="No posts" />

      <h3>No new posts found </h3>
      <Typography>
        Your prospects appear to be inactive. <br />
        Would you like to add new prospects ?
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" gap="8px">
        <StyledButton onClick={goToProspects}>
          <MdGroups />
          <span>Manage prospects</span>
        </StyledButton>
        <StyledButton onClick={onCloseModal} variation="noBackground">
          No thanks
        </StyledButton>
      </Box>
    </Box>
  );
}

export default NoPostsFoundDialog;
