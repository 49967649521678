import { Box, Button, DialogContent, DialogTitle } from "@mui/material";

import StyledButton from "../../ReusableComponents/Button";

import { IoClose } from "react-icons/io5";
import { formateNumber } from "../../../../Utils/reusableFunctions";
import { Dialog } from "@mui/material";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    paddingTop: "32px",
    textAlign: "center",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },
  closeBtn: {
    background: "#ecedee",
    border: "none",
    padding: "8px",
    borderRadius: "3px",
    transition: "all 0.2s",
    height: "40px",
    width: "40px",
    minWidth: "40px",

    "&:hover": {
      background: " #ecedeecc",
    },

    "& svg ": {
      width: "24px",
      height: "24px",
      color: "#1f292e",
    },
  },
};

function MaxLimitDialog({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xs"}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <Button sx={styles.closeBtn} onClick={onClose}>
            <IoClose />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.Modal}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ borderRadius: "8px", width: "56px", height: "56px", background: "var(--color-red)" }}
          >
            <img
              src="/images/maxProspectsLimit.svg"
              alt="max limit exceeded"
              style={{ color: "white", height: "40px", width: "40px" }}
            />
          </Box>
          <h2>Exceeded prospect limit</h2>
          <span>
            You have reached the maximum of <strong>{formateNumber(10000)} saved prospects</strong> in your account.
            Please delete some inactive prospects to free up space.
          </span>
          <StyledButton onClick={onClose} variation="primary">
            <IoClose />
            <span>Close</span>
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default MaxLimitDialog;
