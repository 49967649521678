import { Box, Typography } from "@mui/material";
import { RiDeleteBin6Fill } from "react-icons/ri";
import StyledButton from "../../NurtureProspects/ReusableComponents/Button";
import { DeleteSettingService } from "../../../Services/SettingsService";

const styles = {
  deleteModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 24px",
    gap: "10px",
    "& *": {
      margin: " 0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#415058",
      lineHeight: "24px",
      marginBottom: "5px",
      "& span": {
        fontSize: "14px",
        fontWeight: "700",
        color: "#1F292E",
      },
    },
  },
};

function DeleteTone({ tone, onCloseModal, onDelete }) {
  const handleDeleteTone = async () => {
    const response = await DeleteSettingService([{ id: tone.id }]);
    if (response.status === 200) {
      onDelete();
      onCloseModal();
    }
  };

  return (
    <Box sx={styles.deleteModal}>
      <h3>Delete the tone ?</h3>
      <Typography>
        This action will delete the tone <span>{tone.label}.</span>
      </Typography>
      <Box display="flex" justifyContent="center" gap="16px">
        <StyledButton onClick={onCloseModal} variation="secondary">
          Cancel
        </StyledButton>
        <StyledButton variation="danger" onClick={handleDeleteTone}>
          <RiDeleteBin6Fill />
          <span>Delete</span>
        </StyledButton>
      </Box>
    </Box>
  );
}

export default DeleteTone;
