import { Checkbox } from "@mui/material";
import { ReactComponent as CheckboxIcon } from "./checkbox.svg";
import { ReactComponent as CheckboxIconChecked } from "./checkbox-checked.svg";

function StyledCheckbox(props) {
  return (
    <Checkbox
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxIconChecked />}
      sx={{
        "&:hover": {
          // background: "rgba(47, 62, 172, 0.04)",
          background: "none",
        },
      }}
      {...props}
    />
  );
}

export default StyledCheckbox;
