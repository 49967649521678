import { styled } from "@mui/material";

const sizes = {
  small: `
        font-size: 12px;
        padding: 4px 8px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
    `,
  medium: `
        font-size: 16px;
        padding: 8px 24px;
        font-weight: 600;
        line-height: 20px;
        min-height: 40px !important;
        @media screen and (max-width: 43.75em) {
            padding: 10px 14px;
        }
        
    `,
  large: `
        font-size: 1.6rem;
        padding: 12px 24px;
        font-weight: 500;
        @media screen and (max-width: 43.75em) {
            padding: 10px 20px;
        }
        & svg {
            width: 24px;
            height: 24px;
        }
    `,
};

const variations = {
  primary: `
        color: #fff;
        background-color: var(--color-brand);

        &:hover {
            background-color: var(--color-brand-light);
        }
        
        &:disabled {
            background-color: var(--color-brand-light);
        }
    `,
  secondary: `
        color: var(--color-grey-400);
        background: #fff;
        border: 1px solid var(--color-grey-400);
        
        &:hover {
            color: #fff;
            background: var(--color-grey-400);
        }
    `,
  danger: `
        color: #fff;
        background-color: var(--color-red);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08), 0 0 4px rgba(47, 62, 172, 0.1); 
        &:hover {
            background-color: var(--color-red-light);
        }
        `,
  warning: `
        background: var(--color-warning);
        color: #fff;
        border: 1px solid #00000026;
        
        &:hover {
            background-color: var(--color-warning-light);
        }
    `,
  noBackground: `
        color: var(--color-grey-400);
        background: none;
        border: none;
        padding: 0;
        font-size: 16px !important
    `,
  noBackgroundPrimary: `
        color: var(--color-brand);
        background: none;
        border: none;
        padding: 0;
        font-size: 16px !important
        `,
  roundedPrimary: `
        border-radius: 100rem;
        color: var(--color-brand);
        border: 1px solid var(--color-brand);
        background: none;
        padding: 4px 6px;
    `,
};

const StyledButton = styled("button")`
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  margin: 0;
  &:disabled {
    cursor: not-allowed;
  }

  ${({ size }) => sizes[size]}
  ${({ variation }) => variations[variation]}
`;

StyledButton.defaultProps = {
  variation: "primary",
  size: "medium",
};

export default StyledButton;
