// -------------------------------------------------------------------------
// IMPORTS
// -------------------------------------------------------------------------
import { useState } from "react";
import Papa from "papaparse";
import { CSVLink } from "react-csv";

import { ImportProspectService } from "../../../../../Services/GetProspectsPosts";

import DropZone from "../../../ReusableComponents/DropZone";
import StyledButton from "../../../ReusableComponents/Button";
import { formateNumber } from "../../../../../Utils/reusableFunctions";
import { useNurtureContext } from "../../../../../Context/NurtureContext";
import { useOutletContext } from "react-router-dom";

import ImportResultsContent from "./ImportResultsContent";
import OtherError from "./OtherError";
import SyncAccount from "./SyncAccountDialog";
import InvalidCSV from "./InvalidCSV";
import { template } from "./CSVTemplate";

import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { IoClose } from "react-icons/io5";
import { BiExport } from "react-icons/bi";
import { FaCheck } from "react-icons/fa6";
import MaxLimit from "./MaxLimitAfterImport";

// -------------------------------------------------------------------------

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  closeBtn: {
    background: "#ecedee",
    border: "none",
    padding: "8px",
    borderRadius: "3px",
    transition: "all 0.2s",
    height: "40px",
    width: "40px",
    minWidth: "40px",

    "&:hover": {
      background: " #ecedeecc",
    },

    "& svg ": {
      width: "24px",
      height: "24px",
      color: "#1f292e",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    fontWeight: "500",
  },
  sectionTitle: {
    position: "relative",
    zIndex: "2",

    "& h6": {
      color: "#415058",
      fontWeight: "600",
      fontSize: "16px",
      position: "relative",
      zIndex: "2",
      paddingRight: "16px",
      background: "#fff",
      margin: "0",
      width: "max-content",
    },

    "&::after": {
      content: `""`,
      position: "absolute",
      top: "50%",
      width: "100%",
      height: "1px",
      background: "#E6E9EC",
      transform: "translateY(-50%)",
      left: "0",
      display: "block",
    },
  },
  infoBox: {
    padding: "6px 16px",
    borderRaidus: "8px",
    background: "rgba(229, 246, 253, 1)",
    display: "flex",
    alignItems: "center",
    gap: "8px",

    "& span": {
      fontSize: "14px",
      fontWeight: "500",
      color: "rgba(1, 67, 97, 1)",
    },
  },
};

// -------------------------------------------------------------------------
// MAIN
// -------------------------------------------------------------------------

const requiredColumns = ["first_name", "last_name", "list_name", "list_name*", "url", "url*"];

function ImportLeadsDialog({ open, onClose, monioredProspects }) {
  const { setRefreshProspectsKey, prospects: allProspects = [] } = useNurtureContext();
  const { prospectsLimit } = useOutletContext();

  const [isSaving, setIsSaving] = useState(false);
  const [hasImported, setHasImported] = useState(false);
  const [importFinished, setImportFinished] = useState(false);
  const [openMaxLimitDialog, setOpenMaxLimitDialog] = useState(false);
  const [fileDataLength, setFileDataLength] = useState(null);
  const [invalidCSV, setInvalidCSV] = useState(false);
  const [errorSync, setErrorSync] = useState(false);
  const [errorImport, setErrorImport] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [results, setResults] = useState({
    imported: [],
    already_exists: [],
    error: [],
  });

  const transformErrorArr = (errors) => {
    if (!errors || errors.length === 0) return;
    let finalArr = [];
    let lines = [];
    let temp = errors.map((item) => ({ ...item, errors: { [item.column]: item.reason }, line: item.line + 2 }));
    temp.forEach((item) => {
      if (!lines.includes(item.line)) {
        const dpLinesItems = temp.filter((_item) => _item.line === item.line);
        if (dpLinesItems.length > 1) {
          lines.push(item.line);
          finalArr.push({
            ...item,
            errors: dpLinesItems.reduce(
              (acc, cur) => ({
                ...acc,
                [cur.column]: cur.reason,
              }),
              {}
            ),
          });
        } else {
          finalArr.push(item);
        }
      }
    });
    return finalArr;
  };

  const handleImport = async () => {
    if (!selectedFile) return;
    setIsSaving(true);
    const reader = new FileReader();
    reader.onload = async (ev) => {
      try {
        const csv = Papa.parse(ev.target.result, { header: true, skipEmptyLines: true, separator: "," });
        const headers = csv.meta.fields;

        const hasRequiredColumns = requiredColumns.some((col) => headers.includes(col));

        if (!hasRequiredColumns) {
          setInvalidCSV(true);
          return;
        }

        const urlAst = headers.some((item) => item.includes("url*"));
        const listAst = headers.some((item) => item.includes("list_name*"));

        const parsedData = csv.data.map((item) => ({
          url: urlAst ? item?.["url*"] : item?.url,
          list_name: listAst ? item?.["list_name*"] : item?.list_name,
          first_name: item?.first_name,
          last_name: item?.last_name,
          headline: item?.headline,
        }));

        if (parsedData.length > 10000 - allProspects.length) {
          setFileDataLength(parsedData.length);
          setOpenMaxLimitDialog(true);
          return;
        }

        const canMonitorLength = prospectsLimit - monioredProspects.length;
        const payload = parsedData.map((item, index) => ({ ...item, monitoring: index < canMonitorLength }));

        const response = await ImportProspectService(payload);
        const responseData = await response.json();

        if (response.ok) {
          let draft = {
            imported: [],
            already_exists: [],
            error: [],
          };
          if (responseData.imported.length > 0) {
            draft.imported = responseData.imported;
            setRefreshProspectsKey((prev) => prev + 1);
          }

          if (responseData.already_exists.length > 0) {
            draft.already_exists = responseData.already_exists.map((_url) => {
              const line = parsedData.findIndex((item) => item.url === _url);
              return { line: line + 2, url: _url };
            });
          }
          if (responseData.error.length > 0) {
            if (responseData.error.some((item) => item?.reason && item?.reason.includes("LinkedIn cookie"))) {
              return setErrorSync(true);
            }
            draft.error = transformErrorArr(responseData.error);
          }
          setResults(draft);
        }
      } catch (err) {
        setErrorImport(true);
      } finally {
        setIsSaving(false);
        setImportFinished(true);
      }
    };

    reader.readAsText(selectedFile);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
          width: openMaxLimitDialog || errorImport || errorSync || invalidCSV ? "450px" : "600px",
        },
      }}
    >
      {!errorSync && (
        <DialogTitle>
          <Box display="flex" alignItems="center" mb="8px">
            <Box display="flex" alignItems="center" gap="10px" flexGrow={1}>
              {!importFinished && !errorImport && !errorSync && !openMaxLimitDialog && !invalidCSV && (
                <>
                  <BiExport style={{ height: "24px", width: "24px" }} />
                  <h2 style={{ fontSize: "24px", fontWeight: "700", color: "#1F292E", margin: "0" }}>Import Leads</h2>
                </>
              )}
              {!hasImported &&
                !isSaving &&
                !importFinished &&
                !errorImport &&
                !errorSync &&
                !openMaxLimitDialog &&
                !invalidCSV && (
                  <span
                    style={{
                      background: "rgba(47, 62, 172, 0.1)",
                      padding: "4px 8px",
                      borderRadius: "8px",
                      color: "var(--color-brand)",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                  >
                    Beta version
                  </span>
                )}
            </Box>
            <Button sx={styles.closeBtn} onClick={onClose}>
              <IoClose />
            </Button>
          </Box>
        </DialogTitle>
      )}
      <DialogContent sx={errorSync ? { padding: 0 } : {}}>
        {isSaving && !importFinished && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="16px"
            sx={{
              position: "relative",
              borderRadius: "8px",
              border: "1px solid rgba(224, 226, 228, 1)",
              background: "rgba(245, 245, 251, 0.5)",
              padding: "40px",
            }}
          >
            <CircularProgress />
            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "var(--color-brand)" }}>
              Importing leads...
            </Typography>
          </Box>
        )}
        {!isSaving && !importFinished && (
          <>
            <Box sx={styles.section} gap="8px" mb="32px">
              <Box sx={styles.sectionTitle}>
                <h6>Download the CSV template</h6>
              </Box>
              <span style={{ marginBottom: "8px" }}>
                To import leads in bulk using a file,{" "}
                <CSVLink {...template} enclosingCharacter={``} separator={`,`}>
                  <strong style={{ color: "var(--color-brand)", textDecoration: "underline" }}>
                    Download the CSV template
                  </strong>
                </CSVLink>
              </span>
              {!hasImported && (
                <span style={{ color: "var(--color-red)" }}>
                  Columns with names ending in * are required. Other columns can be provided if available (recommended)
                  , otherwise they will be retrived automatically from LinkedIn.
                </span>
              )}
            </Box>
            <Box sx={styles.section} gap="16px">
              <Box sx={styles.sectionTitle}>
                <h6>Step 2 : Upload your File</h6>
              </Box>
              <span>Add your leads to the CSV template and upload the completed file here:</span>
              <DropZone
                onChange={(files) => {
                  if (files.length > 0) {
                    setHasImported(true);
                    setSelectedFile(files[0]);
                  }
                }}
              />
            </Box>

            {!hasImported && (
              <Box sx={styles.infoBox} mt="24px">
                <span>
                  You can currently add <strong>{formateNumber(10000 - allProspects.length)} more prospects</strong> to
                  your account.
                </span>
              </Box>
            )}
          </>
        )}
        {!isSaving && importFinished && !errorImport && !errorSync && !openMaxLimitDialog && !invalidCSV && (
          <ImportResultsContent results={results} />
        )}
        {!isSaving && importFinished && errorSync && <SyncAccount />}
        {!isSaving && importFinished && errorImport && <OtherError />}
        {!isSaving && importFinished && invalidCSV && <InvalidCSV />}
        {!isSaving && importFinished && openMaxLimitDialog && (
          <MaxLimit fileDataLength={fileDataLength} allProspects={allProspects} />
        )}
      </DialogContent>
      {!isSaving && (
        <DialogActions>
          {importFinished ? (
            <Box
              sx={{ width: "100%" }}
              display="flex"
              justifyContent={openMaxLimitDialog || errorImport || errorSync || invalidCSV ? "center" : "flex-end"}
              px="16px"
              py="16px"
            >
              {!errorSync ? (
                <StyledButton onClick={onClose}>
                  <IoClose />
                  <span>Close</span>
                </StyledButton>
              ) : (
                <StyledButton onClick={onClose}>
                  <FaCheck />
                  <span>Done</span>
                </StyledButton>
              )}
            </Box>
          ) : (
            <Box sx={{ width: "100%" }} display="flex" justifyContent="space-between" px="16px" py="16px">
              <StyledButton onClick={onClose} variation="noBackgroundPrimary">
                Cancel
              </StyledButton>
              <StyledButton
                sx={{ "& span": { color: "white", width: "24px !important", height: "24px !important" } }}
                disabled={!hasImported}
                onClick={handleImport}
              >
                {isSaving ? <CircularProgress /> : "Import"}
              </StyledButton>
            </Box>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default ImportLeadsDialog;
