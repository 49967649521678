import React, { useContext} from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isTokenValid } from "../Services/AuthService";
import { Context } from "../Store/Store";
//import { deleteLocalStorage } from "../Scripts/getSetLocalStorage";
//import { TransformUserData } from '../Services/UserDataService';

// Check if user is admin
const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const loggedIn = isTokenValid();
  const context = useContext(Context);
  const state = context[0];
  if (state.user['data'] === undefined) {
    const t = localStorage.getItem('user');
    const u = JSON.parse(t);
    if (!loggedIn || !u) {
      return <Navigate to={'/Login'} state={{ from: location }} replace />
    }
  }

  return children;

}

export default PrivateRoute;