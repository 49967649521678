import React, { useContext, useState } from "react";
import { Context } from "../../Store/Store";
import styles from "./NurtureProspects.module.css";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Outlet } from "react-router-dom";
import Tabs from "./Tabs/Index";
import { Box } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

const NurtureProspects = () => {
  const path = window.location.pathname.split("/")[2];
  const [activeTab, setActiveTab] = useState(path || "posts");

  const context = useContext(Context);
  const state = context[0];
  const userGroup = state.user?.data?.group;
  const userId = state.user?.data?.id;
  const prospectsLimit = state.user?.data?.max_number_of_leads;
  const premiumUser =
    state.user?.data?.group === "Premium" ||
    state.user?.data?.group === "Growth Plan" ||
    state.user?.data?.group === "Member" ||
    state.user?.data?.group === "Starter" ||
    state.user?.data?.group === "Trial";

  return (
    <div>
      <ReactNotifications />
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <Box className="headerText" mb="1.5rem">
          <Box display="flex" alignItems="center" gap="8px">
            <h1>Monitor</h1>
          </Box>
          <p>
            Create targeted prospect for relationship building. &nbsp;
            <a href="https://bit.ly/EAI-Monitoring" target="_blank" rel="noopener noreferrer">
              <span>See documentation</span>
              <LaunchIcon />
            </a>
          </p>
        </Box>
        <div className={styles["box-container"]}>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <Outlet
            context={{
              userGroup,
              premiumUser,
              userId,
              activeTab,
              setActiveTab,
              prospectsLimit,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NurtureProspects;
