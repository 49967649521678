import React from "react";

export default function CreateResponseMessage(responseValues) {
  if (responseValues.response) {
    // Case where we have to modify the message
    if (responseValues.response.statusInt === 402 && responseValues.response.session_url) {
      let messageArray = responseValues.response["message"].match(/(.*)(Stripe portal)(.*)/);
      const session_url = responseValues.response.session_url;
      return (
        <div data-testid="message-with-url">
          {messageArray[1]} {<a href={session_url}>{messageArray[2]}</a>}
          {messageArray[3]}
        </div>
      );
    }
    // Use default message from server
    return <div>{responseValues.response["message"]}</div>;
  }
};
