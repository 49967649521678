import Gleap from "gleap";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { Context } from "../../Store/Store";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { TransformUserData } from "../../Services/UserDataService";
import ReactJoyRide from "react-joyride";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const tourStyles = {
  // modal arrow and background color
  arrowColor: "#eee",
  backgroundColor: "#FFFFFF",
  //button color
  primaryColor: "#2F3EAC",
  //text color
  textColor: "#333",
  //width of modal
  width: 500,
  //zindex of modal
  zIndex: 1000,
};

const Main = () => {
  const [state, dispatch] = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [run, setRun] = useState(false);
  const userName = state.user?.data?.firstName;

  const modalBody = (
    <div style={{ padding: "24px", textAlign: "center" }}>
      <div
        style={{ cursor: "pointer", textAlign: "right" }}
        onClick={() => {
          setShowModal(false);
        }}
      >
        X
      </div>
      <div
        style={{ color: "#2F3EAC", display: "flex", gap: "4px", justifyContent: "center", cursor: "pointer" }}
        onClick={() => {
          setRun(true);
          setShowModal(false);
        }}
      >
        <img style={{ width: "14px", height: "20px" }} src="/images/tour.svg" alt=""></img>
        <div>Quick Tour</div>
      </div>
    </div>
  );

  let history = useNavigate();
  const redirect = useCallback(
    (path) => {
      history(path);
    },
    [history]
  );

  // Onboarding tour
  const TOUR_STEPS = [
    {
      target: ".engage",
      content: (
        <div style={{ textAlign: "left" }}>
          <div
            className="row"
            style={{
              color: "#2F3EAC",
              alignItems: "center",
              gap: "5px",
              borderBottom: "1px solid #E8EEF6",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <img style={{ height: "18px", width: "24px" }} src="/images/engage_blue.svg" alt=""></img>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>Engage</div>
          </div>
          <div style={{ fontSize: "14px", marginBottom: "10px" }}>
            Engage and increase touchpoints with your audience.
          </div>
          <div
            onClick={() => {
              redirect("/NurtureProspects?tour=true");
            }}
            style={{
              background: "#E1E7F0",
              borderRadius: "200px",
              padding: "8px 20px",
              color: "#2F3EAC",
              display: "flex",
              gap: "4px",
              width: "max-content",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            <img style={{ width: "14px", height: "20px" }} src="/images/tour.svg" alt=""></img>
            <div>Begin Tour</div>
          </div>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".hashtag",
      content: (
        <div style={{ textAlign: "left" }}>
          <div
            className="row"
            style={{
              color: "#2F3EAC",
              alignItems: "center",
              gap: "5px",
              borderBottom: "1px solid #E8EEF6",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <img style={{ height: "18px", width: "24px" }} src="/images/hashtag_blue.svg" alt=""></img>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>Hashtag</div>
          </div>
          <div style={{ fontSize: "14px", marginBottom: "10px" }}>
            Seize hashtags with rapid follower growth for <b>greater exposure</b> and <b>audience reach</b>.
          </div>
          <div
            onClick={() => {
              redirect("/LinkedinHashtagTrend?tour=true");
            }}
            style={{
              background: "#E1E7F0",
              borderRadius: "200px",
              padding: "8px 20px",
              color: "#2F3EAC",
              display: "flex",
              gap: "4px",
              width: "max-content",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            <img style={{ width: "14px", height: "20px" }} src="/images/tour.svg" alt=""></img>
            <div>Begin Tour</div>
          </div>
        </div>
      ),
      placement: "right",
    },
    {
      target: ".boost",
      content: (
        <div style={{ textAlign: "left" }}>
          <div
            className="row"
            style={{
              color: "#2F3EAC",
              alignItems: "center",
              gap: "5px",
              borderBottom: "1px solid #E8EEF6",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <img style={{ height: "18px", width: "24px" }} src="/images/boost_blue.svg" alt=""></img>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>Boost</div>
          </div>
          <div style={{ fontSize: "14px", marginBottom: "10px" }}>
            Give your LinkedIn Post a strong boost of visibility for better audience reach and maximise your
            opportunities.
          </div>
        </div>
      ),
      placement: "right",
    },
    {
      target: ".integrations",
      content: (
        <div style={{ textAlign: "left" }}>
          <div
            className="row"
            style={{
              color: "#2F3EAC",
              alignItems: "center",
              gap: "5px",
              borderBottom: "1px solid #E8EEF6",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <img style={{ height: "18px", width: "24px" }} src="/images/puzzle_blue.svg" alt=""></img>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>Integrations</div>
          </div>
          <div style={{ fontSize: "14px", marginBottom: "10px" }}>Check out our partners!</div>
        </div>
      ),
      placement: "right",
    },
    {
      target: ".account",
      content: (
        <div style={{ textAlign: "left" }}>
          <div
            className="row"
            style={{
              color: "#2F3EAC",
              alignItems: "center",
              gap: "5px",
              borderBottom: "1px solid #E8EEF6",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <img style={{ height: "18px", width: "24px" }} src="/images/profile.svg" alt=""></img>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>My Profile</div>
          </div>
          <div style={{ fontSize: "14px", marginBottom: "10px" }}>
            Configure your profile and account settings here.
          </div>
        </div>
      ),
      placement: "right",
    },
  ];
  useEffect(() => {
    // Check localstorage to see if user data exists
    // Use case is when a user refreshes and we lose the context store
    // Rehydrate user data from localstorage
    // Have to use payload: {data: {}} data inside is to match the shape of the original object
    // without this the conext store isn't readable
    const t = localStorage.getItem("user");
    const u = JSON.parse(t);
    if (state.user["data"] === undefined) {
      if (u) {
        dispatch({
          type: "SET_USER",
          payload: {
            data: TransformUserData(u),
          },
        });
      }
    }
    console.log(u)
    Gleap.identify(u.id, {
      name: u.firstName + " " + u.lastName,
      email: u.email,
      plan: u.group,
    });
    // show pop up notification if it's user's first login
    if (u && u.is_first_login) {
      setShowModal(true);
      u.is_first_login = false;
      localStorage.setItem("user", JSON.stringify(u));
    }
  }, [dispatch, state.user]);

  useEffect(() => {
    //tour
    const queryParams = new URLSearchParams(window.location.search);
    const tour = queryParams.get("tour");
    if (tour === "true") {
      setShowModal(true);
    }
  }, [history]);

  return (
    <div>
      <ReactJoyRide
        steps={TOUR_STEPS}
        run={run}
        continuous
        showProgress
        showSkipButton
        styles={{ options: tourStyles }}
      />
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <Box className="headerText" mb="1.5rem">
            <Box display="flex" alignItems="center" gap="8px">
              <h1>{`Welcome, ${userName}!`}</h1>
            </Box>
        </Box>
        <div style={{ flex: "1" }}>
          <div>
            <div style={{ display: "flex", gap: "15px", marginBottom: "20px" }}>
              <div
                style={{ borderRadius: "10px", color: "#2F3EAC", padding: "24px", background: "#F2F6FA", width: "70%" }}
              >
                <div style={{ color: "#2F3EAC", fontSize: "14px", fontWeight: 700, marginBottom: "10px" }}>
                  Get Started
                </div>
                <div style={{ display: "flex", gap: "15px", marginBottom: "20px" }}>
                  <div
                    style={{
                      padding: "20px 12px",
                      background: "white",
                      boxShadow: "2px 2px 2px #686262c9",
                      borderRadius: 4,
                      width: "50%",
                    }}
                  >
                    <Link to="/NurtureProspects">
                      <div style={{ margin: "auto" }}>
                        <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                          <img style={{ height: "23px" }} src="images/monitor.svg" alt=""></img>
                          <div>Monitor</div>
                        </div>
                      </div>
                    </Link>
                    <div style={{ textAlign: "center", fontSize: "14px" }}>Build relationships effectively</div>
                  </div>
                  <div
                    style={{
                      padding: "20px 12px",
                      background: "white",
                      boxShadow: "2px 2px 2px #686262c9",
                      borderRadius: 4,
                      width: "50%",
                    }}
                  >
                    <div>
                      <Link to="/CustomizeAI">
                        <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                          <img style={{ height: "20px" }} src="images/settings_blue.svg" alt=""></img>
                          <div>Customise AI</div>
                        </div>
                      </Link>
                    </div>
                    <div style={{ textAlign: "center", fontSize: "14px" }}>Make comments sound more like you</div>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "15px", marginBottom: "20px" }}>
                  <div
                    style={{
                      padding: "20px 12px",
                      background: "white",
                      boxShadow: "2px 2px 2px #686262c9",
                      borderRadius: 4,
                      width: "50%",
                    }}
                  >
                    <div>
                      <Link to="/LinkedinHashtagTrend">
                        <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                          <img style={{ height: "20px" }} src="images/hashtag_blue.svg" alt=""></img>
                          <div>Hashtag</div>
                        </div>
                      </Link>
                    </div>
                    <div style={{ textAlign: "center", fontSize: "14px" }}>Monitor any hashtag’s follower growth</div>
                  </div>
                  <div
                    style={{
                      padding: "20px 12px",
                      background: "white",
                      boxShadow: "2px 2px 2px #686262c9",
                      borderRadius: 4,
                      width: "50%",
                    }}
                  >
                    <div>
                      <Link to="/Integrations">
                        <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                          <img style={{ height: "20px" }} src="images/puzzle_blue.svg" alt=""></img>
                          <div>Integrations</div>
                        </div>
                      </Link>
                    </div>
                    <div style={{ textAlign: "center", fontSize: "14px" }}>Use Engage AI on your favorite apps</div>
                  </div>
                </div>
                {state.user?.data?.group === "Member" ? (
                  <div style={{ display: "flex", gap: "15px", marginBottom: "20px" }}>
                    <div
                      style={{
                        padding: "20px 12px",
                        background: "white",
                        boxShadow: "2px 2px 2px #686262c9",
                        borderRadius: 4,
                        width: "50%",
                      }}
                    >
                      <div>
                        <Link to="/LinkSubmit">
                          <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                            <img style={{ height: "20px" }} src="images/boost_blue.svg" alt=""></img>
                            <div>Boost</div>
                          </div>
                        </Link>
                      </div>
                      <div style={{ textAlign: "center", fontSize: "14px" }}>
                        Amplify engagement on your <br></br>LinkedIn post for 5x visibility
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{ borderRadius: "10px", color: "#2F3EAC", padding: "24px", background: "#F2F6FA", width: "30%" }}
              >
                <div style={{ color: "#2F3EAC", fontSize: "14px", fontWeight: 700, marginBottom: "10px" }}>
                  Install Free Extensions
                </div>
                <div
                  style={{
                    padding: "20px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    margin: "0 auto 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://chrome.google.com/webstore/detail/engage-ai/nelhhkchoapcbpcgpmmiahfkcdhgecaf?hl=en-GB&authuser=0"
                    );
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img style={{ height: "20px" }} src="images/engage_blue_filled.svg" alt=""></img>
                    <div>Engage AI</div>
                  </div>
                </div>
                <div
                  style={{
                    padding: "20px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://chromewebstore.google.com/detail/hashtag-analytics-by-enga/ocgihmepobcgbfibaopggddieigbopja"
                    );
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img style={{ height: "20px" }} src="images/lha_icon.svg" alt=""></img>
                    <div>Hashtag Analytics</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ padding: "14px", background: "#8490E2", color: "white", borderRadius: 10, marginBottom: "20px" }}
            >
              <div style={{ marginBottom: "10px", fontWeight: 700 }}>Refer & Earn</div>
              <div>Receive 40% monthly commission with each referred customer.</div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    padding: "14px",
                    background: "#5B6BDA",
                    color: "white",
                    marginLeft: "auto",
                    borderRadius: 4,
                    boxShadow: "2px 2px 2px #686262c9",
                  }}
                >
                  <a
                    href="https://engageai.firstpromoter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit" }}
                  >
                    Start Earning
                  </a>
                </div>
              </div>
            </div>

            <div style={{ borderRadius: "10px", padding: "24px", background: "#F2F6FA" }}>
              <div style={{ color: "#2F3EAC", fontSize: "14px", fontWeight: 700, marginBottom: "10px" }}>
                Quick Guides
              </div>
              <div style={{ color: "#2F3EAC", fontSize: "12px", marginBottom: "10px" }}>
                Discover tips and strategies to get started quickly.
              </div>
              <div style={{ display: "flex", gap: "15px", marginBottom: "20px" }}>
                <div
                  style={{
                    padding: "21px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://engageai.atlassian.net/wiki/spaces/HelpStrategyHub/pages/1081733/Step+1%3A+Account+setup"
                    );
                  }}
                >
                  <div>Sync Account</div>
                </div>
                <div
                  style={{
                    padding: "21px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://engageai.atlassian.net/wiki/spaces/HelpStrategyHub/pages/1769677/Step+2%3A+Create+list%28s%29+for+prospect+monitoring"
                    );
                  }}
                >
                  <div>Prospect List Creation</div>
                </div>
                <div
                  style={{
                    padding: "21px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://engageai.atlassian.net/wiki/x/QgAb");
                  }}
                >
                  <div>Save and Monitor Propects</div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px", marginBottom: "20px" }}>
                <div
                  style={{
                    padding: "21px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://engageai.atlassian.net/wiki/spaces/HelpStrategyHub/pages/1540225/Step+4%3A+Customize+the+AI+for+more+personalized+comments"
                    );
                  }}
                >
                  <div>AI Customization</div>
                </div>
                <div
                  style={{
                    padding: "21px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://engageai.atlassian.net/wiki/x/eQAe");
                  }}
                >
                  <div>Switch Engage AI Plans</div>
                </div>
                <div
                  style={{
                    padding: "21px 12px",
                    background: "white",
                    boxShadow: "2px 2px 2px #686262c9",
                    borderRadius: 4,
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://engageai.atlassian.net/wiki/x/3YAX");
                  }}
                >
                  <div>LinkedIn Account Safety</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size="lg"
        onHide={() => setShowModal(false)}
        dialogClassName={`modal-dialog-centered`}
        aria-labelledby="boost-modal"
      >
        <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
    </div>
  );
};

export default Main;
