import domain from './DomainService';

/**
 * Get the user's accountability data to display on Accountability Page.
 * This is the list of people who have been assigned to comment on this uers's link.
 *
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function InstituteService() {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: 'JWT ' + token
    }
  };
  return fetch(`${domain()}institute`, options);
}
