import React, {useState,useCallback} from 'react';
import styles from './Onboarding.module.css';
import { useNavigate } from 'react-router-dom';
import SaveUserSourceService from '../../Services/SaveUserSourceService';

const logoStyle = {
    width:'165px',
    height:'67px',
}

const circle50 = {
    backgroundImage: `url(/images/circleGroup50.png)`,
    backgroundPosition: '1250px 3px',
    backgroundRepeat: 'no-repeat',
    height:'350px'
  };

const backArrow = (
    <svg width='24' height='24' xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#385A9F" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
    </svg>
)

const UserSourceSurvey = () => {
    const sources = ['Web Search (e.g. Google, Bing, etc.)', 'Referral - LinkedIn', 'Referral - Twitter', 'Other'];
    const [success, setSuccess] = useState(false);
    const [checkedState, setCheckedState] = useState(
        new Array(sources.length).fill(false)
    );
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : false
        );
        setCheckedState(updatedCheckedState);
    }
    const saveUserSource = () => {
        const index = checkedState.findIndex((item)=>{return item === true});
        const source = sources[index];
        if (source === 'Other') {
            let other = document.getElementById("other").value;
            SaveUserSourceService(source, other).then((res)=>{
                if (res.status === 200) {
                    setSuccess(true);
                }
            })
        }
        else {
            let other = '';
            SaveUserSourceService(source, other).then((res)=>{
                if (res.status === 200) {
                    setSuccess(true);
                }
            })
        }
    }
    let history = useNavigate();
    const redirect = useCallback(
        (path) => {
        history.push(path);
        },
        [history]
    );
    
    const prevPage = () => {
        redirect('/Onboarding/CreateAccount')
    }
    return (
        <div className={`${styles['body']}`} style={circle50}>
            <div className={`${styles.logo} ${styles['middle-left']}`}>
                <a href='https://filtpod.com/resources/linkedin-hashtag-trend/' target='__blank'>
                    <img src='/images/fp_logo_darkblue.svg' style={logoStyle} alt='filtpod-logo'></img>
                </a>
                <div style={logoStyle}><p className={`${styles['f-17']}`}>Maximise your reach on LinkedIn</p></div>
            </div>
            <div  className={`${styles['row']}`}>
                <div className={` ${styles['left']} ${styles['left-align']}`}><button onClick={prevPage} className={`${styles['button']}`}>{backArrow}</button></div>
                
                <div className={`${styles.container} ${styles['middle']} ${styles['center-align']}`}>
                <div className={`${styles['title']} ${styles['f-30']} mb-3 mt-5`}>
                        How did you learn about us?
                    </div>
                    <div className={`${styles['ml-auto']}`}>
                        <ul>
                            {sources.map((source,index) => {
                                if (source === 'Other') {
                                    return(
                                        <li key={index}>
                                        <div>
                                            <div className={`${styles['list-item']}`}>
                                            <input
                                                type="checkbox"
                                                id={`custom-checkbox-${index}`}
                                                name={source}
                                                value={source}
                                                checked={checkedState[index]}
                                                onChange={() => handleOnChange(index)}
                                            />
                                            <label htmlFor={`custom-checkbox-${index}`}>{source}</label>
                                            <input type="text" id="other" placeholder="Colleague, Giveaway, ..." className={`${styles['h-35']} ${styles['text-input']} ml-2`}></input>
                                            </div>
                                        </div>
                                        </li>
                                    )
                                }
                                else{
                                    return (
                                        <li key={index}>
                                        <div>
                                            <div className={`${styles['list-item']}`}>
                                            <input
                                                type="checkbox"
                                                id={`custom-checkbox-${index}`}
                                                name={source}
                                                value={source}
                                                checked={checkedState[index]}
                                                onChange={() => handleOnChange(index)}
                                            />
                                            <label htmlFor={`custom-checkbox-${index}`}>{source}</label>
                                            </div>
                                        </div>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                        <button onClick={saveUserSource} className={`${styles['send-button']} ${styles['w-208']}`}>Send</button>
                    </div>
                    {success ? (<div style={{color:"green"}}>Thank you!</div>) : <></>}
                </div>
                <div className={` ${styles['right']} ${styles['right-align']}`}></div>
            </div>
        </div>
    );
}
export default UserSourceSurvey;
