import { Box } from "@mui/material";
import StyledButton from "../../NurtureProspects/ReusableComponents/Button";

const styles = {
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",

    "& h3": {
      fontSize: "24px",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.7)",
      margin: "0",
    },
    "& span": {
      fontSize: "16px",
      fontWeight: "700",
      color: "rgba(10, 114, 195, 1)",
      lineHeight: "22px",
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "22px",
      color: "rgba(0, 0, 0, 0.7)",
      "& strong": {
        fontWeight: "600",
      },
    },
  },
  doneBtn: {
    fontSize: "16px",
    fontWeight: "600",
    border: "2px solid rgba(10, 114, 195, 1)",
    background: "rgba(10, 114, 195, 1)",
    padding: "16px 32px",
    boxShadow: "0px 2px 8px 0px rgba(10, 114, 195, 0.2)",
    height: "42px",
    transition: "all .3s",
    "&:hover": { background: "#085b9c", borderColor: "#085b9c" },
  },
};

function PinExtension({ onDoneClick = () => {}, isNewUser }) {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap="24px" p="24px 24px 0 24px">
      <Box sx={styles.text}>
        <span>{isNewUser ? `Account created 🎉` : `Welcome back 🎉`}</span>
        <h3>Pin the extension 📌</h3>
        <p>
          Pin <strong>Engage AI</strong> to your browser for quick and easy access to your favorite tools.
        </p>
      </Box>
      <StyledButton sx={styles.doneBtn} onClick={onDoneClick}>
        Done
      </StyledButton>
    </Box>
  );
}

export default PinExtension;
