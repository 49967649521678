import domain from "./DomainService";

/**
 * Marks a Submission as complete in the database after the user clicks on the link
 * Uses JWT token to identify user
 *
 * @param {number} id The submission id in the database
 * @return {Promise}
 */

export default function MarkCompleteService(id) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ id: id, pod_id: "TIC1" }),
  };

  return fetch(domain() + "mark-complete", options);
}

/**
 * Marks a Suggestion Box as complete in the database after the user clicks on the checkbox
 * Uses JWT token to identify user
 *
 * @param {number} id The suggestion id for that user in the database (a m2m table)
 * @param {boolean} completedBoolean
 * @return {Promise}
 */

export function MarkSuggestionBoxService(id, completedBoolean) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ id: id, completedBoolean: completedBoolean, pod_id: "TIC1" }),
  };

  return fetch(domain() + "homepage", options);
}
