import React, {useCallback, useEffect, useState} from 'react';
import styles from './Onboarding.module.css';
import { useNavigate } from 'react-router-dom';
import LineChart from '../Homepage/Chart';
import Spinner from '../Spinner/Spinner';
import chartStyles from '../../Components/Homepage/Homepage.module.css';
import GetHashtagsService from '../../Services/GetHashtagsService';

const logoStyle = {
    width:'165px',
    height:'67px',
}

const backArrow = (
    <svg width='24' height='24' xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#385A9F" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
    </svg>
)

const nextArrow = (
    <svg width='24' height='24' xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#385A9F" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
    </svg>
)


const LinkedinHashtagTrendDemo = () => {
    let history = useNavigate();
    const redirect = useCallback(
        (path) => {
        history.push(path);
        },
        [history]
    );
    const [loading, setIsLoading] = useState(true);
    const [topList, setTopList] = useState([]);
    const [topKeyword, setTopKeyword] = useState();
    const [topxValues, setTopxValues] = useState();
    const [topyValues, setTopyValues] = useState();
    const [topfcounts, setTopfcounts] = useState();
    const nextPage = () => {
        redirect('/Onboarding/CreateAccount');
    }
    const prevPage = () => {
        redirect('/Onboarding/HowItWorks');
    }

    useEffect(() => {
        let hashtags = localStorage.getItem("hashtags-data");
        
        if (hashtags) {
            hashtags = JSON.parse(hashtags);
            const topWeekly = hashtags["data"]["sample_hashtags"];
            const topFcount = hashtags["data"]["sample_hashtags"];
            setTopList(topWeekly);
            setTopfcounts(topFcount);
            setIsLoading(false);
        }
        else {
            GetHashtagsService().then((res) => {
                res.json().then((json) => {
                    setTopList(json.data['sample_hashtags']);
                    setTopfcounts(json.data['sample_hashtags']);
                    localStorage.setItem("hashtags-data", JSON.stringify(json));
                    setIsLoading(false);
                }).catch(() => {
                    
                })
            })
        }
    }, []);

    useEffect(() => {
        if (topKeyword) {
            const items = Object.keys(topList[topKeyword]).map(
                (key) => { return [key, topList[topKeyword][key]] }); 
            items.sort(
                (first, second) => { return Date.parse(first[0]) - Date.parse(second[0]) }
            );  
            const sortedDates = items.map(
                (e) => { 
                    const date = new Date(e[0]);
                    return date.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' }) });
            const fcount = items.map(
                (e) => { return e[1] });
            setTopxValues(sortedDates);
            setTopyValues(fcount);
            document.getElementById(topKeyword + "Item").style.background = '#E3AC7A';
            document.getElementById(topKeyword).style.color = '#FFFFFF'; 
        }
          
    }, [topKeyword, topList])

    const handleChange = (e) => {
        
        const id = e.currentTarget.id; 
        if (topKeyword === id){ 
            document.getElementById(id + "Item").style.background = '#E5F0FF';
            document.getElementById(id).style.color = '#385A9F'; 
            setTopKeyword();
        } 
        else if (topKeyword) {
            document.getElementById(topKeyword + "Item").style.background = '#E5F0FF';
            document.getElementById(topKeyword).style.color = '#385A9F';
            setTopKeyword(id);
        }
        else {
            setTopKeyword(id);
        }
    };

    return (
        <div className={`${styles['body']}`}>
            {loading ? <Spinner/> : ''}
            <div className={`${styles.logo} ${styles['middle-left']}`}>
                <a href='https://filtpod.com' target='__blank'>
                    <img src='/images/fp_logo_darkblue.svg' style={logoStyle} alt='filtpod-logo'></img>
                </a>
                <div style={logoStyle}><p className={`${styles['f-14']}`}>Scale your authority, reach and opportunities on LinkedIn</p></div>
            </div>
            <div  className={`${styles['row']}`}>
                <div className={` ${styles['left']} ${styles['left-align']}`}><button onClick={prevPage} className={`${styles['button']}`}>{backArrow}</button></div>
                
                <div className={`${styles.container} ${styles['middle']}`}>
                    <div className={`${styles['f-30']} ${styles['center-align']} ${styles['fw-b']} mb-3`}>A Glimpse of Your Personalised Dashboard</div>
                    <div className={`${styles['f-20']} ${styles['center-align']} mb-3`}>Experience one of many business growth strategies you'll receive through this tool. </div>
                    <div className={` ${styles['center-align']} ${styles['mt-25']}`} >
                        <p>Discover and track promising hashtags with rapid follower growth.</p>
                        <div className={`${styles['hashtag-container']}`}>
                            <div className={`${styles['mt-16']}`}>Click on hashtags ➡</div>
                            {topfcounts ? (  
                                Object.keys(topfcounts).map((key) => {
                                    let item = (<div className={`${chartStyles['hashtag-item']}`} id={key + "Item"}><button className={`${chartStyles['hashtag-button']}`} exclusive id={key} onClick={handleChange}>{'#' + key}</button ></div>);  
                                        return item
                                })
                                
                                ) : (<div className=''></div>)
                            }
                        </div>
                        <div className={`${chartStyles['chart-container']} ${styles['ml-auto']}`}>
                            {typeof topKeyword != "undefined" && topKeyword != null ? (
                                <LineChart xValues={topxValues} yValues={topyValues} keyword={topKeyword}/>
                                ) : (<></>)}
                        </div>
                    </div>
                </div>
                
                <div className={` ${styles['right']} ${styles['right-align']}`}><button onClick={nextPage} className={`${styles['button']}`}>{nextArrow}</button></div>
            </div>
            <div className={` ${styles['center-align']}`}>3/4</div>
        </div>
    );
}
export default LinkedinHashtagTrendDemo;