import { TabContext, TabList } from "@mui/lab";
import { Box, Tab, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { IoMdListBox } from "react-icons/io";
import { MdGroups } from "react-icons/md";
import { BiSolidUserDetail } from "react-icons/bi";

const StyledTab = styled(Tab)(() => ({
  "&": {
    paddingLeft: "4px",
    paddingRight: "48px",
    color: "#1e1e1e",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "20px",
    fontFamily: "Poppins",
    textTransform: "capitalize",
  },
}));

const styles = {
  btn: {
    height: "40px",
    width: "170px",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    paddingBlock: "8px",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "20px",
  },
  leadsBtn: {
    backgroundColor: "#E6E9EC",
    color: "#000",
  },
  updatesBtn: {
    backgroundColor: "#2F3EAC",
    color: "white",
  },
  icon: {
    width: "18px",
    height: "18px",
  },
  tabIcon: {
    width: "24px",
    height: "24px",
  },
  engageBtn: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    textDecoration: "underline",
    textTransform: "none",
    padding: "8px 16px",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
};

function Index({ activeTab, setActiveTab }) {
  const navigate = useNavigate();

  const handleTabChange = async (_, newVal) => {
    setActiveTab(newVal);
    navigate(newVal);
  };

  return (
    <TabContext value={activeTab}>
      <Box
        sx={{
          marginBottom: "1rem",
          borderBottom: "2px solid #edeff2",
          display: "flex",
          alignItems: "center",
          maxHeight: "60px",
        }}
      >
        <Box flexGrow={1}>
          <TabList
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "#2f3eac" } }}
            sx={{
              "& button.Mui-selected": {
                color: "#2f3eac",
              },
            }}
          >
            <StyledTab
              label={
                <Box display="flex" alignItems="center" gap=".5rem">
                  <IoMdListBox style={styles.tabIcon} />
                  <span>Posts</span>
                </Box>
              }
              value="posts"
              sx={styles.tab}
            />
            <StyledTab
              label={
                <Box display="flex" alignItems="center" gap=".5rem">
                  <MdGroups style={styles.tabIcon} />
                  <span>Prospects</span>
                </Box>
              }
              value="prospects"
              sx={styles.tab}
            />
            <StyledTab
              label={
                <Box display="flex" alignItems="center" gap=".5rem">
                  <BiSolidUserDetail style={{ ...styles.tabIcon, transform: "rotateY(180deg)" }} />
                  <span>Segments</span>
                </Box>
              }
              value="lists"
              sx={{ "& button": { background: "red" } }}
            />
          </TabList>
        </Box>
      </Box>
    </TabContext>
  );
}
export default Index;
