import React, { useState } from "react";
import styles from "./Topbar.module.css";
import SendFeedbackService from "../../Services/FeedbackService";
import { Box } from "@mui/material";

const Feedback = () => {
  const [beContacted, setBeContacted] = useState(true);
  const [recommendationScale, setRecommendationScale] = useState();
  const [response, setResponse] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    const t = localStorage.getItem("user");
    const u = JSON.parse(t);
    const formValues = {
      date: new Date(),
      name: u.firstName + " " + u.lastName,
      email: u.email,
      "important feature request": document.getElementById("feature-request1").value,
      "nice to have feature": document.getElementById("feature-request2").value,
      "how likely to recommend FILT Pod": recommendationScale,
      "can we contact you": beContacted,
    };
    console.log(formValues);
    SendFeedbackService(formValues)
      .then((res) => {
        console.log(res);
        setResponse("Thank you for your feedback!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (event) => {
    event.persist();
    console.log(event.target.value, event.target.checked, event.target.name);
    if (event.target.name.includes("scale")) {
      setRecommendationScale(event.target.value);
    } else if (event.target.name.includes("no") && beContacted) {
      setBeContacted(false);
    } else if (event.target.name.includes("yes") && !beContacted) {
      setBeContacted(true);
    }
  };

  return (
    <div>
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <Box className="headerText" mb="1.5rem">
            <Box display="flex" alignItems="center" gap="8px">
              <h1>Feedback</h1>
            </Box>
        </Box>
        <div style={{ flex: "1" }}>
          {/* {loading ? <Spinner className="spinner-container" /> : ""} */}
          <div>
            <div className={` mt-5 col-md-7 ml-2`}>
              <form onSubmit={handleSubmit} style={{ fontWeight: "400" }}>
                <div className="mt-4">
                  <label htmlFor="feature-request1">
                    1. This is really important. I need Engage AI to do this.....
                  </label>
                  <textarea id="feature-request1" name="feature-request" rows="4" cols="100" required></textarea>
                </div>

                <div className="mt-4">
                  <label htmlFor="feature-request2">
                    2. This would be nice to have. I wish that Engage AI could do this.....
                  </label>
                  <textarea id="feature-request2" name="feature-request" rows="4" cols="100"></textarea>
                </div>

                <div className="mt-4">
                  <label htmlFor="likely-to-recommend">
                    3. On a scale of one to ten, how likely would you be to recommend us to friends or business
                    associates?
                  </label>
                  <div className="row" style={{ alignItems: "center", gap: "12px" }}>
                    <div>Very unlikely</div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale1"
                        name="scale1"
                        value={1}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "1" ? true : false}
                      />
                      <label htmlFor="scale1" style={{ margin: "auto" }}>
                        1
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale2"
                        name="scale2"
                        value={2}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "2" ? true : false}
                      />
                      <label htmlFor="scale2" style={{ margin: "auto" }}>
                        2
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale3"
                        name="scale3"
                        value={3}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "3" ? true : false}
                      />
                      <label htmlFor="scale3" style={{ margin: "auto" }}>
                        3
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale4"
                        name="scale4"
                        value={4}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "4" ? true : false}
                      />
                      <label htmlFor="scale4" style={{ margin: "auto" }}>
                        4
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale5"
                        name="scale5"
                        value={5}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "5" ? true : false}
                      />
                      <label htmlFor="scale5" style={{ margin: "auto" }}>
                        5
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale6"
                        name="scale6"
                        value={6}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "6" ? true : false}
                      />
                      <label htmlFor="scale6" style={{ margin: "auto" }}>
                        6
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale7"
                        name="scale7"
                        value={7}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "7" ? true : false}
                      />
                      <label htmlFor="scale7" style={{ margin: "auto" }}>
                        7
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale8"
                        name="scale8"
                        value={8}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "8" ? true : false}
                      />
                      <label htmlFor="scale8" style={{ margin: "auto" }}>
                        8
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale9"
                        name="scale9"
                        value={9}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "9" ? true : false}
                      />
                      <label htmlFor="scale9" style={{ margin: "auto" }}>
                        9
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="scale10"
                        name="scale10"
                        value={10}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={recommendationScale === "10" ? true : false}
                      />
                      <label htmlFor="scale10" style={{ margin: "auto" }}>
                        10
                      </label>
                    </div>
                    <div>Very likely</div>
                  </div>
                </div>

                <div className="mt-4">
                  <label htmlFor="contact">4. Can we contact you to get deeper insights?</label>
                  <div className="row" style={{ alignItems: "center", gap: "12px" }}>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="yes"
                        name="yes"
                        value={true}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={beContacted ? true : false}
                      />
                      <label htmlFor="yes" style={{ margin: "auto" }}>
                        Yes
                      </label>
                    </div>
                    <div className="row" style={{ alignItems: "center", gap: "5px" }}>
                      <input
                        type="checkbox"
                        id="no"
                        name="no"
                        value={false}
                        className={`${styles["checkbox-round"]}`}
                        style={{ borderRadius: "18px" }}
                        onChange={handleChange}
                        checked={!beContacted ? true : false}
                      />
                      <label htmlFor="no" style={{ margin: "auto" }}>
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <button className={`${styles["submit-button"]} `} style={{ fontWeight: "400", marginTop: "30px" }}>
                  Submit
                </button>
              </form>
            </div>
            <div>{response}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
