import mixpanel from 'mixpanel-browser';
import React from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar';

mixpanel.init("c4f100876679806ed51594b9ff32c3f9", { debug: true, persistence: 'localStorage' });

const OptimizeYourNurturingEfforts = () => {
    const trackEvent = () => {
        mixpanel.track(
            "Onboarding",
            {
                "origin": "Optimize Your Nurturing Efforts",
                "navigate": "Nurture To Improve Conversion"
            }
        );
    }
    return (
        <div >
        <div >
            <Topbar/>
            <div className={`${styles['main']} ${styles['center-align']}`}>
                <h3 style={{color:"#2F3EAC"}}>Optimize Your Nurturing Efforts</h3>
                <p><b>– using targeted lists.</b></p>
                <div className={`${styles['frow']} `} style={{justifyContent:"normal"}}>
                    <div style={{width:"100%"}}><iframe width="800" height="500" src="https://www.youtube.com/embed/yLoIAIsFTQk?si=8olpqZ4yv8acNJth&autoplay=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
                </div>
                <div style={{width:"max-content", padding:"12px 16px", color:"white", background:"#3947A8", borderRadius:"8px", margin:"auto"}} >
                    <a href="/Onboarding/EngageAI/NurtureToImproveConversion" style={{color:"white", textDecoration:"none"}} onClick={trackEvent}>Next <img src='/images/right_arrow_white.svg' alt=''></img></a>
                </div>
            </div>
        </div>
        </div>
    );
}
export default OptimizeYourNurturingEfforts;