import React, { useState } from "react";
import Spinner from "../Spinner/Spinner";
import ChangeEmailService from "../../Services/ChangeEmail";
//mv delete file and folder, because it doesn't look like we're using this anymore

const ChangeEmail = () => {
  const [loading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formValues, setFormValues] = useState({
    newemail: "",
  });
  const [responseValues, setResponseValues] = useState({
    response: { message: "" },
  });

  const handleChange = (event) => {
    event.persist();
    setFormValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    // Check if new passwords match
    event.preventDefault();
    if (formValues.newemail !== formValues.oldemail) {
      setResponseValues({
        message: "Please provide a valid email address.",
      });
      return;
    }

    ChangeEmailService(formValues).then((res) => {
      if (res.status === 200) {
        res.json().then((resetJSON) => {
          setResponseValues({
            message: resetJSON.message,
          });
          setFormValues({
            newemail: "",
          });
          setSuccess(true);

          const token = resetJSON.token;
          const tokenParts = token.split(/\./);
          const tokenDecoded = JSON.parse(window.atob(tokenParts[1]));

          localStorage.setItem("userName", tokenDecoded.username);
          localStorage.setItem("token", token);
        });
      } else if (res.status === 400)
        res.json().then((resetJSON) => {
          setResponseValues({
            message: resetJSON.message,
          });
          setSuccess(false);
        });
    });
  };

  return (
    <div>
      <div className="row">
        <div className="diplay-container col-8 mt-5">
          {loading ? <Spinner className="spinner-container" /> : ""}
          <div>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-8">
                  <label htmlFor="lastname">Old Email</label>
                  <p>{localStorage.getItem("userName")}</p>
                  <label htmlFor="lastname">New Email</label>
                  <input
                    className="form-control"
                    placeholder="janedoe@gmail.com"
                    type="email"
                    id="newemail"
                    name="newemail"
                    onChange={handleChange}
                    autoComplete="new-email"
                    value={formValues.newemail}
                  />
                </div>
              </div>
              <div>
                <p style={success ? { color: "green" } : { color: "#A91212" }}>{responseValues.message}</p>
              </div>
              <button className="btn-sm btn btn-primary mt-3">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangeEmail;
