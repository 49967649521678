import StyledButton from "../../ReusableComponents/Button";

import { Box, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    textAlign: "center",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },
};

function SyncErrAfterAdd({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
          width: "450px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Box sx={styles.Modal}>
          <img style={{ width: "100%" }} src="/images/suceessToneUpdate.png" alt="sucess img" />
          <Box sx={{ padding: "24px" }} display="flex" flexDirection="column" alignItems="center" gap="1rem">
            <h2>Please sync your account</h2>
            <Typography>
              To add a prospect, sync your account using the <strong>"Sync Account"</strong> button in the Engage AI
              widget.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: "100%" }} display="flex" justifyContent="center" px="16px" py="16px">
          <StyledButton onClick={onClose}>
            <IoClose />
            <span>Close</span>
          </StyledButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SyncErrAfterAdd;
