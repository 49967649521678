import React, { useState, useContext } from "react";
import Spinner from "../Spinner/Spinner";
import CutoffTimeComponent from "./CutoffTIme";
import styles from "./LinkSubmitPage.module.css";
import { Context } from "../../Store/Store";
import { deleteLinkDataLocalStorage, deleteGeneratedResponsesLocalStorage } from "../../Scripts/getSetLocalStorage";
import LinkSubmitForm from "./LinkSubmitForm";

const timeIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.098 10.634L11 9.423V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4C9.73479 4 9.48043 4.10536 9.2929 4.29289C9.10536 4.48043 9 4.73478 9 5V10C9.00001 10.1755 9.04622 10.348 9.13399 10.5C9.22175 10.652 9.34799 10.7782 9.5 10.866L12.098 12.366C12.2118 12.4327 12.3376 12.4762 12.4683 12.4941C12.5989 12.512 12.7319 12.5038 12.8594 12.4701C12.9869 12.4364 13.1064 12.3778 13.2112 12.2977C13.3159 12.2176 13.4038 12.1175 13.4698 12.0033C13.5357 11.8891 13.5784 11.763 13.5954 11.6322C13.6124 11.5014 13.6034 11.3686 13.5688 11.2413C13.5343 11.114 13.4749 10.9949 13.3941 10.8906C13.3133 10.7864 13.2127 10.6992 13.098 10.634ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34873 18.9426 4.80688 17.0679 2.93215C15.1931 1.05742 12.6513 0.0029116 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9974 12.1209 17.1537 14.1542 15.6539 15.6539C14.1542 17.1536 12.1209 17.9974 10 18Z"
      fill="#315DDA"
    />
  </svg>
);

const LinkSubmitPageNew = () => {
  const [loading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  //const [tzTime, setTzTime] = useState({ sf: "", ny: "", br: "" });
  const [state, dispatch] = useContext(Context);
  const t = localStorage.getItem("user");
  const u = JSON.parse(t);

  const checkEnabled = (cutoff_datetime, now, paymentEnabled, post_status) => {
    // Disabled: accepting_for_day day is equal to today's day AND current time is greater than cutoff_datetime_utc
    // Disabled: accepting_for_day day is not equal to today's day
    // Enabled: accepting_for_day day is equal to today's day AND current time is less than cutoff_datetime_utc
    // Disable form input if the user is not paying
    // Disable if user has not completed all assignments or in cooling period
    if (post_status !== "unlocked") {
      setIsEnabled(false);
      return;
    }
    if (paymentEnabled === false) {
      if (isEnabled === true) {
        setIsEnabled(false);
      }
      return;
    }
    console.log("cutoff time: ", cutoff_datetime, "current time: ", now);
    if (cutoff_datetime > now && cutoff_datetime.getTime() - now.getTime() < 60 * 60 * 1000) {
      setIsEnabled(true);
      return;
    } else {
      setIsEnabled(false);
      if (cutoff_datetime.getUTCDate() !== now.getUTCDate()) {
        // Delete saved link if past
        // If there is data in the currentSubmittedLink state clear it
        dispatch({ ...state.currentSubmittedLink.data, dateSubmitted: "", link: "", pod: "" });
        // Clear localstorage link
        deleteLinkDataLocalStorage();
        deleteGeneratedResponsesLocalStorage();
      }
      return;
    }
  };

  const podSchedule = () => {
    // Define table data for 4 rows and 5 columns
    const tableDataAPAC = [
      ['Brisbane', 'Mon, Wed & Fri', '7:25 AM', '8:25 AM', 'AEST (UTC+10)'],
      ['Sydney / Melbourne', 'Mon, Wed & Fri', 'Standard Time 7:25 AM / Daylight Saving 8:25 AM', 'Standard Time 8:25 AM / Daylight Saving 9:25 AM',  'AEST (UTC+10) / AEDT (UTC+11)'],
      ['New York', 'Sun, Tue, & Thu', 'Standard Time 4:25 PM / Daylight Saving 5:25 PM', 'Standard Time 5:25 PM / Daylight Saving 6:25 PM', 'EST (UTC-5) / EDT (UTC-4)'],
      ['San Francisco', 'Sun, Tue, & Thu', 'Standard Time 1:25 PM / Daylight Saving 2:25 PM', 'Standard Time 2:25 PM / Daylight Saving 3:25 PM', 'PST (UTC-8) / PDT (UTC-7)'],
    ];
    const tableDataEU = [
      ['UK', 'Mon, Wed & Fri', 'Standard Time 7:55 AM / Daylight Saving 8:55 AM', 'Standard Time 8:55 AM / Daylight Saving 9:55 AM', 'GMT (GMT+0) / BST (GMT+1)'],
      ['Germany, Sweden, Austria', 'Mon, Wed & Fri', 'Standard Time 8:55 AM / Daylight Saving 9:55 AM', 'Standard Time 9:55 AM / Daylight Saving 10:55 AM',  'CET (GMT+1) / CEST (GMT+2)'],
      ['Ukraine', 'Mon, Wed & Fri', 'Standard Time 9:55 AM', 'Standard Time 10:55 AM', 'EET (GMT+2)'],
      ['Hong Kong, Singapore', 'Mon, Wed & Fri', '3:55 PM', '4:55 PM', 'HKT / SGT (UTC+8)'],
      ['Brisbane', 'Mon, Wed & Fri', '5:55 PM', '6:55 PM', 'AEST (UTC+10)'],
      ['Sydney,  Melbourne', 'Mon, Wed & Fri', 'Standard Time 5:55 PM / Daylight Saving 6:55 PM', 'Standard Time 6:55 PM / Daylight Saving 7:55 PM', 'AEST (UTC+11) / AEDT (UTC+11)']
    ];
    const tableDataUS = [
      ['New York, Washington DC, Montreal, Toronto', 'Mon, Wed & Fri', 'Standard Time 7:25 AM / Daylight Saving 8:25 AM', 'Standard Time 8:25 AM / Daylight Saving 9:25 AM', 'EST (UTC-5) / EDT (UTC-4)'],
      ['UK', 'Mon, Wed & Fri', 'Standard Time 8:25 AM / Daylight Saving 9:25 AM', 'Standard Time 1:25 PM / Daylight Saving 2:25 AM',  'GMT (GMT+0) / BST (GMT+1)'],
      ['Germany, Sweden, Austria', 'Mon, Wed & Fri', 'Standard Time 2:25 PM / Daylight Saving 3:25 PM', 'Standard Time 3:25 PM / Daylight Saving 4:25 PM', 'CET (GMT+1) / CEST (GTM+2)']
    ];
  
    return (
      <div>
        <table border="1" style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black'}}>
          <thead>
            <tr>
              <th>City</th>
              <th>Days</th>
              <th>Submission Opening Time</th>
              <th>Submission Cut-Off Time</th>
              <th>Time Zone</th>
            </tr>
          </thead>
          <tbody>
          {tableDataAPAC.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        </table>
        <div className={`mt-2`}><b>Notes:</b></div>
        <ul style={{ listStyleType: 'disc'}}>
          <li><b>Sydney/Melbourne:</b>
            <ul style={{ listStyleType: 'circle'}}>
              <li><b>Daylight Saving Time (AEDT)</b> is observed from the <b>first Sunday in October</b> to the <b>first Sunday in April</b>.</li>
              <li><b>Standard Time (AEST)</b> applies outside these dates.</li>
              <li>Adjust your submission times accordingly.</li>
            </ul>
          </li>
          <li><b>New York and San Francisco:</b>
            <ul style={{ listStyleType: 'circle'}}>
              <li><b>Daylight Saving Time</b> in the U.S. is observed from the <b>second Sunday in March</b> to the <b>first Sunday in November</b>.</li>
              <li><b>Standard Time</b> applies outside these dates.</li>
            </ul>
          </li>
          <li><b>Overlap Periods:</b>
            <ul style={{ listStyleType: 'circle'}}>
              <li>Be aware that daylight saving changes may not align between regions, affecting time differences.</li>
            </ul>
          </li>
          <li><b>Local Dates:</b>
            <ul style={{ listStyleType: 'circle'}}>
              <li>Days correspond to local dates in each city (e.g., if it's Monday in Sydney, it's Sunday in New York).</li>
            </ul>
          </li>
        </ul>
        <div className={`mt-4`}>
          <div>Below is the schedule for the <b>UK, Europe</b> and <b>APAC</b> regions:</div>
          <table border="1" style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black'}}>
            <thead>
              <tr>
                <th>City</th>
                <th>Days</th>
                <th>Submission Opening Time</th>
                <th>Submission Cut-Off Time</th>
                <th>Time Zone</th>
              </tr>
            </thead>
            <tbody>
              {tableDataEU.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className={`mt-2`}><b>Notes:</b></div>
            <ul style={{ listStyleType: 'disc'}}>
              <li><b>United Kingdom:</b>
                <ul style={{ listStyleType: 'circle'}}>
                  <li><b>Daylight Saving Time (British Summer Time - BST)</b> is observed from the <b>last Sunday in March</b> to the <b><label htmlFor=""></label>last Sunday in October</b>.</li>
                  <li><b>Standard Time (Greenwich Mean Time - GMT)</b> applies outside these dates.</li>
                  <li>Adjust your submission times accordingly.</li>
                </ul>
              </li>
              <li><b>Germany, Sweden, and Austria:</b>
                <ul style={{ listStyleType: 'circle'}}>
                  <li><b>Daylight Saving Time (Central European Summer Time - CEST)</b> in the U.S. is observed from the <b>last Sunday in March</b> to the <b><label htmlFor=""></label>last Sunday in October</b>.</li>
                  <li><b>Standard Time (Central European Time - CET)</b> applies outside these dates.</li>
                  <li>Adjust your submission times accordingly.</li>
                </ul>
              </li>
            </ul>
          </div>
        <div className={`mt-4`}>
          <div>Below is the schedule for the <b>USA East Coast, UK</b> and <b>Europe</b> regions:</div>
          <table border="1" style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black'}}>
            <thead>
              <tr>
                <th>City</th>
                <th>Days</th>
                <th>Submission Opening Time</th>
                <th>Submission Cut-Off Time</th>
                <th>Time Zone</th>
              </tr>
            </thead>
            <tbody>
              {tableDataUS.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className={`mt-2`}><b>Notes:</b></div>
            <ul style={{ listStyleType: 'disc'}}>
              <li><b>New York and San Francisco:</b>
                <ul style={{ listStyleType: 'circle'}}>
                  <li><b>Daylight Saving Time</b> in the U.S is observed from the <b>second Sunday in March</b> to the <b>first Sunday in November</b>.</li>
                  <li><b>Standard Time</b> applies outside these dates.</li>
                </ul>
              </li>
              <li><b>United Kingdom:</b>
                <ul style={{ listStyleType: 'circle'}}>
                  <li><b>Daylight Saving Time (British Summer Time - BST)</b> is observed from the <b>last Sunday in March</b> to the <b>last Sunday in October</b>.</li>
                  <li><b>Standard Time (Greenwich Mean Time - GMT)</b> applies outside these dates.</li>
                  <li>Adjust your submission times accordingly.</li>
                </ul>
              </li>
              <li><b>Germany, Sweden, and Austria:</b>
                <ul style={{ listStyleType: 'circle'}}>
                  <li><b>Daylight Saving Time (Central European Summer Time - CEST)</b> is observed from the <b>last Sunday in March</b> to the <b>last Sunday in October</b>.</li>
                  <li><b>Standard Time (Central European Time - CET)</b> applies outside these dates.</li>
                  <li>Adjust your submission times accordingly.</li>
                </ul>
              </li>
            </ul>
          </div>
      </div>
    );
  };

  return (
    <div>
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div style={{ flex: "1" }}>
          {loading ? <Spinner className="spinner-container" /> : ""}
          <div className={`${styles["background"]}`}>
            <div className={`${styles["main"]} mt-5 `}>
              <div className="row  justify-content-between ">
                <div>
                  <p className={`${styles["title"]} h4 mb-5`}>Submit a link</p>
                </div>
              </div>
              <div className="row">
                <div className={`${styles["dot"]} d-flex align-content-center flex-wrap justify-content-center`}>
                  {timeIcon}
                </div>
                <div className="ml-4">
                  <div>
                    <p className={`${styles["title"]} h4 `}>Time till next submission</p>
                  </div>
                  <CutoffTimeComponent
                    checkEnabled={checkEnabled}
                    isEnabled={isEnabled}
                    //setTzTime={setTzTime}
                    setIsLoading={setIsLoading}
                    podID={u.mainPod}
                  />
                  {/**<div className={`${styles["notification"]}`} onClick={displayNotification}>
                    <p>
                      <span role="img" aria-label="finger-pointing-right">
                        &#128073;
                      </span>{" "}
                      <b>Schedule 5-Minute Reminder:</b> Submit and fuel your LinkedIn post with engagement on time.
                      <span role="img" aria-label="finger-pointing-left">
                        &#128072;
                      </span>
                    </p>
                  </div>**/}
                </div>
              </div>
              <div className="ml-4 mt-4">
                <LinkSubmitForm isEnabled={isEnabled} podID={u.mainPod} subgroup={u.podSubgroup}/>
              </div>
              <div>
                <div className={`${styles["container-vertial-box"]} ml-4`}>
                  <div className="ml-4">
                    <div className={`${styles["box-text-title"]} mt-3 mb-4`}>Guidelines for Using Our LinkedIn Engagement Pod</div>
                    <div className={`mb-4`}>To make the most of our LinkedIn engagement pod, please follow these instructions:</div>
                    <ol className={`${styles["body-text"]} ml-4`}>
                      <li className="mb-4">
                        <b>Post Ownership</b> <br></br>
                        - Only submit posts that are published on <b>your personal LinkedIn profile</b> or <b>your company's LinkedIn page</b>. <br></br>
                        - Do not submit content that belongs to others or is posted elsewhere. <br></br>
                      </li>
                      <li className="mb-4">
                        <b>No External Links</b> <br></br>
                        - Ensure your post does <b>not include any external links</b>, such as YouTube videos, blog articles, links to external websites <br></br>
                        - Keep the content native to LinkedIn for better engagement.
                      </li>
                      <li className="mb-4">
                        <b>Timely Submission</b> <br></br>
                        - Submit the URL of your post <b>within one hour of publishing</b> it on LinkedIn.<br></br>
                        - Posts older than one hour are not eligible for the engagement pod.<br></br>
                      </li>
                      <li className="mb-4">
                        <b>Submission Deadlines</b> <br></br>
                        - Make sure to submit your post URL <b>before the cutoff time</b> for your chosen session.<br></br>
                        - Late submissions are not accepted; you'll need to wait until the next session's submission window opens.<br></br>
                      </li>
                      <li className="mb-4">
                        <b>Engagement Sessions</b> <br></br>
                        - The engagement pod operates <b>three days a week</b> to accommodate different time zones.<br></br>
                        - Choose the session that best fits your location for optimal engagement.<br></br>
                      </li>
                      <li className="mb-4">
                        <div>Below is the schedule for the <b>APAC</b> and <b>North America</b> regions:</div>
                        {podSchedule()}
                      </li>
                    </ol>
                    <div>By following these guidelines and adhering to the schedule, you'll enhance the visibility of your LinkedIn posts and engage effectively with our community.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkSubmitPageNew;
