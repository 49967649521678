import domain from "./DomainService";

/**
 * Triggers email to be sent to the user with a link to reset their password
 * Uses JWT token to identify user
 *
 * @param {string} email user's email
 * @return {Promise}
 */
export default function ForgotPasswordService(email) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({ email: email }),
  };
  return fetch(domain() + "password-reset/", options);
}
