import React, { useState, useEffect } from 'react';
import styles from '../Onboarding/Onboarding.module.css';
import loginStyles from '../Login/LoginPage.module.css';

const PaymentFail = ()=> {
    const [pauseImage, setPauseImage] = useState(false);
    const handleMouseEnter = () => {
      setPauseImage(true);
    }
    const handleMouseLeave = () => {
      setPauseImage(false);
      setCurrentIndex(currentIndex + 1);
    }

    const changeImage = (e) => {
      let image = e.currentTarget.id;
      if (image === "lead_monitoring"){
        setCurrentIndex(1);
      }
      else {
        setCurrentIndex(0);
      }
    };
    
  const hashtag_trends = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/1_hashtag.svg' alt='FILT Pod'></img></div>
          <div style={{color:"white", textAlign:"center", height:"500px"}}>
            <div style={{height:"90px", marginBottom:"40px"}}>
              <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                <div>Seize LinkedIn hashtags with rapid follower growth for more content exposure by monitoring their behavioral trends.</div>
              </div>
            </div>
            <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
              <img src='/images/hashtag_trends_description_small.jpg' alt='Hashtag Trends'></img>
            </div>
          </div>
          <div >
            <div style={{width:"max-content", margin:"auto"}} className='row'>
              <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
              <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
              
            </div>
          </div>
        </div>
    </div>
)

const lead_monitoring = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/2_engage.svg' alt='FILT Pod'></img></div>
            <div style={{color:"white", textAlign:"center", height:"500px"}}>
              <div style={{height:"90px", marginBottom:"40px"}}>
                <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                Use AI to write insightful comments, saving hours of your time by engaging and increasing touchpoints with multiple LinkedIn leads for faster conversions.
                </div>
              </div>
              <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
                <img src='/images/lead_monitoring.jpg' alt='Lead Monitoring'></img>
              </div>
            </div>
            <div >
              <div style={{width:"max-content", margin:"auto"}} className='row'>
                <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
                <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
                
              </div>
            </div>
        </div>
    </div>
)

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pauseImage) {
        return
      }
      if (currentIndex >= 1) {
        setCurrentIndex(0)
      }
      else {
        setCurrentIndex(currentIndex + 1)
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, pauseImage]);
 
  return (
    <div className={`${loginStyles.main} flex-nowrap `}>
      <div className={`${styles.row}`}>
        {currentIndex === 1 ? lead_monitoring : hashtag_trends}
        <div className={`${loginStyles.rightbackground} col-6 `}>
            <div className='form-row d-flex justify-content-center'>
                <div className='col-md-8 mt-3' style={{ textAlign:"center"}}>
                    <div style={{fontSize:"24px", fontWeight:"600", lineHeight:"36px", color:"red"}}>Payment Cancelled</div>
                    <div className='mt-3'>If you are having trouble, please email <a href="mailto:hello@engage-ai.co">hello@engage-ai.co</a>.</div>
                    <div style={{marginTop:"40px"}}><span><img src='/images/back-arrow.svg' alt='back to login'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/Upgrade'>Payment Page</a></span></div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  );
};
export default PaymentFail;