import { Link } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import StyledButton from "../../ReusableComponents/Button";

import { MdDiamond } from "react-icons/md";
import LaunchIcon from "@mui/icons-material/Launch";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "5000px",
    overflow: "hidden",
    "& *": {
      margin: " 0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#415058",
      lineHeight: "24px",
      marginBottom: "5px",
      "& span": {
        fontSize: "14px",
        fontWeight: "700",
        color: "#1F292E",
      },
    },
  },
  topBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: "linear-gradient(0deg, #D79F3F 0%, #EAAB3E 100%)",

    paddingBlock: "16px",
    "& svg": {
      width: "75px",
      height: "75px",
      color: "white",
    },
  },
  list: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    listStyleImage: "url('/images/checkmark.png')",
    paddingLeft: "20px",
    margin: "0",
    paddingBlock: "32px",
    borderTop: "1px solid #E0E2E4",
    borderBottom: "1px solid #E0E2E4",

    "& li": {
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "22px",
      color: "#415058",
    },
  },
  listTitle: {
    position: "absolute",
    top: "-12px",
    left: "50%",
    transform: "translateX(-50%)",
    paddingInline: "16px",
    background: "#fff",
    zIndex: "1",
    fontSize: "14px",
    fontWeight: "600",
    color: "#415058",
    lineHeight: "22px",
  },
};

function UpgradeModal({ onCloseModal }) {
  return (
    <Box sx={styles.Modal}>
      <Box sx={styles.topBox}>
        <MdDiamond />
      </Box>
      <Box sx={{ padding: "32px 24px" }} display="flex" flexDirection="column" alignItems="center" gap="24px">
        <h3>Upgrade required</h3>
        <Typography>
          Free accounts are limited to a <strong>weekly</strong> automatic update check. <br />
          Upgrade to a paid plan to check for new posts anytime.
        </Typography>
        <Box>
          <ul style={styles.list}>
            <span style={styles.listTitle}>Paid plans advantages</span>
            <li>
              <strong>Never miss a post</strong> with <strong> daily</strong> automatic and <strong>anytime</strong>{" "}
              updates,
            </li>
            <li>
              <strong>Monitor more leads</strong> with bulk import or CRM integration,
            </li>
            <li>
              Unlock more additional advanced features.&nbsp;
              <a
                href="https://bit.ly/3xdvInj"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontWeight: "600",
                  color: "var(--color-brand)",
                }}
              >
                <span>Learn more</span>
                <LaunchIcon style={{ width: "15px", height: "15px", marginLeft: "4px" }} />
              </a>
            </li>
          </ul>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
          <Link to="https://bit.ly/3xdvInj" target="_blank" rel="noopener noreferrer">
            <StyledButton size="medium" variation="warning">
              Upgrade Now
            </StyledButton>
          </Link>
          <StyledButton variation="noBackground" onClick={onCloseModal}>
            Maybe later
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
}

export default UpgradeModal;
