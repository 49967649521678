import React from 'react';
import styles from './Onboarding.module.css';

const Topbar = () => {

  return (
    <div className={`${styles['frow']} `} style={{padding:"10px 120px", background:"#F2F6FA"}}>
        <div>
          <a href='https://engage-ai.co/'>
          <img style={{width:'40px'}} src='/images/engage_ai_logo.svg' alt='engageai'></img>
          <span style={{marginLeft:"10px"}}>Engage AI</span>
          </a>
          
        </div>
    </div>
  );
};
export default Topbar;
