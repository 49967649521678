import domain from "./DomainService";

/**
 * Save user source from LHA onboarding page
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function SaveUserSourceService(source, other) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({ source: source, other: other}),
  };

  return fetch(domain() + "save-user-source", options);
}
