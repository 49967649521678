/**
 * Sets user data into local storage
 * @param {object} data of the form {"first_name", "last_name", "email", "linkedin"} 3/15/21
 * @param {string} token jwt token
 */
export const setUserDataLocalStorage = (data, token) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(data));
};

/**
 * Sets user data into local storage
 * @param {object} data of the form {"link", "dateSubmitted", "pod"} 3/15/21
 */
export const setLinkDataLocalStorage = (data) => {
  localStorage.setItem("link", JSON.stringify(data));
};

export const setStatsLocalStorage = (data) => {
  localStorage.setItem("stats", JSON.stringify(data));
};

export const setGeneratedResponsesLocalStorage = (responsesArray) => {
  localStorage.setItem("generated-responses", JSON.stringify(responsesArray));
};

export const deleteLinkDataLocalStorage = () => {
  localStorage.removeItem("link");
};

export const deleteGeneratedResponsesLocalStorage = () => {
  localStorage.removeItem("generated-responses");
};

export const deleteLocalStorage = () => {
  localStorage.removeItem("link");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("stats");
};
