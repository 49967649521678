import { Avatar, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/material";

import { RiDeleteBin6Fill } from "react-icons/ri";
import StyledButton from "../../ReusableComponents/Button";
import StyledCheckbox from "../../ReusableComponents/StyledCheckbox";
import toast from "react-hot-toast";
import { ReactComponent as CheckboxIcon } from "./chckIcon.svg";
import { ReactComponent as CheckboxCheckedIcon } from "./chckedIcon.svg";
import { useState } from "react";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },

  cellProps: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#1E1E1E",
  },
  Paper: {
    border: "1px solid #D1D9E2",
    borderRadius: "10px",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    padding: "0",
  },
  headerCell: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    color: "#1F292E",
    border: "none",
  },
  unselectBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    background: "#FBE9E9",
    borderRadius: "8px",
    marginTop: "20px",
    "& span": {
      "&:first-child": {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "500",
        color: "#5F2120",
      },
      "&:last-child": {
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: "600",
        color: "#D61F1F",
        cursor: "pointer",
        padding: "4px 8px",
      },
    },
  },
};

function RemoveInactivesDialog({ onCloseModal, setShowSuccess, inactives, checked, setChecked, onDelete }) {
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSaving(true)
      const prospectIds = checked.map((c) => c.id);
      await onDelete(prospectIds);
      setShowSuccess(true);
    } catch {
      toast.error("Error removing prospects");
    } finally {
      setIsSaving(false)
    } 
  };

  const handleToggle = (prospect) => {
    const newChecked = [...checked];
    const index = checked.findIndex((c) => c.id === prospect.id);

    if (index === -1) {
      newChecked.push(prospect);
    } else {
      newChecked.splice(index, 1);
    }
    setChecked(newChecked);
  };

  return (
    <Box sx={styles.Modal}>
      <Box display="flex" alignItems="center" gap="10px" mb={2}>
        <img src="/images/pageInfo.svg" alt="Settings icon" style={{ height: "24px", width: "24px" }} />
        <h2>Remove inactive prospects</h2>
      </Box>
      <Box>
        <p>Here are the inactive profiles we found in your prospects lists. Select the ones you want to remove.</p>
      </Box>

      <Paper sx={styles.Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                display: "flex",
                background: "#FAF9F9",
                alignItems: "center",
                height: "45px",
                "& > th": { paddingBlock: "8px" },
              }}
            >
              <TableCell sx={{ ...styles.headerCell, flex: "1 1 30%", flexBasis: "30%" }}>Prospect</TableCell>
              <TableCell sx={{ ...styles.headerCell, flex: "1 1 30%", flexBasis: "30%" }}>Last publication</TableCell>
              <TableCell
                sx={{
                  ...styles.headerCell,
                  flex: "1 1 30%",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "31px",
                }}
              >
                <Button
                  onClick={() => setChecked(inactives)}
                  sx={{
                    color: "var(--color-grey-400)",
                    padding: "4px 16px",
                    borderRadius: "8px",
                    border: "1px solid var(--color-grey-400)",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    background: "none",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#F0F0F1",
                    },
                  }}
                >
                  Select all
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div style={{ overflow: "auto", height: "250px" }}>
          <Table style={{ tableLayout: "fixed" }}>
            <TableBody>
              {inactives.map((prospect, index) => (
                <TableRow
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    "& > td": { height: "100%" },
                  }}
                  onClick={() => handleToggle(prospect)}
                >
                  <TableCell sx={{ ...styles.cellProps, flex: "1 1 30%", flexBasis: "30%" }}>
                    <Box sx={{ height: "100%" }} display="flex" alignItems="center" gap="8px">
                      <Avatar sx={{ height: "24px", width: "24px" }} alt="user" />
                      <span>{`${prospect["first name"]} ${prospect["last name"]}`}</span>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ ...styles.cellProps, flex: "1 1 30%", flexBasis: "30%" }}>5 month ago</TableCell>
                  <TableCell sx={{ ...styles.cellProps, flex: "1 1 30%", display: "flex", justifyContent: "flex-end" }}>
                    <StyledCheckbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxCheckedIcon />}
                      checked={checked.some((c) => c.id === inactives[index].id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>

      {!!checked.length && (
        <Box sx={styles.unselectBox}>
          <span>{`${checked.length} prospects selected`}</span>
          <span onClick={() => setChecked([])}>Unselect all</span>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mt={2}>
        <StyledButton
          onClick={() => {
            setChecked([]);
            onCloseModal();
          }}
          variation="noBackgroundPrimary"
        >
          Cancel
        </StyledButton>

        { isSaving ?
        ( <StyledButton
          variation="danger"
          sx={{ "& span": { color: "white", height: "24px !important", width: "24px !important" } }}
        >
           <CircularProgress />
        </StyledButton> ) :
        ( <StyledButton
          variation="danger"
          sx={{ "& span": { color: "white" }}}
          disabled={checked.length === 0}
          onClick={handleSubmit}
        >
          <RiDeleteBin6Fill />
          <span style={{ height: "auto !important" }}>Remove selected</span>
        </StyledButton>
      )}
      </Box>
    </Box>
  );
}

export default RemoveInactivesDialog;
