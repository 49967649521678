import domain from "./DomainService";

/**
 * Returns x raised to the n-th power.
 *
 * @param {{newemail: String}} formValues user's email
 * @return {Promise}
 */
export default function SaveDeviceService(device_registration_token, topics) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({
      device_registration_token: device_registration_token,
      topics: topics
    }),
  };
  return fetch(domain() + "save-device", options);
}
