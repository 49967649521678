import React, {useCallback} from 'react';
import styles from './Onboarding.module.css';
import { useNavigate } from 'react-router-dom';

const logoStyle = {
    width:'165px',
    height:'67px',
}

const backArrow = (
    <svg width='24' height='24' xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#385A9F" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
    </svg>
)

const nextArrow = (
    <svg width='24' height='24' xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#385A9F" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
    </svg>
)


const NurtureLeadsDemo = () => {
    let history = useNavigate();
    const redirect = useCallback(
        (path) => {
        history.push(path);
        },
        [history]
    );
    const nextPage = () => {
        redirect('/Login');
    }
    const prevPage = () => {
        redirect('/Onboarding/AISmartCommentingDemo');
    }
    return (
        <div className={`${styles['body']}`}>
            <div className={`${styles.logo} ${styles['middle-left']}`}>
                <a href='https://filtpod.com' target='__blank'>
                    <img src='/images/fp_logo_darkblue.svg' style={logoStyle} alt='filtpod-logo'></img>
                </a>
                <div style={logoStyle}><p className={`${styles['f-14']}`}>Scale your authority, reach and opportunities on LinkedIn</p></div>
            </div>
            <div  className={`${styles['row']}`}>
                <div className={` ${styles['left']} ${styles['left-align']}`}><button onClick={prevPage} className={`${styles['button']}`}>{backArrow}</button></div>
                
                <div className={`${styles.container} ${styles['middle']}`}>
                    <div className={`${styles['f-30']} ${styles['center-align']} ${styles['fw-b']} mb-3`}>Nurture leads at scale</div>
                    <div className={`${styles['f-20']} ${styles['center-align']} mb-3`}>Save your key leads’ LinkedIn profiles on your personalised dashboard to nurture them effortlessly.</div>
                    <div className={`${styles['f-70']} ${styles['center-align']} ${styles['mt-25']}`} >
                    <img style={{width:'900px',height:'500px'}} src='/images/NurtureLeadsDemo2.gif' alt='NurtureLeadsDemo.gif'></img>
                    </div>
                </div>
                
                <div className={` ${styles['right']} ${styles['right-align']}`}><button onClick={nextPage} className={`${styles['button']}`}>{nextArrow}</button></div>
            </div>
        </div>
    );
}
export default NurtureLeadsDemo;