import { styled } from "@mui/material";
import { cloneElement, createContext, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { IoClose } from "react-icons/io5";

import { useOutsideClick } from "../Hooks/useOutsideClick";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "#000000B2",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08), 0px 0px 6px rgba(0, 0, 0, 0.02)",
    zIndex: "1000",
    transition: "all 0.5s",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
    transition: "all 0.5s",
    overflow: "hidden",
    border: "none",
  },
};

const CloseButton = styled("button")`
  background: #ecedee;
  border: none;
  padding: 8px;
  border-radius: 3px;
  transition: all 0.2s;
  position: absolute;
  top: 18px;
  right: 24px;

  &:hover {
    background: #ecedeecc;
  }

  & svg {
    width: 24px;
    height: 24px;
    color: #1f292e;
  }
`;

const ModalContext = createContext();

function Modal({ children }) {
  const [openName, setOpenName] = useState("");
  const close = () => setOpenName("");
  const open = setOpenName;

  return (
    <ModalContext.Provider
      value={{
        open,
        close,
        openName,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

function Open({ children, opens: opensWindowName, onClick }) {
  const { open } = useContext(ModalContext);

  return cloneElement(children, {
    onClick: (ev) => {
      open(opensWindowName);
      onClick?.(ev);
    },
  });
}

function Window({ children, name, closeIcon, noPadding, shouldOpen, modalStyles, onClose = () => {} }) {
  const { openName, close, open } = useContext(ModalContext);

  useEffect(() => {
    if (shouldOpen) {
      open(name);
    }
  }, [shouldOpen, open, name]);

  const handleCloseModal = () => {
    close();
    onClose();
  };

  const ref = useOutsideClick(handleCloseModal);

  if (openName !== name) return null;

  return createPortal(
    <div style={styles.overlay}>
      <div ref={ref} style={{ ...styles.modal, ...modalStyles, padding: noPadding ? "0" : "24px" }}>
        {closeIcon && <CloseButton onClick={handleCloseModal}>{<IoClose />}</CloseButton>}{" "}
        <div>{cloneElement(children, { onCloseModal: handleCloseModal })}</div>
      </div>
      ,
    </div>,
    document.body
  );
}

Modal.Open = Open;
Modal.Window = Window;

export default Modal;
