import { Box, Typography } from "@mui/material";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    textAlign: "center",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },
};

function SyncAccount() {
  return (
    <Box sx={styles.Modal}>
      <img style={{ width: "100%" }} src="/images/suceessToneUpdate.png" alt="sucess img" />
      <Box sx={{ padding: "24px" }} display="flex" flexDirection="column" alignItems="center" gap="1rem">
        <h2>Please sync your account</h2>
        <Typography>
          To import prospects, sync your account using the <strong>"Sync Account"</strong> button in the Engage AI
          widget.
        </Typography>
      </Box>
    </Box>
  );
}

export default SyncAccount;
