import domain from "./DomainService";

/**
 * Returns x raised to the n-th power.
 *
 * @param {{oldpassword: String, password1: String}} formValues form values holding the new and old password
 * @return {Promise}
 */
export default function ChangePasswordService(formValues) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({
      old_password: formValues.oldpassword,
      new_password: formValues.password1,
    }),
  };
  return fetch(domain() + "change-password", options);
}
