import domain from "./DomainService";

export default function AccountActivationService(activation_token, uidb64, pwb64) {
  //const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8"
    },
    body: JSON.stringify({
      token: activation_token,
      uidb64: uidb64,
      pwb64: pwb64
    }),
  };
  return fetch(domain() + "activate-account", options);
}

export function SendAccountReactivationEmailService(email, password) {
  //const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8"
    },
    body: JSON.stringify({
      email: email,
      password: password
    }),
  };
  return fetch(domain() + "reactivate-account", options);
}

