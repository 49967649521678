import React from 'react';
import styles from './Onboarding.module.css';

const backgroundStyle={
    backgroundImage: `url(/images/survey_background.jpg)`,
    height:'60vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    textAlign: "center",
    color:"white"
};

const ExitSurvey = () => {
    return (
        <div>
            <div style={backgroundStyle}>
                <div style={{padding:"30px 0 30px 0"}}><a href='https://engage-ai.co' target='__blank'><img src='/images/engage_ai_logo_white.svg' alt='Engage AI'></img></a></div>
                <p style={{fontWeight:"700", fontSize:"36px"}}>We are sorry to see you go</p>
                <p style={{marginBottom:"50px"}}>Help us understand what we can do to improve <b>Hashtag Analytics</b> and, hopefully, stand a chance to see you take it for another spin!</p>
                <p>This super short survey takes less than 30 seconds:</p>
                <div className={`${styles['white_button']}`}><a href='https://forms.gle/JtKF4ccjBBoM1vzN9' target='__blank'>Start Survey</a></div>
            </div>
            <div style={{padding:"30px 0 30px 0", textAlign:"center", fontWeight:"400"}}>
                <p>Having issues with the <b>Hashtag Analytics</b> extension?</p>
                <div className={`${styles['button']}`} style={{width:"max-content", margin:"auto"}}><a href="https://chrome.google.com/webstore/detail/ocgihmepobcgbfibaopggddieigbopja?authuser=0&hl=en-GB" target='__blank' style={{color:"white"}}>Reinstall Hashtag Analytics</a></div>
                <div style={{paddingTop:"16px"}}><p>or</p></div>
                <div style={{paddingBottom:"16px"}}><a href="mailto: hello@engage-ai.co" style={{color:"black", fontWeight:"700", fontSize:"14px", textDecoration:"underline"}}>Contact Support</a></div>
                <div style={{fontWeight:"400", fontSize:"14px"}}>...and we will gladly help you solve your problems!</div>
            </div>
        </div>
    );
}
export default ExitSurvey;