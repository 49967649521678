import React, {useState} from "react";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from '../Topbar/Topbar';
import { PublishNotificationService } from "../../Services/NotificationService";

const Notifications = () => {
  const [message, setMessage] = useState();
  const [header, setHeader] = useState('');
  const [summary, setSummary] = useState('');
  const [intro, setIntro] = useState('');
  const [body, setBody] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [cta, setCTA] = useState('');
  const [ctaLink, setCTALink] = useState('');
  const [closing, setClosing] = useState('');
  const [targetGroup, setTargetGroup] = useState();
  const [targetIndividuals, setTargetIndividuals] = useState('');

  const handleSubmit = (event) => {
    setMessage("")
    event.preventDefault();
    const data = {
        header: header,
        summary: summary,
        intro: intro,
        body: body,
        imageURL: imageURL,
        cta: cta,
        ctaLink: ctaLink,
        closing: closing,
        group: targetGroup,
        individuals: targetIndividuals
    }
    PublishNotificationService(data).then((res) => {
        if (res.status === 200) {
            setMessage("success!")
        }
        else {
            setMessage("error saving! Please check server.")
        }
    }).catch(() => {
        setMessage("error saving! Please check server.")
    })
  };


  return (
    <div className='container '>
        <div className='row flex-nowrap no-gutters'>
            <Sidebar />
            <div className='no-gutters col-105 mt-2'>
                <div >
                    <Topbar />
                </div>
                <div>
                    <h6>Use the form below as a template of the notification.</h6>
                    <form onSubmit={handleSubmit}>
                        <div >
                            <div className='form-group '>
                            <input
                                className='form-control'
                                placeholder='header'
                                type='text'
                                id='header'
                                onChange={event => setHeader(event.target.value)}
                                value={header}
                                required
                            />
                            </div>
                            <div className='form-group '>
                            <input
                                className='form-control'
                                placeholder='summary to display in main notification page (optional)'
                                type='text'
                                id='summary'
                                onChange={event => setSummary(event.target.value)}
                                value={summary}
                            />
                            </div>
                            <div className='form-group '>
                            <textarea 
                                className='form-control' 
                                placeholder="intro (optional)" 
                                id="intro" 
                                rows="5"
                                onChange={event => setIntro(event.target.value)}
                                value={intro}
                                ></textarea>
                            </div>
                            <div className='form-group '>
                            <input
                                className='form-control'
                                placeholder='image_url (optional)'
                                type='text'
                                id='image_url'
                                onChange={event => setImageURL(event.target.value)}
                                value={imageURL}
                            />
                            </div>
                            <div className='form-group '>
                            <textarea 
                                className='form-control' 
                                placeholder="body (optional)" 
                                id="body" 
                                rows="10"
                                onChange={event => setBody(event.target.value)}
                                value={body}>
                            </textarea>
                            </div>
                            <div className='form-group '>
                            <input
                                className='form-control'
                                placeholder='cta (optional)'
                                type='text'
                                id='cta'
                                onChange={event => setCTA(event.target.value)}
                                value={cta}
                            />
                            </div>
                            <div className='form-group '>
                            <input
                                className='form-control'
                                placeholder='cta_link (required if cta is present)'
                                type='text'
                                id='cta_link'
                                onChange={event => setCTALink(event.target.value)}
                                value={ctaLink}
                            />
                            </div>
                            <div className='form-group '>
                            <textarea 
                                className='form-control' 
                                placeholder="closing (optional)" 
                                id="closing" 
                                rows="5"
                                onChange={event => setClosing(event.target.value)}
                                value={closing}>
                            </textarea>
                            </div>
                            <div style={{marginBottom: "10px"}}>
                                <div>Select target group or custom</div>
                                <select id="target" onChange={event => setTargetGroup(event.target.value)}>
                                <option value=""></option>
                                    <option value="All">All</option>
                                    <option value="Member">Member</option>
                                    <option value="Growth Plan">Growth Plan</option>
                                    <option value="Affiliate Partner">Affiliate Partner</option>
                                    <option value="Chrome Extension">Chrome Extension</option>
                                    <option value="Hootsuite">Hootsuite</option>
                                    <option value="Pipedrive">Pipedrive</option>
                                    <option value="Engage AI">Engage AI</option>
                                    <option value="Prominence Global">PG</option>
                                    <option value="Custom">Custom</option>
                                </select> 
                            </div>
                            <div className='form-group ' style={{marginBottom: "10px"}}>
                                <div>Specify emails if custom (comma separated and no space in between)</div>
                                <textarea 
                                    className='form-control' 
                                    placeholder="jane@gmail.com,john@gmail.com" 
                                    id="targetIndividuals" 
                                    rows="2"
                                    onChange={event => setTargetIndividuals(event.target.value)}
                                    value={targetIndividuals}>
                                </textarea>
                            </div>
                        </div>
                        
                        <button className='btn-sm mt-3'>
                            Publish
                        </button>
                    </form>
                    <p>{message}</p>
                </div>
            </div>
        </div>
    </div>
  );
};
export default Notifications;
