import domain from "./DomainService";

/**
 * Returns a Promise
 * @param {string} inputEmail admin email address
 * @param {string} inputPassword admin password
 * @param {string} resetDate date as a string in the form yyyyMMdd
 * @return {Promise}
 */
export default function SimulationResetService(inputEmail, inputPassword,resetDate) {
  const token = localStorage.getItem("token");
  console.log("line12")
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ email: inputEmail, password: inputPassword, date : resetDate }),
  };

  return fetch(domain() + "simulation-reset", options);
  
}
