import domain from "./DomainService";
/**
 * Returns a Promise
 *
 * @param {string} formValues values from submitted form
 * @return {Promise}
 */
export default function ResetPasswordService(formValues) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      token: formValues.token,
      password: formValues.password1,
    }),
  };
  return fetch(domain() + "password-reset/confirm/", options);
}
