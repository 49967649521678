const initialState = {
  user: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    is_first_login: "",
    linkedinPage: "",
    group: "",
    is_paying: "",
    is_staff: "",
    ui: {},
    max_number_of_regenerated_AI_responses: 0,
    max_number_of_leads: 0,
    days_to_run_lead_nurturing: "",
    time_to_scrape_prospects_posts_local: 0,
    get_notified_when_prospects_post: "",
    get_notified_day: "",
    get_notified_hour_local: "",
    open_ai_api_key: "",
    linkedin_email: "",
    linkedin_first_name: "",
    linkedin_last_name: "",
    linkedin_access_token_expiry_date: "",
  },
  currentSubmittedLink: {
    dateSubmitted: "",
    link: "",
    pod: "",
  },
};

export default initialState;
