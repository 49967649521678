import domain from "./DomainService";

/**
 * 
 * @param {string} inputEmail user's email
 * @param {string} inputPassword user's pass
 * @return {Promise}
 */
export default function GetHotApplicantsService() {
  const token = localStorage.getItem('token');
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: 'JWT ' + token
    }
  };

  return fetch(domain() + "hot-applicants", options);
}

export function UpdateHotApplicantsService(data) {
  const token = localStorage.getItem('token');
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: 'JWT ' + token
    },
    body: JSON.stringify(data)
  };

  return fetch(domain() + "hot-applicants", options);
}