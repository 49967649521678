const headers = [
  { label: "first_name", key: "firstName" },
  { label: "last_name", key: "lastName" },
  { label: "url*", key: "url" },
  { label: "list_name*", key: "listName" },
  { label: "headline", key: "headline" },
];

const data = [
  {
    firstName: "Jason",
    lastName: "Tan",
    url: "https://www.linkedin.com/in/jpctan/",
    listName: "Default",
    headline: "Ex-engineer now selling dreams – if I can do it, your goldfish can too!",
  },
];

export const template = {
  data: data,
  headers: headers,
  filename: "template.csv",
};
