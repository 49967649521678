import React, { useEffect, useState } from "react";
import Spinner from "../Spinner/Spinner";
import { GetUserData } from "../../Services/UserDataService";
import ReportMemberService from "../../Services/ReportMemberService";
import styles from "./ReportMember.module.css";

const ReportMember = () => {
  const [data, setData] = useState();
  const [allMembers, setAllMembers] = useState();
  const [dropdown, setDropdown] = useState();
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState();
  const [link, setLink] = useState("");
  const [remark, setRemark] = useState("");
  const [loading, setIsLoading] = useState(true);
  const [confirmation, setConfirmation] = useState();

  useEffect(() => {
    GetUserData().then((res) => {
      res.json().then((data) => {
        //console.log(Object.values(data))
        setData(data);
        setAllMembers(Object.values(data));
        setIsLoading(false);
      });
    });
  }, []);

  const handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    //console.log(id,value,selectedMember)

    if (id === "link") {
      setLink(value);
    }
    if (id === "remark") {
      setRemark(value);
    }
    if (id === "member") {
      setSelectedMember();
      let tempArray = [];
      allMembers
        .filter((item) => {
          //console.log(item);
          const searchTerm = value.toLowerCase();
          const fullName = item.toLowerCase();
          return searchTerm && fullName.startsWith(searchTerm) && fullName !== searchTerm;
        })
        .map((item) => {
          tempArray.push(item);
          return item;
        });
      if (tempArray.length > 0) {
        setDropdown(tempArray);
      } else {
        setDropdown();
      }
      console.log(tempArray);
    }
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();

    //const payload = {"member_id": selectedMemberId, "link": link, "remark":remark};
    if (selectedMember) {
      ReportMemberService(selectedMemberId, link, remark)
        .then((res) => {
          if (res.status === 200) {
            setConfirmation("We have received your report.");
          } else {
            setConfirmation("Error submitting report. Please try again.");
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setConfirmation("Please select a member.");
    }
  };

  const selectMember = (clickedName) => {
    //console.log(clickedName);
    //console.log(data);
    const member_id = Object.keys(data).find((key) => data[key] === clickedName);
    setSelectedMember(clickedName);
    setSelectedMemberId(member_id);
    setDropdown();
    //console.log(member_id)
  };

  return (
    <div>
      <div className="row flex-nowrap no-gutters">
        <div className="no-gutters col-105 mt-2 pl-3">
          {loading ? <Spinner /> : ""}
          <p className={`h4 mb-3 mt-5`}>Report Member</p>
          <p>
            It's important to keep the pod healthy and we take this very seriously. Please cast your vote carefully, and
            when requested, you should also ensure you have the evidence to support your claims. Once the score of a
            respective member goes below an acceptable threshold, the member will be asked to leave the pod.
          </p>

          <form onSubmit={handleSubmit}>
            <div className={`${styles["row"]}`}>
              {/**
               * Prevent PG from seeing all members' names
               * <Select styles={customStyles} options={dropdownOptions} onChange={setSelectedMember} defaultValue={selectedMember} placeholder={<div style={{fontWeight:"400", color:"#385a9f"}}>Select member to report</div>}/>*/}
              <div className={`${styles["row-item"]}`}>
                <div className={`${styles["column"]}`}>
                  <input
                    className={`${styles["text-input"]} ${styles["h-35"]}`}
                    placeholder="Type the member's name"
                    type="text"
                    id="member"
                    value={selectedMember}
                    onChange={handleChange}
                    required
                  />
                  {dropdown ? (
                    <div className={`${styles["dropdown"]}`}>
                      {dropdown.map((item) => (
                        <div onClick={() => selectMember(item)} className={`${styles["dropdown-row"]}`}>
                          {item}
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <input
                className={`${styles["text-input"]} ${styles["row-item"]} ${styles["h-35"]} ml-3`}
                placeholder="Link to your post that is affected by the problem"
                type="text"
                id="link"
                value={link}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                className={`${styles["text-input"]} ${styles["w-100"]} ${styles["h-100"]} mt-3`}
                placeholder="Reason for reporting"
                type="text"
                id="remark"
                autoComplete="off"
                value={remark}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className={`${styles["button"]} mt-3`}>
              Submit
            </button>
          </form>
          <>{confirmation ? <div>{confirmation}</div> : ""}</>
        </div>
      </div>
    </div>
  );
};

export default ReportMember;
