import domain from "./DomainService";

/**
 * Fetch user's data for display on their homepage
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function GetHomePage() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  let pod_id = "TIC1";

  return fetch(domain() + "homepage?pod_id=" + pod_id, options);
}
