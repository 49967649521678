import { useState } from "react";

import RemoveInactivesDialog from "./RemoveInactivesDialog";
import Modal from "../../ReusableComponents/Modal";
import SucessModal from "./SucessModal";

import { Box, useMediaQuery, useTheme } from "@mui/material";

import { MdMessage } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { IoMdInformationCircle } from "react-icons/io";
import { formateNumber } from "../../../../Utils/reusableFunctions";

const styles = {
  title: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    background: "#2F3EAC1A",
    padding: "8px 16px",
    width: "max-content",
    border: "1px solid #CED2EC",
    borderBottom: "none",
    color: "var(--color-brand)",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    borderRadius: "4px 4px 0 0",
    "&::after": {
      content: `""`,
      position: "absolute",
      width: "100%",
      bottom: "-1px",
      left: "0",
      display: "block",
      height: "1px",
      backgroundColor: "#E5E7F5",
      zIndex: "1",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    background: "#fff",
    border: "1px solid #CED2EC",
    padding: "16px",
    borderRadius: "0 8px 8px 8px",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "21px",
    color: "var(--color-grey-400)",
    position: "relative",
  },
};

function Copilot({
  inactives = [],
  monitored = [],
  prospectsLimit,
  nonMonitoredProspects,
  onAutoSelect,
  onDelete,
  setShowSuccessAutoSelect,
  autoSelectNumber,
}) {
  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [showSuccessDelete, setShowSuccessDelete] = useState(false);
  const [checked, setChecked] = useState([]);

  const handleAutoSelect = async () => {
    await onAutoSelect(autoSelectNumber);
    setShowSuccessAutoSelect(true);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box sx={styles.title}>
        <MdMessage
          style={{ color: "var(--color-brand)", height: "14px", width: "14px", transform: "rotateY(180deg)" }}
        />
        <span>Suggestions</span>
      </Box>
      <Modal>
        <Box sx={styles.body}>
          {inactives && !!inactives.length && (
            <Box display="flex" alignItems="center" gap="8px">
              <IoIosWarning style={{ height: "18px", width: "18px", color: "#D61F1F" }} />
              <div>
                <strong>{formateNumber(inactives.length)} inactive prospects</strong> found on your lists.{" "}
                <Modal.Open opens="check-inactives">
                  <span
                    style={{
                      fontWeight: "700",
                      color: "#D61F1F",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Check inactive prospects
                  </span>
                </Modal.Open>
              </div>
            </Box>
          )}
          {prospectsLimit - monitored.length > 0 && (
            <Box display="flex" alignItems="center" gap="8px">
              <IoMdInformationCircle style={{ height: "18px", width: "18px", color: "var(--color-brand)" }} />
              <div>
                You can still add
                <strong> {formateNumber(prospectsLimit - monitored.length)} more prospects for monitoring </strong>(
                {formateNumber(monitored.length) || 0}/{formateNumber(prospectsLimit)} currently selected).{" "}
                {nonMonitoredProspects && !!nonMonitoredProspects.length && (
                  <span
                    onClick={handleAutoSelect}
                    style={{
                      fontWeight: "700",
                      color: "var(--color-brand)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                       Auto-select {formateNumber(autoSelectNumber)} prospects
                  </span>
                )}
              </div>
            </Box>
          )}
        </Box>
        <Modal.Window name="check-inactives" closeIcon={true} modalStyles={{ width: bigScreen ? "43%" : "40%" }}>
          <RemoveInactivesDialog
            onDelete={onDelete}
            setShowSuccess={setShowSuccessDelete}
            inactives={inactives}
            checked={checked}
            setChecked={setChecked}
          />
        </Modal.Window>
        <Modal.Window
          name="sucessDelete"
          shouldOpen={showSuccessDelete}
          closeIcon={true}
          modalStyles={{ width: bigScreen ? "40%" : "30%" }}
          onClose={() => {
            setShowSuccessDelete(false);
            setChecked([]);
          }}
        >
          <SucessModal
            onClose={() => {
              setShowSuccessDelete(false);
              setChecked([]);
            }}
            title={"Deleted Successfully"}
            text={
              <>
                <strong>
                  {formateNumber(checked.length)} inactive {checked.length > 1 ? "prospects" : "prospect"}
                </strong>{" "}
                have been removed. <br /> You can now add and monitor new prospects.
              </>
            }
          />
        </Modal.Window>
      </Modal>
    </Box>
  );
}

export default Copilot;
