import domain from './DomainService';

/**
 * LinkedIn Authentication
 */
export default function LinkedinAuthService(code, email) {
    const options = {
        method: 'POST',
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({code:code, email:email})
    };
    return fetch(domain() + "linkedin-auth" , options);
}

export function DisconnectLinkedinService(email) {
    const options = {
        method: 'DELETE',
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({email:email})
    };
    return fetch(domain() + "linkedin-auth" , options);
}