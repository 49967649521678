import domain from "./DomainService";

/**
 * Marks a Submission as complete in the database after the user clicks on the link
 * Uses JWT token to identify user
 *
 * @param {object} user The user object, with properties firstName, lastName, email and linkedinPage
 * @return {Promise}
 */

export default function UpdateAccountService(user) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify(user),
  };

  return fetch(domain() + "update-account", options);
}

export function DeleteAccountService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    }
  };

  return fetch(domain() + "update-account", options);
}
