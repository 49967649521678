import { Box } from "@mui/material";

const styles = {
  helpBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    color: "#fff",
    "& span": {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "22px",
    textDecoration: "underline",
  },
};

function Help() {
  return (
    <Box sx={styles.helpBox}>
      <span>Having issues with the extension ?</span>
      <Box display="flex" alignItems="center" gap="20px">
        <a href="https://help.engage-ai.co/en" target="_blank" rel="noopener noreferrer" style={styles.link}>
          Help center 🙏
        </a>
        <a href="https://tally.so/r/mDkRyN" target="_blank" rel="noopener noreferrer" style={styles.link}>
          Contact us 💬
        </a>
      </Box>
    </Box>
  );
}

export default Help;
