import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner/Spinner';
import Topbar from '../Topbar/Topbar';
import AdminDashboardService from '../../Services/AdminDashboardService';
import LineChart from './Chart'
import Sidebar from '../NurtureProspects/Sidebar/Sidebar';

const Dashboard= () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [selectedMetric, setSelectedMetric] = useState();
    const [xValues, setXValues] = useState();
    const [yValues, setYValues] = useState();
    const [names, setNames] = useState();
    const [selectedWeeklyMetric, setSelectedWeeklyMetric] = useState();
    const [WeeklyXValues, setWeeklyXValues] = useState();
    const [WeeklyYValues, setWeeklyYValues] = useState();
    const [WeeklyNames, setWeeklyNames] = useState();

    const handleChange = (selected) => {
        setSelectedMetric(selected);
        setXValues();
        setYValues();
        if (['members_who_subscribed_but_not_logged_in_for_3_months', 'members_who_subscribed_but_not_submitting'].includes(selected)) {
            let tempArray = [];
            Object.values(data[selected]).map((name) => {return tempArray.push(name)});
            setNames(tempArray);
        } else {
            const items = Object.keys(data["daily"][selected]).map((date) => { return [date, data["daily"][selected][date]] });
            //console.log(items);
            items.sort(
                (first, second) => { return Date.parse(first[0]) - Date.parse(second[0]) }
            );  
            const sortedDates = items.map(
                (e) => { 
                    const date = new Date(e[0]);
                    return date.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' }) });
            const values = items.map(
                (e) => { return e[1] });
            setXValues(sortedDates);
            setYValues(values);
        }
    };
    const handleWeeklyChange = (selected) => {
        setSelectedWeeklyMetric(selected);
        setWeeklyXValues();
        setWeeklyYValues();
        if (['members_who_subscribed_but_not_logged_in_for_3_months', 'members_who_subscribed_but_not_submitting'].includes(selected)) {
            let tempArray = [];
            Object.values(data[selected]).map((name) => {return tempArray.push(name)});
            setWeeklyNames(tempArray);
        } else {
            const items = Object.keys(data["weekly"][selected]).map((date) => { return [date, data["weekly"][selected][date]] });
            //console.log(items);
            items.sort(
                (first, second) => { return Date.parse(first[0]) - Date.parse(second[0]) }
            );  
            const sortedDates = items.map(
                (e) => { 
                    const date = new Date(e[0]);
                    return date.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' }) });
            const values = items.map(
                (e) => { return e[1] });
            setWeeklyXValues(sortedDates);
            setWeeklyYValues(values);
        }
    };

    useEffect(() => {
        setLoading(true);
        AdminDashboardService().then((res)=>{
            if (res.status === 200) {
                res.json().then((json) =>{
                    console.log(json)
                    setData(json);
                    setLoading(false);
                })
            }
        })
    }, [])
    return (
        <div className='container '>
            <div className='row' style={{flexWrap:"nowrap"}}>
                <Sidebar />
                <div style={{flex:"1 0 80%", width:"100vw"}}>
                    {loading ? <Spinner /> : ''}
                    <Topbar />
                    <div style={{marginLeft:"1.5em"}}>
                    <p className={`h4`}>Daily Stats</p>
                        {data ? <select
                                    value={selectedMetric}
                                    onChange={e => handleChange(e.target.value)}>
                                    {Object.keys(data["daily"]).map(o => (
                                        <option key={o} value={o}>{o}</option>
                                    ))}
                                </select> : ""
                        }
                    </div>
                    <div style={{marginTop:"1em"}}>
                            {xValues && yValues ? <LineChart xValues={xValues} yValues={yValues} keyword={selectedMetric}/> : 
                            names ? <div>
                                    {names.map((name) => <div>{name}</div>)}
                                    </div> 
                                : ''}
                    </div>

                    <div style={{marginLeft:"1.5em"}}>
                        <p className={`h4`}>Weekly Stats</p>
                        {data ? <select
                                    value={selectedWeeklyMetric}
                                    onChange={e => handleWeeklyChange(e.target.value)}>
                                    {Object.keys(data["weekly"]).map(o => (
                                        <option key={o} value={o}>{o}</option>
                                    ))}
                                </select> : ""
                        }
                    </div>
                    <div style={{marginTop:"1em"}}>
                            {WeeklyXValues && WeeklyYValues ? <LineChart xValues={WeeklyXValues} yValues={WeeklyYValues} keyword={selectedWeeklyMetric}/> : 
                            WeeklyNames ? <div>
                                    {WeeklyNames.map((name) => <div>{name}</div>)}
                                    </div> 
                                : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard