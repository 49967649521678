// -------------------------------------------------------------------------
// IMPORTS
// -------------------------------------------------------------------------
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";

// -------------------------------------------------------------------------

const styles = {
  Paper: {
    border: "1px solid #D1D9E2",
    borderRadius: "10px",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    padding: "0",
    boxShadow: "none",
  },
  headerCell: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    color: "#1F292E",
    border: "none",
    "&:not(:last-of-type)": {
      borderRight: "1px solid rgba(224, 226, 228, 1)",
    },
  },
  bodyCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
    lineHeight: "18px",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Inter",
    color: "rgba(65, 80, 88, 1)",
    borderBottom: "none",
  },
  errorCell: {
    border: "1px solid var(--color-red)",
    color: "var(--color-red)",
    background: "rgba(251, 233, 233, 1)",
  },
  tooltipIcon: { height: "20px", width: "20px", color: "var(--color-red)" },
};

function ReusableTable({ tableIdx, options = {} }) {
  const scrollVisible = options.data.length > 6;

  return (
    <Paper sx={styles.Paper}>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: "flex",
              background: "#FAF9F9",
              alignItems: "center",
              borderBottom: "1px solid rgba(224, 226, 228, 1)",
              "& > th": {
                height: "45px",
                "&:first-of-type": {
                  width:
                    tableIdx === 1
                      ? `${scrollVisible ? "calc(10% - 1.7px)" : "10%"}`
                      : `${scrollVisible ? "calc(20% - 3.4px)" : "20%"}`,
                },
                "&:nth-of-type(2)": {
                  width:
                    tableIdx === 1
                      ? `${scrollVisible ? "calc(25% - 4.25px)" : "25%"}`
                      : `${scrollVisible ? "calc(80% - 13.6px)" : "80%"}`,
                },
                "&:nth-of-type(3)": { width: tableIdx === 1 ? `${scrollVisible ? "calc(15% - 2.55px)" : "15%"}` : "0" },
                "&:nth-of-type(4)": { width: tableIdx === 1 ? `${scrollVisible ? "calc(15% - 2.55px)" : "15%"}` : "0" },
                "&:nth-of-type(5)": { width: tableIdx === 1 ? `${scrollVisible ? "calc(20% - 3.4px)" : "20%"}` : "0" },
                "&:nth-of-type(6)": { width: tableIdx === 1 ? `${scrollVisible ? "calc(15% - 2.55px)" : "15%"}` : "0" },
              },
            }}
          >
            {options.headers.map((header, index) => (
              <TableCell sx={{ ...styles.headerCell }} key={index}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: options.data.length > 6 ? "240px" : `${options.length * 40}px`,
        }}
      >
        <Table style={{ tableLayout: "fixed" }}>
          <TableBody>
            {options.data.map((row, index) => (
              <TableRow
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px !important",
                  "& td": {
                    display: "flex",
                    alignItems: "center !important",
                    height: "100% !important",
                  },
                }}
                key={index}
              >
                {tableIdx === 0 ? (
                  <>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "20%",
                        borderRight: "1px solid rgba(224, 226, 228, 1)",
                      }}
                    >
                      {row.line}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "80%",
                        color: "var(--color-brand)",
                      }}
                    >
                      <a style={{ color: "var(--color-brand)" }} href={row.url} target="_blank" rel="noreferrer">
                        {row.url}
                      </a>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "10%",
                      }}
                    >
                      {row.line}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "25%",
                        borderLeft: "1px solid rgba(224, 226, 228, 1)",
                        borderRight: "1px solid rgba(224, 226, 228, 1)",
                        ...(row.errors?.URL && styles.errorCell),
                      }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: row.errors?.URL ? "var(--color-red)" : "var(--color-brand)",
                        }}
                      >
                        <a
                          style={{ color: row.errors?.URL ? "var(--color-red)" : "var(--color-brand)" }}
                          href={row.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.url}
                        </a>
                      </span>
                      {row.errors?.URL && (
                        <Tooltip title={row.errors?.URL} placement="bottom-end">
                          <span>
                            <RiErrorWarningLine style={styles.tooltipIcon} />
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "15%",
                        borderRight: "1px solid rgba(224, 226, 228, 1)",
                        ...(row.errors?.first_name && styles.errorCell),
                      }}
                    >
                      <span>{row.first_name}</span>
                      {row.errors?.first_name && (
                        <Tooltip title={row.errors?.first_name} placement="bottom-end">
                          <span>
                            <RiErrorWarningLine style={styles.tooltipIcon} />
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "15%",
                        borderRight: "1px solid rgba(224, 226, 228, 1)",
                        ...(row.errors?.last_name && styles.errorCell),
                      }}
                    >
                      <span>{row.last_name}</span>
                      {row.errors?.last_name && (
                        <Tooltip title={row.errors?.last_name} placement="bottom-end">
                          <span>
                            <RiErrorWarningLine style={styles.tooltipIcon} />
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        borderRight: "1px solid rgba(224, 226, 228, 1)",
                        ...(row.errors?.list_name && styles.errorCell),
                      }}
                    >
                      <span>{row.list_name}</span>
                      {row.errors?.list_name && (
                        <Tooltip title={row.errors?.list_name} placement="bottom-end">
                          <span>
                            <RiErrorWarningLine style={styles.tooltipIcon} />
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styles.bodyCell,
                        width: "15%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        ...(row.errors?.headline && styles.errorCell),
                      }}
                    >
                      <span>{row.headline}</span>
                      {row.errors?.headline && (
                        <Tooltip title={row.errors?.headline} placement="bottom-end">
                          <span>
                            <RiErrorWarningLine style={styles.tooltipIcon} />
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

export default ReusableTable;
