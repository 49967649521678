import React, { useState, useEffect } from 'react';
import ResetPasswordService from '../../Services/ResetPasswordService';
import styles from '../Login/LoginPage.module.css';
import formStyles from '../Login/FormInput.module.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';

const ResetPassword = (params) => {
  const searchParams = useSearchParams();
  const token = searchParams[0].get("token");
  const [success, setSuccess] = useState(false);
  const [formValues, setFormValues] = useState({
    token: token,
    password1: '',
    password2: ''
  });
  const [responseValues, setResponseValues] = useState({
    response: { message: '' }
  });

  let history = useNavigate();

  const handleChange = (event) => {
    event.persist();
    setFormValues((values) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (event) => {
    // Check if new passwords match
    event.preventDefault();
    if (formValues.password1 !== formValues.password2) {
      setResponseValues({ message: 'Passwords Do Not Match' });
      return;
    }
    if (formValues.password1.length < 8) {
      setResponseValues({
        message: 'New Password Must Be At Least 8 Characters Long'
      });
      return;
    }

    ResetPasswordService(formValues).then((res) => {
      if (res.status === 200) {
        res.json().then((resetJSON) => {
          setResponseValues({
            message: resetJSON.message
          });
          setFormValues({ password1: '', password2: '' });
          setSuccess(true);
          //setIsLoading(false);
        });
      } else {
        setSuccess(false);
      }
        
    });
  };

  const redirect = (path) => {
    history(path);
  };

  const [pauseImage, setPauseImage] = useState(false);
  const handleMouseEnter = () => {
    setPauseImage(true);
  }
  const handleMouseLeave = () => {
    setPauseImage(false);
    setCurrentIndex(currentIndex + 1);
  }

  const changeImage = (e) => {
    let image = e.currentTarget.id;
    if (image === "lead_monitoring"){
      setCurrentIndex(1);
    }
    else if (image === "ai_smart_commenting") {
      setCurrentIndex(2)
    }
    else {
      setCurrentIndex(0);
    }
  };
  
  const hashtag_trends = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/1_hashtag.svg' alt='FILT Pod'></img></div>
          <div style={{color:"white", textAlign:"center", height:"500px"}}>
            <div style={{height:"90px", marginBottom:"40px"}}>
              <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                <div>Seize LinkedIn hashtags with rapid follower growth for more content exposure by monitoring their behavioral trends.</div>
              </div>
            </div>
            <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
              <img src='/images/hashtag_trends_description_small.jpg' alt='Hashtag Trends'></img>
            </div>
          </div>
          <div >
            <div style={{width:"max-content", margin:"auto"}} className='row'>
              <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
              <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
              
            </div>
          </div>
        </div>
    </div>
)

const lead_monitoring = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/2_engage.svg' alt='FILT Pod'></img></div>
            <div style={{color:"white", textAlign:"center", height:"500px"}}>
              <div style={{height:"90px", marginBottom:"40px"}}>
                <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                Use AI to write insightful comments, saving hours of your time by engaging and increasing touchpoints with multiple LinkedIn leads for faster conversions.
                </div>
              </div>
              <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
                <img src='/images/lead_monitoring.jpg' alt='Lead Monitoring'></img>
              </div>
            </div>
            <div >
              <div style={{width:"max-content", margin:"auto"}} className='row'>
                <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
                <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
               
              </div>
            </div>
        </div>
    </div>
)

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pauseImage) {
        return
      }
      if (currentIndex >= 1) {
        setCurrentIndex(0)
      }
      else {
        setCurrentIndex(currentIndex + 1)
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, pauseImage]);

  const openWebsite = () => {
    window.open("https://filtpod.com/engage-ai");
  };

  return (
    <div className={`${styles.main} flex-nowrap `}>
      <div className='row'>
        {currentIndex === 1 ? lead_monitoring : hashtag_trends}
        <div className={`${styles.rightbackground} col-6 `}>
          {/* {loading ? <Spinner className="spinner-container" /> : ""} */}
          {success ? (
            <div className='form-row d-flex justify-content-center'>
              <div className='col-md-8 mt-5 row' style={{alignItems:"center", cursor:"pointer"}} onClick={openWebsite}>
                      <img
                          src='/images/engage_ai_logo.svg'
                          alt='engageai'
                          style={{height:"50px", marginRight:"10px"}}
                        />
                      <div className='h2'>Engage AI</div>
                      
                    </div>
              <div className='col-md-8 mt-3'>
                <div style={{fontSize:"20px", fontWeight:"500", lineHeight:"36px", marginBottom:"50px"}}>Password successfully updated!</div>
                <div className='d-flex justify-content-center mt-3'>
                  <div><span><img src='/images/back-arrow.svg' alt='FILT Pod'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <div>
                  <div className='form-row d-flex justify-content-center'>
                  <div className='col-md-8 mt-5 row' style={{alignItems:"center", cursor:"pointer"}} onClick={openWebsite}>
                      <img
                          src='/images/engage_ai_logo.svg'
                          alt='engageai'
                          style={{height:"50px", marginRight:"10px"}}
                        />
                      <div className='h2'>Engage AI</div>
                      
                    </div>
                    <div className=' form-group col-md-8 mt-5'>
                      <p className='mb-5' style={{fontSize:"20px"}}>Reset Password</p>
                    </div>
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='password1'>Password</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='Password (at least 8 characters)'
                                type='password'
                                id='password1'
                                name='password1'
                                onChange={handleChange}
                                autoComplete='new-password'
                                value={formValues.password1}
                                required
                            />
                        </div>
                        <PasswordStrengthBar password={formValues.password1} minLength={8}/>
                    </div>
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='password2'>Confirm Password</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='Re-Enter Password'
                                type='password'
                                id='password2'
                                name='password2'
                                onChange={handleChange}
                                autoComplete='new-password'
                                value={formValues.password2}
                                required
                            />
                        </div>
                    </div>
                    <div className='d-flex col-md-12 justify-content-center'>
                      <span style={success ? { color: 'green' } : { color: '#A91212' }}>{responseValues.message}</span>
                    </div>
                    <div className='form-group col-md-8 mt-4'>
                      <button className={`${formStyles['submit-button']}`} data-testid='newpassword-submit'>
                        Reset password
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className='d-flex justify-content-center'>
                <span className='font-weight-bold cursorPointer' onClick={() => redirect('/Dashboard')}>
                  Return to login
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
