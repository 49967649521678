import { Box } from "@mui/material"
import Spinner from "../../Spinner/Spinner"

const styles = {
    Box: {
        height:"100%",
        width:"100%",
        position:"absolute",
        backdropFilter: "blur(1px)",
        backgroundColor: "#F9FBFD",
        top: "0",
        left:"0",
        zIndex: "10",
    }
}

function StyledSpinner({isLoading}) {
    return (
        <Box sx={{...styles.Box, opacity: isLoading ? "1" : "0", visibility: isLoading ? "visible" : "hidden"}}>
         <Spinner />
        </Box>
    )
}

export default StyledSpinner
