import { Box, Typography } from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import StyledButton from "../../ReusableComponents/Button";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    maxWidth: "490px",
    overflow: "hidden",
    "& *": {
      margin: " 0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#415058",
      lineHeight: "24px",
      marginBottom: "5px",
      textAlign: "center",
      "& span": {
        fontSize: "14px",
        fontWeight: "700",
        color: "#1F292E",
      },
    },
  },
};

function CookieErrDialog({ onCloseModal, getInitialState }) {
  const handleClick = () => {
    onCloseModal();
    getInitialState();
  };
  return (
    <Box sx={styles.Modal}>
      <img style={{ width: "100%" }} src="/images/suceessToneUpdate.png" alt="sucess img" />
      <Box sx={{ padding: "24px" }} display="flex" flexDirection="column" alignItems="center" gap="1rem">
        <h3>Please sync your account</h3>
        <Typography>
          To check for updates, sync your account using the <strong>"Sync Account"</strong> button in the Engage AI
          widget.
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" gap="16px" mt="5px">
          <StyledButton onClick={handleClick}>
            <FaCheck />
            <span>Done</span>
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
}

export default CookieErrDialog;
