import React, { useContext, useEffect } from "react";
import { Context } from "../../Store/Store";
import { TransformUserData } from "../../Services/UserDataService";
import "./LinkedinTraining.css";

const LinkedinTraining = () => {
  const [state, dispatch] = useContext(Context);
  useEffect(() => {
    // Check localstorage to see if user data exists
    // Use case is when a user refreshes and we lose the context store
    // Rehydrate user data from localstorage
    // Have to use payload: {data: {}} data inside is to match the shape of the original object
    // without this the context store isn't readable

    if (state.user["data"] === undefined) {
      const t = localStorage.getItem("user");
      const u = JSON.parse(t);

      if (u) {
        dispatch({
          type: "SET_USER",
          payload: {
            data: TransformUserData(u),
          },
        });
      }
    }
    return () => {};
  }, [dispatch, state.user]);

  return (
    <div>
      <div className="pageLinkedinTraining row flex-nowrap no-gutters">
        <div className="no-gutters mt-2">
          {state.user?.data?.ui["linkedin-training"] ? (
            <div dangerouslySetInnerHTML={{ __html: state.user?.data?.ui["linkedin-training"] }}></div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkedinTraining;
