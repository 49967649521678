import React, { useState, useRef } from "react";
import styles from "../Login/FormInput.module.css";
import ForgotPasswordService from "../../Services/ForgotPasswordService";

const FormInputForgot = ({ setSuccess}) => {
  const [formValues, setFormValues] = useState({
    email: "",
  });
  let forgotButton = useRef();

  const handleSubmit = (event) => {
    // Check if new passwords match
    event.preventDefault();
    console.log(formValues.email);

    if (forgotButton.current) {
      forgotButton.current.setAttribute("disabled", "disabled");
    }

    ForgotPasswordService(formValues.email).then((res) => {
      if (res.status === 200) {
        res.json().then(() => {
          setFormValues({
            email: "",
          });
          setSuccess(true);
        });
      } else if (res.status === 400)
        res.json().then(() => {
          setSuccess(false);
        });
    });
  };

  const handleChange = (event) => {
    event.persist();
    setFormValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const openWebsite = () => {
    window.open("https://filtpod.com/engage-ai");
  };

  return (
    <div>
      <div className="form-row d-flex justify-content-center">
      <div className='col-md-8 mt-5 row' style={{alignItems:"center", cursor:"pointer"}} onClick={openWebsite}>
          <img
              src='/images/engage_ai_logo.svg'
              alt='engageai'
              style={{height:"50px", marginRight:"10px"}}
            />
          <div className='h2'>Engage AI</div>
          
        </div>
      </div>
      <form onSubmit={handleSubmit} id="login-form" data-testid="login-form">
        <div className="form-row d-flex justify-content-center">
          <div className=" form-group col-md-8 mt-5">
            <div style={{fontSize:"24px"}}>Forgot Password?</div>
          </div>

          <div className="form-group col-md-8 mt-3">
            <label htmlFor="email" style={{fontWeight:"500"}}>No worries, we’ll send you reset instructions.</label>
            <input
              className={`${styles["form-input3"]} form-control  mt-2`}
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              value={formValues.email}
              autoComplete="off"
              required
              data-testid="emailInput"
              placeholder="user@gmail.com"
            />
          </div>
          <div className="form-group col-md-8 mt-3">
            <button ref={forgotButton} className={`${styles["submit-button"]}`} data-testid="login-submit">
              Reset password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormInputForgot;
