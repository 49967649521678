import domain from "./DomainService";
const { Configuration, OpenAIApi } = require("openai");


export default function GenerateResponsesService(post, numResponses, link, responseText = '', trial=false) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ post: post, numResponses: numResponses, link:link, responseText:responseText, trial:trial}),
  };
  let pod_id = "TIC1";
  return fetch(domain() + "generate-responses?pod_id=" + pod_id, options);
}

export function SaveGeneratedResponsesService(responsesArray, link, editedArray) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ responsesArray:responsesArray, link:link, editedArray:editedArray}),
  };
  let pod_id = "TIC1";
  return fetch(domain() + "generate-responses?pod_id=" + pod_id, options);
}

export function GetGeneratedResponsesService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    }
  };
  let pod_id = "TIC1";
  return fetch(domain() + "generate-responses?pod_id=" + pod_id, options);
}

export function RegenerateResponseService(post_url) {
  const token = localStorage.getItem("token");
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ post_url: post_url})
  };
  let pod_id = "TIC1";
  return fetch(domain() + "regenerate-response?pod_id=" + pod_id, options);
}

/** This is for generating for admin only */
export async function AdminGenerateResponseService(text, prompt, api_key, num_responses) {
  const response = [];
  const configuration = new Configuration({
    apiKey: api_key,
  });
  const openai = new OpenAIApi(configuration);
  for (let i=0; i<num_responses; i++) {
    var resp = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: prompt + `\nHuman:` + text,
      temperature: 0.8,
      max_tokens: 300,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });
    resp = resp["data"]["choices"][0]["text"];
    response.push(resp)
  }
  return response
}