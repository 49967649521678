import React from 'react';

const logoStyle = {
    maxWidth: "120%",
    maxHeight: "100vh",
    margin: "auto",
}

const StaySubscribed = () => {
    return (
        <div style={{height: "100%", display:"grid"}}><img src='/images/stay_subscribed.png' style={logoStyle} alt='filtpod-logo'></img></div>
    );
}
export default StaySubscribed;