import domain from './DomainService';

/**
 * Get notifications
 *
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function NotificationService() {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: 'JWT ' + token
    }
  };
  return fetch(`${domain()}notification`, options);
}

export function NotificationCountService(type) {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: 'JWT ' + token
    },
    body: JSON.stringify({ type: type }),
  };
  return fetch(`${domain()}notification`, options);
}

export function PublishNotificationService(data) {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: 'JWT ' + token
    },
    body: JSON.stringify(data),
  };
  return fetch(`${domain()}publish-notification`, options);
}