import { Box } from "@mui/material";
import { RiErrorWarningFill } from "react-icons/ri";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    paddingTop: "32px",
    textAlign: "center",
    "& h2": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
      margin: "0",
    },
  },
};

function OtherError() {
  return (
    <Box sx={styles.Modal} display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "56px", width: "56px", background: "var(--color-red)", borderRadius: "8px" }}
      >
        <RiErrorWarningFill style={{ height: "40px", width: "40px", color: "white", background: "var(--color-red)" }} />
      </Box>
      <h2>An error occured</h2>
      <span>
        An unknown error occured during file import. <br /> Please try again later.
      </span>
    </Box>
  );
}

export default OtherError;
